<template>
<div class="academic-entry ap-entry">
  <div class="editing-academic ap-editing" v-if="editing">
    <div class="cont-form">
      <div class="elem-form private">
        <!-- <label>Private:  </label> -->
        <!-- <true-false-switch v-model="data.private" /> -->
        <ToggleSwitch
          :labelOff="'Public'"
          :labelOn="'Private'"
          :func="updatePrivate"
          :val="data.private"
        ></ToggleSwitch>
      </div>
      <div class="first elem-form">
        <autocomplete class="v-select" v-model="data.diplomaType"
        :terms="['a', 'b']" :placeHolder="'Diploma Type'"/>

      </div>
      <div class="elem-form">
        <autocomplete
          class="v-select"
          v-model="data.diplomaLabel"
          :terms="['a', 'b']"
          :placeHolder="'Diploma label'"/>
      </div>
      <div class="elem-form">
        <input class="v-select" v-model="data.establishment" placeholder="Establishment name"/>
      </div>
      <div class="cont-half">
        <div class="elem-form">
          <div class="labeldate">Start date</div>
          <datepicker
            class="elem-date"
            :minimum-view="'month'"
            :format="'MMM yyyy'"
            v-model="data.dates.start"
          />
        </div>
        <div class="elem-form" :class="currentSelected ? 'disable' : ''">
          <div class="labeldate wother">
            End date
            <div class="other">
              <div class="select-current">
                <div>Current</div>
                <input type="checkbox" v-model="currentSelected" />
              </div>
            </div>
          </div>
          <datepicker
            class="elem-date candisable"
            :minimum-view="'month'"
            :format="'MMM yyyy'"
            v-model="data.dates.end"
          />
        </div>
      </div>
      <div class="elem-form">
        <vue-tags-input
          v-model="tag" :tags="tags"
          :placeholder="'Add Field'"
          class="radius"
          @tags-changed="newTags => updateTags(newTags)"
        />
      </div>
    </div>
  </div>
  <div class="view-academic ap-view radius-small" v-else>
    <div class="content_date">
      {{data.dates.end? new Date(data.dates.end).getFullYear():'Today' }}
      <div class="separ"></div>
      {{data.dates.start? new Date(data.dates.start).getFullYear(): '2020' }}
    </div>
    <div class="content_infos">
      <h4>{{data.diplomaType}} - {{data.diplomaLabel}}</h4>
      <h5>{{data.establishment}}</h5>
      <div class="content_tags">
        <div
          class="elem_skill radius-small"
          v-for="field in data.learningFields"
          :key="field"
        >
          <span>{{field}}</span>
        </div>
      </div>
      <!-- <div class="private_field" v-if="display">
        <p><i>{{data.private? 'Private': 'Public'}}</i></p>
      </div> -->
    </div>
  </div>
</div>

</template>

<script>
// import DateRange from '@/components/utilityPartials/dateRange.vue';
import Datepicker from 'vuejs-datepicker';
import VueTagsInput from '@johmun/vue-tags-input';
import Autocomplete from '@/components/utilityPartials/_autocomplete.vue';
// import TrueFalseSwitch from '@/components/utilityPartials/trueFalseSwitch.vue';
import ToggleSwitch from '@/components/utilityPartials/ToggleSwitch.vue';
import currentSelected from '@/modules/mixins/currentSelect';

export default {
  name: 'AcademicEntry',
  components: {
    Datepicker,
    VueTagsInput,
    // TrueFalseSwitch,
    ToggleSwitch,
    Autocomplete,
  },
  props: ['data', 'editing', 'display'],
  mixins: [currentSelected],
  data() {
    return {
      diplomaType: ['Bac', 'Bac professionnel', 'CAP', 'BTS', 'DUT', 'Prépa', 'Bachelor', 'Licence', 'Licence Pro', 'Diplôme d\'ingénieur', 'Master', 'MBA', 'CQP'],
      tag: '',
      tags: this.data.learningFields ? this.data.learningFields.map((t) => ({ text: t })) : [],
    };
  },
  mounted() {
  },
  methods: {
    updateTags(newTags) {
      this.data.learningFields = newTags.map((tag) => tag.text);
      this.tags = newTags;
    },
    updatePrivate(elem) {
      this.data.private = elem.target.checked;
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .line{
    margin-top: 5px;
    &:first-child{
      margin: 0;
    }
    &.private{
      display: flex;
      margin-right: 40px;
      justify-content: flex-end;
    }
  }
  .vs--searchable{
    width: 100%;
  }
</style>
