<template>
  <div class="image-attach-options">
    <button @click="openMedia">Insert Picture</button>

    <div class="size">
      <div class="label">
        Size
      </div>
      <div class="options">
        <button @click="() => {size = 'large'}"
          :class="{'btn-green': true, 'radius': true, selected:  size === 'large'}" >
          Large
        </button>
        <button @click="() => {size = 'medium'}"
          :class="{'btn-green': true, 'radius': true, selected:  size === 'medium'}">
          Medium
        </button>
        <button @click="() => {size = 'small'}"
          :class="{'btn-green': true, 'radius': true, selected:  size === 'small'}">Small</button>
      </div>
    </div>
    <div class="size">
      <div class="label">
        Display
      </div>
      <div class="options">
        <button @click="() => {display = 'inline'}"
          :class="{'btn-green': true, 'radius': true, selected:  display === 'inline'}" >
          Inline
        </button>
        <button @click="() => {display = 'block'}"
          :class="{'btn-green': true, 'radius': true, selected:  display === 'block'}" >
          Block
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import SelectMedia from '@/modules/mixins/selectMedia';

export default {
  title: 'imageCustom',
  icon: '<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M576 576q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm1024 384v448h-1408v-192l320-320 160 160 512-512zm96-704h-1600q-13 0-22.5 9.5t-9.5 22.5v1216q0 13 9.5 22.5t22.5 9.5h1600q13 0 22.5-9.5t9.5-22.5v-1216q0-13-9.5-22.5t-22.5-9.5zm160 32v1216q0 66-47 113t-113 47h-1600q-66 0-113-47t-47-113v-1216q0-66 47-113t113-47h1600q66 0 113 47t47 113z"/></svg>',
  description: 'Insert Image',
  mixins: [SelectMedia],
  data() {
    return {
      size: 'small',
      sizeDef: {
        small: { width: '6vh' },
        medium: { width: '12vh' },
        large: { width: '20vh' },
      },
      display: 'inline',

    };
  },
  methods: {
    fileSelect(image) {
      if (image) { this.$emit('exec', 'insertHTML', `<img style="width:${this.sizeDef[this.size].width};height: auto;display: ${this.display}" src=${image}>`); }
    },
    openMedia() {
      this.openAttach('Image', (file) => { this.fileSelect(file.data); }, true);
    },

  },

};
</script>

<style scoped lang="scss">
.image-attach-options{
  display: flex;
  flex-direction: row;
  .size{
    margin-left: 2vh;
    display: flex;
    flex-direction: column;
    .options{
      flex-direction: row;
    }
  }
  .selected{
    background-color: #8c28e1 !important;
  }

}
</style>
