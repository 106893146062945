<template>
  <div class="feedback-modal">
    <div class="contentbox box-shadow radius marginB">
      <div class="title-page wother no-margin">
        Feedback
        <div class="other">
          <div class="btn-green radius small reverse" @click="close">Close</div>
        </div>
      </div>
    </div>
    <div class="contentbox box-shadow radius marginB">
      <div class="text-feedback" v-html="this.feedback">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedbackTalent',
  props: ['feedback'],
  methods: {
    close() {
      this.$modal.hide('feedback-talent');
    },
  },

};
</script>

<style>

</style>
