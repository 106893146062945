module.exports = {
  methods: {
    checkAuthRole(roles) {
      if (!roles) {
        return true;
      }
      if (roles && roles.length > 0 && this.$store.state.auth) {
        if (this.$store.state.auth.roleName) {
          if (roles.includes(this.$store.state.auth.roleName)) {
            return true;
          }
        }
      }
      return false;
    },
    checkAuthType(type) {
      if (type && this.$store.state.auth) {
        if (this.$store.state.auth.type === type) {
          return true;
        }
      }
      return false;
    },
    $permission(permission) {
      if (!permission) {
        return true;
      }
      if (permission && this.$store.state.auth) {
        if (this.$store.state.auth.permissions.length > 0) {
          if (this.$store.state.auth.permissions.includes(permission)) {
            return true;
          }
        }
      }
      return false;
    },
    $permissionList(permissions) {
      for (let i = 0; i < permissions.length; i += 1) {
        if (!this.$permission(permissions[i])) {
          return false;
        }
      }
      return true;
    },
  },
};
