import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMoment from 'vue-moment';
import VTooltip from 'v-tooltip';
import vSelect from 'vue-select';
import wysiwyg from 'vue-wysiwyg';
import VModal from 'vue-js-modal';
import VueScrollmagic from 'vue-scrollmagic';
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import AudioRecorder from 'vue-audio-recorder';
import VueSocialSharing from 'vue-social-sharing';
import VueI18n from 'vue-i18n';
import Toasted from 'vue-toasted';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import messages from '@/language/lang_en';
import App from './App.vue';
import router from './router';
import authMixin from './modules/auth/authMixin';
import authGaurd from './router/authGaurd';
import ApiPlugin from './modules/api';
import CustomImage from './modules/customModules/insertImage.vue';
// import './assets/scss/common.scss';
import './assets/scss/_reset.scss';
import './assets/scss/common-new.scss';
import 'vue-select/dist/vue-select.css';
import store from './store';
import toastError from './modules/mixins/toastError';

if (process.env.NODE_ENV !== 'development') {
  // Initialize Sentry
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}
// Connecting socket to server for realtime updates
const socket = io(process.env.VUE_APP_API_URL, {
  autoConnect: false,
});
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',
  messages,
});

Vue.use(VueScrollmagic);

Vue.use(Toasted);
Vue.use(toastError);
let unsub = () => {};
router.beforeEach(authGaurd(store, new Promise((resolve) => {
  const init = store.initialized;
  if (init) {
    unsub();
    resolve();
  } else {
    unsub = store.subscribe((mutation) => {
      if (mutation.type === 'initialize') {
        resolve();
      }
    });
  }
})));
Vue.use(VueRouter);

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});
// Authentication and Authorization middlewear for router

Vue.use(ApiPlugin, app.$store); // Api plugin for convenience

// Wait for auth to be initialized before loading DOM (prevent missed redirects due to auth gaurd)

Vue.use(VueSocketIOExt, socket, { store });
Vue.use(AudioRecorder);
Vue.use(VueMoment); // Moment for DateString format
Vue.use(wysiwyg, {
  forcePlainTextOnPaste: true,
  hideModules: { image: true },
  customModules: [CustomImage],
});
// Editor for HTML fields

Vue.use(VTooltip); // Tooltip for hover
Vue.use(VModal); // Modals for pop ups
Vue.component('v-select', vSelect); // Select boxes
Vue.use(VueSocialSharing); // social sharing mixin

Vue.config.productionTip = false;
// config storing component
Vue.prototype.$modal.showStatic = Vue.prototype.$modal.show;
const storeAndShow = (comp, props, options) => {
  store.dispatch('storeModal', { comp, props, options });
  Vue.prototype.$modal.showStatic(comp, props, options);
};
Vue.prototype.$modal.show = storeAndShow;

Vue.mixin(authMixin); // Mixin to authorize routes and views based on role of user
app.$store.dispatch('auth/initialize').then(() => {
  app.$mount('#app');
  Vue.prototype.$socket.client.on('disconnect', () => {
    throw Error('Socket Disconnection');
  });
}); // Initialize app
