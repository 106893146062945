<template>
  <div class="ShowDropdown" :class="this.dropClass" @click="toggleOpen">
    <div class="title" v-if="!!title">
      {{title}}
    </div>
    <div class="slot" v-else>

      <slot class="slot-child"></slot>
    </div>
    <div class="arrow-cont">
      <div class="arrow" :class="[value ? 'open' : '']">
        <img src="@/assets/img/arrow_black.png">
        <!-- {{this.value ? 'v':'>'}}  -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShowDropdown',
  props: {
    dropClass: {
      type: Object,
      default: () => ({}),
    },
    title: String,
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleOpen() {
      this.$emit('input', !this.value);
    },
  },

};
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
  .ShowDropdown{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 1vh;
    .title{
      font-size: 2vh;
      font-weight: bold;
      flex: 9;
    }
    .slot{
      flex: 9;
    }
    .arrow-cont{
      // flex: 1;
      // margin-right:5px;
      // margin-bottom: 5px;
      .arrow{
        background-color: #48ffaf;
        position: relative;
        width: 3vh;
        height: 3vh;
        // padding: 5px;
        border-radius: 50%;

        img{
          position: absolute;
          top: 50%;
          left: 50%;
          width: 30%;
          // height: 75%;
          transform: translateX(-50%) translateY(-50%) rotate(-90deg);
          position: relative;
        }
        &.open{
          img{
            transform: translateX(-50%) translateY(-50%) rotate(90deg);
          }
        }
      }
    }
  }
</style>
