<template>
<div class="company-info-part radius" id="company-info-scroll">
  <div class="company-name contentbox box-shadow radius marginB purple">
    <div class="row ">
      <div class="pro-pic">
        <img :src="this.offer.offer.company.companyPicture" />
      </div>
      <div class="user-info">
        <div class="name">
          {{this.offer.offer.company.name}}
        </div>
        <div class="status">
          {{this.offer.offer.name}}
        </div>
      </div>
    </div>
  </div>
  <div class="btn-green radius bottom-right center" @click="leaveApplication">
    Leave Application
  </div>
  <div class="contentbox box-shadow radius marginB">
    <toggle-dropdown
      :title="'Offer Info'"
      v-model="offerInfo"
    />
    <div class="infosupp" v-if="offerInfo">
      <div class="elem-infosupp contract">
          <div class="picto">
            <img :src="require('@/assets/pictos/picto_contract_darkpurple.svg')" />
          </div>
          <div class="txt">
            {{ this.offer.offer.contract }}
          </div>
        </div>
        <div class="elem-infosupp location">
          <div class="picto">
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')" />
          </div>
          <div class="txt">
            {{ this.offer.offer.location }}
          </div>
        </div>
        <div class="elem-infosupp location">
          <div class="picto">
            <img class="small" :src="require('@/assets/pictos/picto_remote.svg')" />
          </div>
          <div class="txt">
            {{ this.offer.offer.remote }}
          </div>
        </div>
        <div class="elem-infosupp sector">
          <div class="picto">
            <img class="small" :src="require('@/assets/pictos/picto_sector.svg')" />
          </div>
          <div class="txt">
            {{ this.offer.offer.sector }}
          </div>
        </div>
        <div class="elem-infosupp sector">
          <div class="picto">
            <img class="small" :src="require('@/assets/pictos/picto_sector.svg')" />
          </div>
          <div class="txt">
            {{ this.salary }}
          </div>
        </div>

    </div>
  </div>
  <div class="contentbox box-shadow radius marginB">
    <toggle-dropdown
      :title="'My Application'"
      v-model="application"
    />
    <div class="inner-slot" v-show="application">
    <div class="about-entry box-shadow" v-if="offer.application.bio">
      <div class="about-title">
        Bio
      </div>
      <div class="about-line" v-html="offer.application.bio">
      </div>
    </div>
      <div class="about-entry box-shadow" v-if="offer.application.motivationalText">
      <div class="about-title">
        Cover Letter
      </div>
      <div class="about-line" v-html="offer.application.motivationalText">
      </div>
    </div>
    <div class="about-entry box-shadow">
      <div class="about-title">
        Skills
      </div>
      <div class="about-line" v-html="allSkills()">
      </div>
    </div>
    <div class="about-entry box-shadow">
      <div class="about-title">
        Technologies
      </div>
      <div class="about-line" >
        <p>{{allTech()}}</p>
      </div>
    </div>
    </div>
  </div>
</div>
</template>

<script>
import scrollInit from '@/modules/mixins/perfectScrollInit';
import ToggleDropdown from '@/components/utilityPartials/showDropdown.vue';

export default {
  name: 'CompanyInfo',
  props: ['offer', 'leaveApplication'],
  components: {
    ToggleDropdown,
  },
  data() {
    return {
      application: false,
      offerInfo: false,
    };
  },
  mounted() {
    setTimeout(() => {
      scrollInit('#company-info-scroll');
    }, 100);
  },

  methods: {
    allSkills() {
      let skillList = this.$store.getters['talent/skills'];
      if (this.offer.application.customApplication
        && this.offer.application.customApplication.skills
      ) {
        skillList = this.offer.application.customApplication.skills;
      }
      return skillList.map((sk) => `#${sk}`).join(', ');
    },
    allTech() {
      let techList = this.$store.getters['talent/technologies'];
      if (this.offer.application.customApplication
        && this.offer.application.customApplication.technologies
      ) {
        techList = this.offer.application.customApplication.technologies;
      }

      return techList.map((sk) => `#${sk}`).join(', ');
    },
  },

  computed: {
    salary() {
      const salString = this.offer.offer.salary.currency || '$';
      if (this.offer.offer.salary.amount && !this.offer.offer.salary.salaryType === 'Range') {
        return `${salString}${this.offer.offer.salary.amount}`;
      }
      try {
        return `${salString}${this.offer.offer.salary.range.min}-${this.offer.offer.salary.range.max}`;
      } catch (e) {
        return 'TBD';
      }
    },
  },

};
</script>

<style lang="scss" scoped>

.company-info-part{
  position: relative;
  height: 66vh;
  overflow: auto;
  padding: 1vh;
  background-color: #160033;
  // border-radius: 5%;
  flex: 1;
  .about-entry{
    margin: 10px;
    padding: 1vh;
    background-color: #f8f8f8;
    border-radius: 1vh;
    line-height: 18px;
    .about-title{
      font-size: 18px;
      color: #8c28e1;
      margin-bottom: .5vh;
    }
    .about-line{
      font-size: 12px;
      color: #939598;
      line-height: 2vh;
      overflow: hidden;
      text-overflow: ellipsis;
      #text{
        white-space: nowrap;
      }
    }

  }
  .ShowDropdown{
    padding-left: 1vh;
    .title{
      font-size: 1vh !important;
    }
    padding-bottom: 10px;
  }
  .company-name{
    padding-top:1vh;
    padding-bottom: 1vh;
    .row{
      margin-left: .25vw;
    }
    .pro-pic{
      flex: 1;
      width: 5vh;
      border-radius: 50%;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }
  .user-more{
    display: flex;
    position: relative;
    flex-direction: row;
    padding-left: 2%;

    .col{
      position: relative;
      display: flex;
      flex-direction: column;
    }
    .left{

      flex: 3;
    }
    .right{
      flex: 1

    }
  }
  .user-info{
    flex: 9;
    padding: 1%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .name{
      font-size: 18px;
      font-weight: bold;
    }
    .status{
      font-size: 14px
    }
  }
  .all-info{
    flex:9;
  }
  .row{
    display: flex;
    flex-direction: row;
  }
  .experience{
  }
  .purple{
    background-color: #8c28e1;
    color: #FFF
  }
  .bottom-right{
    margin-bottom: 10px;
    position: relative;
    margin-left: auto;
    width: 40%;
  }

  .infosupp {
      display: flex;
      flex-direction: row;
      margin-top: 1vh;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: 1vw;
      padding-right: 1vw;
      .elem-infosupp {
        position: relative;
        margin-right: 2vh;
        display: flex;
        flex-direction: row;
        width: 40%;
        margin: 0.7vh;
        height: 4vh;
        .picto {
          position: relative;
          width: 1.5vw;
          font-size: 0;
          margin-right: 0.5vh;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 150%;
          }
          .small {
            width: 100% !important ;
          }
        }
        .txt {
          font-size: 12px;
          line-height: 4vh;
        }
      }
    }

}

.inner-slot{
  padding: 1vh;
  .about-entry{
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

h4{
  float: left;
}

</style>
