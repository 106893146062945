<template>
  <div class="upload-input">
    <div class="inner-input radius dropbox">
      <input
        type="file"
        name="fileinput"
        id="fileinput"
        if="file"
        ref="myFile"
        class="inputfile"
        @change="updatePreview"
      />
      <file-preview
        class="preview preview-container radius"
        :filePreview="filePreview"
        :fileType="fileType"
        v-if="this.file  && !this.onlyIcon"
      />
      <img
        :src="require('@/assets/pictos/picto_file_purple.svg')"
        class="preview preview-img"
        v-else-if="this.file"
      />

      <label v-show="!file" class="upload-title" >Click or drag to upload a file</label>
    </div>
    <div
      class="btn-green radius"
      v-if="file && !preventButton"
      @click.prevent="upload"
    >
      Upload
    </div>
  </div>
</template>

<script>
import FilePreview from '@/components/utilityPartials/_filePreview.vue';

export default {
  name: 'FileUpload',
  components: {
    FilePreview,
  },
  props: {
    value: {
      type: String,
    },
    preventButton: {
      type: Boolean,
      default: true,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: false,
      filePreview: false,
      fileUploadPath: '',
      fileType: false,
    };
  },

  methods: {
    upload() {
      return new Promise((resolve) => {
        const { file } = this;
        if (file) {
          this.$api.post('getSignedUrl', { filename: file.name, filetype: file.type }).then((res) => {
            this.fileUploadPath = res.data.link;
            const fileEndpoint = res.data.s3Endpoint;
            this.$api.raw('PUT', this.fileUploadPath, file).then(() => {
              this.$emit(
                'input',
                fileEndpoint,
              );
              resolve(fileEndpoint);
            });
          });
        } else {
          resolve(false);
        }
      });
    },
    updatePreview(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0];
      let blob = '';
      const URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        blob = URL.createObjectURL(this.file);
      }
      this.filePreview = blob;
      // eslint-disable-next-line prefer-destructuring
      this.fileType = this.file.type.split('/')[0];
    },
  },

  watch: {
    file(newFile) {
      this.$emit('fileUpdate', newFile);
    },
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.upload-input{
  height: 45vh !important;
  width: 95%;
  position: relative !important;
  margin-bottom: 1vh;
  .inner-input{
    width: calc(100% - 0.4vh);
    height: calc(100% - 0.4vh);
    background-color: #ffffff;
    border: 0.2vh dashed $logo_color_dark;
    color: $logo_color_dark;

    align-items: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: relative;

    // height: calc(45vh - 0.4vh);
    height: 100%;
    width: calc(100% - 0.4vh);
    margin: 0 auto;
    .inputfile {
      padding: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      left:0;
      top: 0;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    };
    .upload-title{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
    }
    .preview{
      font-size: 0;
      height: 100%;
      overflow: hidden;
      width: 100%;
      > div{
        img, embed{
          padding: 10px;
          height: calc(450px - 20px);
          width: auto;
        }
      }
      .elem-preview-file{
        .radius{
          .preview{
            height: 100%;
            width: auto;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.preview-container{
  .radius{
    text-align: center;
    font-size: 0;
    .preview{
      width: auto !important;
      height: 100% !important;
    }
  }
}
</style>
