<template>
  <div
    class="elem-search"
  >
    <autocomplete
      type="text"
      placeholder="Search"
      @submit="updateSearchText"
      :search="updateSearch"
      class="head-search"
      :defaultValue="searchText"
    />
    <div
      class="picto-search"
      @click="updateSearchText"
    >
      <img class=""
        :src="require('@/assets/pictos/picto_search_darkpurple.svg')"
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';

export default {
  name: 'SearchBar',
  components: {
    Autocomplete,
  },
  props: {
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const startingSearch = this.$store.state.search.searchText;
    return {
      searchText: startingSearch,
    };
  },
  methods: {
    updateSearch(text) {
      this.searchText = text;
      if (this.$store.state.search.previousSearches.length > 0) {
        return this.$store.state.search.previousSearches.filter(
          (option) => option.toLowerCase().indexOf(text.toLowerCase()) > -1,
        );
      }
      return [];
    },
    updateSearchText(result) {
      if (result && typeof result === 'string') {
        this.searchText = result;
      }
      this.$store.dispatch('search/setAndSearch', this.searchText);
      if (this.redirect) {
        this.$router.push('/search');
      }
    },
  },
  computed: {
    ...mapState('search', ['current']),
  },
};
</script>

<style>

</style>
