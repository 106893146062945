/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';

const getInitialState = () => ({
  name: '',
  email: '',
  profilePicture: false,
  loaded: false,
  _id: '',
  notifications: [],
  conversations: [],
  selectedConversationId: false,
  mediaLibrary: [],
});
const state = getInitialState();

const getters = {
  userData: (state) => (
    {
      name: state.name,
      email: state.email,
      id: state._id,
      profilePicture: state.profilePicture,
    }
  ),
  unseenNotificationsCount: (state) => state
    .notifications
    .filter((notification) => !notification.seen)
    .length,
  unseenConversationsCount: (state) => state
    .conversations
    .filter((conversation) => !conversation.seen)
    .length,
  notification: (state) => (id) => state.notifications.find((noti) => noti._id === id),
  conversationSelected: (state) => state.conversations
    .find((conversation) => conversation._id === state.selectedConversationId) || false,
  media: (state) => (params, sort) => {
    const allParams = ['Image', 'Video', 'Pdf', 'Audio'];
    let media = state.mediaLibrary;
    if (params.length > 0) {
      media = state.mediaLibrary.filter(
        (media) => {
          if (params.indexOf('Other') > -1 && allParams.indexOf(media.type) === -1) {
            return true;
          }
          return params.indexOf(media.type) > -1;
        }
        ,
      );
    }
    media = media.sort((a, b) => b - a);
    if (sort === 'date') {
      return [{
        name: 'Date',
        media,
      }];
    } if (sort === 'type') {
      const mediaSections = [
        {
          name: 'Image',
          media: [],
        },
        {
          name: 'Video',
          media: [],
        },
        {
          name: 'Pdf',
          media: [],
        },
        {
          name: 'Audio',
          media: [],
        },
        {
          name: 'Other',
          media: [],
        },
      ];

      const keytObject = {
        Image: 0,
        Video: 1,
        Pdf: 2,
        Audio: 3,
      };
      media.forEach((entry) => {
        try {
          mediaSections[keytObject[entry.type]].media.push(entry);
        } catch (e) {
          mediaSections[4].media.push(entry);
        }
      });
      return mediaSections;
    }

    return [{ name: 'Default', media }];
  },
  ownsMedia: (state) => (id) => state.mediaLibrary.findIndex((media) => media._id === id) > -1,
};

const actions = {

  getUserInfo: ({
    commit, state, rootState, dispatch,
  }) => new Promise((resolve, reject) => {
    if (rootState.auth.token && rootState.auth.loggedIn) {
      Vue.prototype.$api.get('auth/profile').then((res) => {
        dispatch('search/setPreviousSearches', res.data.searchHistory, { root: true });
        commit('storeUser', res.data);
        commit('loaded');
        resolve();
      });
    } else {
      reject();
    }
  }),
  updateUserInfo: ({ commit, state, rootState }, updateBody) => {
    if (rootState.auth.token && rootState.auth.loggedIn) {
      Vue.prototype.$api.post('auth/profile', updateBody).then((res) => {
        commit('storeUser', res.data);
      });
    }
  },

  setUserName: ({ commit }, name) => {
    commit('updateName', name);
  },

  seenNotification: ({ commit }, notificationId) => {
    Vue.prototype.$api.post('communications/seenNotification', { notificationId });
    commit('seenNotification', notificationId);
  },
  deleteNotification: ({ commit }, notificationId) => {
    Vue.prototype.$api.delete(`communications/notification/${notificationId}`).then(() => {
      commit('deleteNotification', notificationId);
    });
  },
  clearSeenNotifications: ({ commit }) => {
    Vue.prototype.$api.post('communications/clearSeenNotifications').then((res) => {
      commit('updateNotifications', res.data);
    });
  },
  setNotificationsAsSeen: ({ commit }) => {
    Vue.prototype.$api.post('communications/markAllNotificationsSeen').then((res) => {
      commit('updateNotifications', res.data);
    });
  },
  socket_newNotification: ({ commit }) => {
    Vue.prototype.$api.get('communications/notifications').then((res) => {
      commit('updateNotifications', res.data);
    });
  },

  seenConversation: ({ commit }, conversationId) => {
    Vue.prototype.$api.post('communications/seenConversation', { conversationId });
    commit('seenConversation', conversationId);
  },
  getConversations: ({ commit }) => new Promise((resolve) => {
    Vue.prototype.$api.get('communications/conversations').then((res) => {
      commit('updateConversations', res.data);
      resolve();
    });
  }),
  sendMessage: ({ commit }, { message, recepientId }) => new Promise((resolve) => Vue.prototype.$api
    .post('communications/message', { message, recepientId }).then((res) => {
      if (res.new) {
        commit('addConversation', res.data.conversation);
      } else {
        commit('updateConversation', res.data.conversation);
      }
      resolve(res.data.conversation);
    })),

  selectConversation: ({ commit }, id) => {
    commit('selectConversation', id);
  },
  deleteConversation: ({ commit }, conversationId) => {
    commit('selectConversation', false);
    Vue.prototype.$api.delete(`communications/conversation/${conversationId}`).then((res) => {
      commit('deleteConversation', conversationId);
    });
  },

  socket_newMessage: ({ commit, state }, { conversationId, message }) => {
    const convo = { ...state.conversations.find((con) => con._id === conversationId) };
    convo.messages.push(message);
    if (state.selectedConversationId !== conversationId) {
      convo.seen = false;
    }
    commit('updateConversation', convo);
  },

  socket_newConversation: ({ commit }, { conversationId }) => {
    Vue.prototype.$api.get(`communications/conversation/${conversationId}`).then((res) => {
      commit('addConversation', res.data);
    });
  },

  getMedia: ({ commit }) => new Promise((resolve) => {
    Vue.prototype.$api.get('media/').then((res) => {
      commit('getMedia', res.data);
      resolve(res.data);
    });
  }),

  uploadMedia: ({ commit }, media) => new Promise((resolve) => {
    Vue.prototype.$api.post('media/', media).then((res) => {
      commit('addMedia', res.data);
      resolve(res.data);
    });
  }),
  updateMedia: ({ commit }, { media, mediaId }) => new Promise((resolve) => {
    Vue.prototype.$api.put(`media/${mediaId}`, media).then((res) => {
      commit('updateMedia', res.data);
      resolve(res.data);
    });
  }),
  deleteMedia: ({ commit }, mediaId) => new Promise((resolve) => {
    Vue.prototype.$api.delete(`media/${mediaId}`).then(() => {
      commit('deleteMedia', mediaId);
      resolve(mediaId);
    });
  }),

};

const mutations = {
  loaded: (state) => {
    state.loaded = true;
  },
  updateUser: (state, { field, val }) => {
    state[field] = val;
  },
  storeUser: (state, userData) => {
    state.name = userData.name;
    state.email = userData.email;
    state._id = userData._id;
    state.profilePicture = userData.profilePicture;
    state.notifications = userData.notifications;
    state.conversations = userData.conversations;
  },
  updateName: (state, name) => {
    state.name = name;
  },

  seenNotification: (state, notificationId) => {
    const index = state.notifications.findIndex((notif) => notif._id === notificationId);
    state.notifications[index].seen = true;
  },
  updateNotifications: (state, notifications) => {
    state.notifications = notifications;
  },
  deleteNotification: (state, notificationId) => {
    const index = state.notifications.findIndex((notif) => notif._id === notificationId);
    Vue.delete(state.notifications, index);
  },

  seenConversation: (state, conversationId) => {
    const index = state.conversations.findIndex((con) => con._id === conversationId);
    state.conversations[index].seen = true;
  },
  updateConversations: (state, conversations) => {
    state.conversations = conversations;
  },
  addConversation: (state, conversation) => {
    state.conversations.push(conversation);
  },
  updateConversation: (state, conversation) => {
    const index = state.conversations.findIndex((con) => con._id === conversation._id);
    Vue.set(state.conversations, index, conversation);
  },
  deleteConversation: (state, conversationId) => {
    const index = state.conversations.findIndex((con) => con._id === conversationId);
    Vue.delete(state.conversations, index);
  },
  selectConversation(state, id) {
    state.selectedConversationId = id;
  },
  getMedia: (state, media) => {
    state.mediaLibrary = media;
  },
  addMedia: (state, media) => {
    state.mediaLibrary.push(media);
  },
  updateMedia: (state, media) => {
    const index = state.mediaLibrary.findIndex((con) => con._id === media._id);
    Vue.set(state.mediaLibrary, index, media);
  },
  deleteMedia: (state, mediaId) => {
    const index = state.mediaLibrary.findIndex((con) => con._id === mediaId);
    Vue.delete(state.mediaLibrary, index);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
