const Signup = () => import('@/views/Universal/Signup.vue');
const AuthPage = () => import('@/views/Universal/AuthPage.vue');
const JobsPage = () => import('@/views/Universal/JobsPage.vue');
const Settings = () => import('@/views/Universal/Settings/Settings.vue');
const ProfileSettings = () => import('@/views/Universal/Settings/ProfileSettings.vue');
const StaticHome = () => import('@/views/Universal/StaticHome.vue');
const OfferView = () => import('@/views/Universal/OfferView.vue');
const CompanyView = () => import('@/views/Universal/CompanyView.vue');
const TalentSettings = () => import('@/views/Talent/TalentDashboard/TalentSettings.vue');
const TalentView = () => import('@/views/Universal/TalentView.vue');
const UniversalSearch = () => import('@/views/Universal/Search/UniversalSearch.vue');
const VideoChat = () => import('@/views/Universal/VideoChat.vue');
const MediaLibrary = () => import('@/views/Universal/MediaLibrary.vue');
const talentProfile = () => import('@/views/Talent/TalentProfile/editTalent.vue');
const Calendar = () => import('@/components/UniversalComponents/Calendar/calendarView.vue');
const FeedView = () => import('@/views/Universal/FeedView.vue');
const ForumCategories = () => import('@/views/Universal/Forum/forumCategories.vue');
const ForumThread = () => import('@/views/Universal/Forum/forumThread.vue');
const ForumThreads = () => import('@/views/Universal/Forum/forumThreads.vue');
const Messages = () => import('../views/Universal/Messages.vue');
const NotiRedirect = () => import('../views/Universal/NotificationRedirect.vue');
const PostView = () => import('@/views/Universal/PostView.vue');

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthPage,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: JobsPage,

  },
  {
    path: '/offer/:id',
    name: 'OfferView',
    component: OfferView,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: { loggedIn: true },

    props: (route) => ({ token: route.query.token }),
  },
  {
    path: '/',
    name: 'Home',
    component: StaticHome,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    children: [
      {
        path: '',
        component: ProfileSettings,
      },
      {
        path: '*',
        component: ProfileSettings,
      },
    ],
    meta: { loggedIn: true },
  },
  {
    path: '/company/:id',
    name: 'CompanyView',
    component: CompanyView,
  },

  {
    path: '/talent-settings',
    name: 'Talent Settings',
    component: TalentSettings,
  },
  {
    path: '/talent/:id',
    name: 'TalentView',
    component: TalentView,
  },
  {
    path: '/search',
    name: 'UniversalSearch',
    component: UniversalSearch,
    meta: { loggedIn: true },
  },
  {
    path: '/messages',
    name: 'MessageView',
    component: Messages,
    meta: { loggedIn: true },
  },
  {
    path: '/media-library',
    name: 'MediaLibrary',
    component: MediaLibrary,
    meta: { loggedIn: true },
  },

  {
    path: '/talent-profile',
    name: 'Talent Profile',
    component: talentProfile,
    meta: { loggedIn: true, type: 'Talent' },
  },
  {
    name: 'Calendar',
    path: '/calendar',
    component: Calendar,
    meta: { loggedIn: true },
  },
  {
    name: 'Feed',
    path: '/feed',
    component: FeedView,
  },

  {
    path: '/videochat/:eventId',
    name: 'VideoChat',
    component: VideoChat,
    props: (route) => ({ eventId: route.params.eventId }),
  },

  {
    path: '/post/:postId',
    name: 'PostView',
    component: PostView,
    props: (route) => ({ postId: route.params.postId }),
  },
  {

    path: '/forum',
    name: 'ForumCategories',
    component: ForumCategories,
    meta: { loggedIn: true },
  },
  {

    path: '/forum/category/:id',
    name: 'ForumThreads',
    component: ForumThreads,
    meta: { loggedIn: true },
  }, {

    path: '/forum/thread/:id',
    name: 'ForumThread',
    component: ForumThread,
    meta: { loggedIn: true },
  },
  {
    path: '/notificationRedirect/:id',
    name: 'NotificationRedirect',
    component: NotiRedirect,
    meta: { loggedIn: true },
  },
];

export default routes.map((route) => {
  const metaRoute = route;
  metaRoute.meta = { authorize: false, type: false, ...metaRoute.meta };
  return metaRoute;
});
