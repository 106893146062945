/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';
import offer from './offer';

const blankField = () => ({
  dates: {
    start: Date.now(),
    end: Date.now(),
  },
  blank: true,

});
const state = () => ({
  offers: [],
  offersRetrieved: false,
  resume: {
    professionalBG: [],
    projects: [],
    academicBG: [],
  },
  certifications: [],
  resumeRetrieved: false,
  requestSending: false,
  talentInfo: {},

});

const getters = {
  offers: (state) => state.offers,
  professionalBG: (state) => state.resume.professionalBG,
  projects: (state) => state.resume.projects,
  academicBG: (state) => state.resume.academicBG,
  skills: (state) => state.talentInfo.skills,
  technologies: (state) => state.talentInfo.technologies,
  socials: (state) => state.talentInfo.socials,
  offer: (state) => (offerId) => state.offers.find((offer) => offer.offer._id === offerId),
  talentResume: (state) => ({
    ...state.talentInfo,
    professionalBG: state.resume.professionalBG,
    projects: state.resume.projects,
    academicBG: state.resume.academicBG,
  }),
  certifications: (state) => state.certifications,
  info: (state) => {
    if (state.talentInfo.name
      && state.talentInfo.name.firstName
      && state.talentInfo.name.lastName) {
      return {
        name: state.talentInfo.name,
        location: state.talentInfo.location,
      };
    }
    return false;
  },

};

const actions = {
  getTalentInfo({ commit, state }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.get('talent').then((res) => {
        const talentInfo = {
          _id: res.data._id,
          name: {
            firstName: res.data.name.firstName,
            lastName: res.data.name.lastName,
          },
          jobWanted: res.data.jobWanted,
          location: res.data.location,
          socials: res.data.socials,
          description: res.data.description,
          skills: res.data.skills,
          profilePicture: res.data.profilePicture,
          technologies: res.data.technologies,
          website: res.data.website,

          __t: res.data.__t,
        };
        commit('updateTalentInfo', talentInfo);
        commit('updateCertifications', res.data.certifications);
        resolve(talentInfo);
      });
    });
  },
  postTalentInfo({ commit, state, dispatch }, newTalentInfo) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('talent/update', newTalentInfo).then((res) => {
        const talentInfo = {
          _id: res.data._id,
          name: {
            firstName: res.data.name.firstName,
            lastName: res.data.name.lastName,
          },
          firstName: res.data.name.firstName,
          lastName: res.data.name.lastName,
          job: res.data.jobWanted,
          location: res.data.location,
          socials: res.data.socials,
          skills: res.data.skills,
          technologies: res.data.technologies,
          description: res.data.description,
          website: res.data.website,
          profilePicture: res.data.profilePicture,
          __t: res.data.__t,

        };
        commit('updateTalentInfo', talentInfo);
        dispatch('user/setUserName', talentInfo.name, { root: true });
        resolve(talentInfo);
      });
    });
  },
  getOffers({ commit, state }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.get('talent/applications').then((res) => {
        commit('changeOffers', res.data);
        resolve(res.data);
      // eslint-disable-next-line no-console
      }).catch((e) => { console.error('error GET talent/applications : ', e); });
    });
  },
  applyToOffer({ commit, state }, { offerId, application }) {
    if (state.requestSending) {
      return false;
    }
    commit('sendRequest');
    const applicationBody = {};
    if (application.customApplication) {
      applicationBody.customApplication = application.customApplication;
    }
    if (application.motivationalText) {
      applicationBody.motivationalText = application.motivationalText;
    }
    if (application.presentationVideo) {
      applicationBody.presentationVideo = application.presentationVideo;
    }
    return new Promise((resolve) => {
      Vue.prototype.$api.post(`talent/apply/${offerId}`, applicationBody).then((res) => {
        commit('addOffer', res.data);
        resolve(res.data);
      }).catch((e) => { commit('requestReceived'); resolve(false); });
    });
  },
  getResume({ commit, state }) {
    Vue.prototype.$api.get('talent/resume').then((res) => {
      commit('changeResume', res.data);
    });
  },
  addBlankResume({ commit, state, getters }, type) {
    const typeArr = getters[type];
    if (typeArr.length === 0 || !!typeArr[typeArr.length - 1]._id) {
      if (type === 'professionalBG') {
        commit('addBlankProfessional');
      } else if (type === 'projects') {
        commit('addBlankProject');
      } else {
        commit('addBlankAcademic');
      }
    }
  },
  removeResumeEntry({ commit, state }, { type, index }) {
    if (type === 'professionalBG') {
      commit('removeProfessional', index);
    } else if (type === 'projects') {
      commit('removeProject', index);
    } else {
      commit('removeAcademic', index);
    }
  },
  postResumeEntry({ commit, state }, { type, index, updateBody }) {
    Vue.prototype.$api.post('talent/resume/',
      { type, ...updateBody }).then((res) => {
      if (type === 'professionalBG') {
        commit('editProfessional', { index, data: res.data });
      } else if (type === 'projects') {
        commit('editProject', { index, data: res.data });
      } else {
        commit('editAcademic', { index, data: res.data });
      }
      commit('requestReceived');
    });
  },
  updateResumeEntry({ commit, state }, { type, id, updateBody }) {
    const index = state.resume[type].findIndex((entry) => entry._id === id);
    Vue.prototype.$api.put(`talent/resume/${type}/${id}`, updateBody)
      .then((res) => {
        if (type === 'professionalBG') {
          commit('editProfessional', { index, data: res.data });
        } else if (type === 'projects') {
          commit('editProject', { index, data: res.data });
        } else {
          commit('editAcademic', { index, data: res.data });
        }
        commit('requestReceived');
      });
  },
  deleteResumeEntry({ commit, state }, { type, id }) {
    const index = state.resume[type].findIndex((entry) => entry._id === id);
    Vue.prototype.$api.delete(`talent/resume/${type}/${id}`).then(() => {
      if (type === 'professionalBG') {
        commit('removeProfessional', index);
      } else if (type === 'projects') {
        commit('removeProject', index);
      } else {
        commit('removeAcademic', index);
      }
    });
  },
  // Required offerId, applicationId, stepId, eventStart(Date)

  acceptMeeting({ commit }, appointmentBody) {
    Vue.prototype.$api.post('talent/acceptMeeting', appointmentBody).then((res) => {
      const newApplication = res.data.offerApplication;
      const stepIndex = newApplication
        .process.findIndex((step) => step._id === appointmentBody.stepId);
      newApplication.process[stepIndex].meeting.event = res.data.event;
      commit('updateOffer', {
        offerId: appointmentBody.offerId,
        newApplication,
      });
    });
  },
  setPhoneNumber({ commit }, appointmentBody) {
    Vue.prototype.$api.post('talent/updatePhoneNumber', appointmentBody).then((res) => {
      commit('updateOffer', {
        offerId: appointmentBody.offerId,
        newApplication: res.data,
      });
    });
  },

  uploadTest({ commit }, {
    file, stepId, offerId, applicationId,
  }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('talent/uploadTest', {
        file, stepId, offerId, applicationId,
      }).then((res) => {
        commit('updateOffer', {
          offerId,
          newApplication: res.data,
        });
        resolve();
      });
    });
  },
  leaveApplication({ commit, dispatch }, offerId) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('talent/leaveApplication', { offerId }).then((res) => {
        if (res.status === 202) {
          commit('removeOffer', offerId);
          dispatch('calendar/getCalendar', null, { root: true });
          resolve();
        }
      });
    });
  },

  // Certifications

  addCertification({ commit }, certification) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('talent/certification', { certification }).then((res) => {
        commit('addCertification', res.data);
        resolve(res.data);
      });
    });
  },

  editCertification({ commit }, certification) {
    return new Promise((resolve) => {
      Vue.prototype.$api.put('talent/certification', { certification }).then((res) => {
        commit('updateCertification', res.data);
        resolve(res.data);
      });
    });
  },

  deleteCertification({ commit }, certId) {
    return new Promise((resolve) => {
      Vue.prototype.$api.delete(`talent/certification/${certId}`).then((res) => {
        if (res.status === 202) {
          commit('deleteCertification', certId);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  },
};
const mutations = {
  updateTalentInfo(state, talentInfo) {
    state.talentInfo = { ...state.talentInfo, ...talentInfo };
  },
  changeOffers(state, offers) {
    state.offers = offers || [];
    state.offersRetrieved = true;
  },
  updateOffer(state, { offerId, newApplication }) {
    const offer = state.offers.find((offer) => offer.offer._id === offerId);
    Vue.set(offer, 'application', newApplication);
  },
  removeOffer(state, offerId) {
    const offerIndex = state.offers.findIndex((offer) => offer.offer._id === offerId);
    Vue.delete(state.offers, offerIndex);
  },
  sendRequest(state) {
    state.requestSending = true;
  },
  requestReceived(state) {
    state.requestSending = false;
  },
  addOffer(state, offer) {
    state.offers.unshift(offer);
    state.requestSending = false;
  },

  changeResume(state, resume) {
    state.resume = resume;
    state.resumeRetrieved = true;
  },

  addBlankProfessional(state) {
    state.resume.professionalBG.push(blankField());
  },
  addBlankProject(state) {
    state.resume.projects.push(blankField());
  },
  addBlankAcademic(state) {
    state.resume.academicBG.push(blankField());
  },
  removeProfessional(state, index) {
    state.resume.professionalBG.splice(index, 1);
  },
  removeProject(state, index) {
    state.resume.projects.splice(index, 1);
  },
  removeAcademic(state, index) {
    state.resume.academicBG.splice(index, 1);
  },
  editProfessional(state, { index, data }) {
    Vue.set(state.resume.professionalBG, index, data);
  },
  editProject(state, { index, data }) {
    Vue.set(state.resume.projects, index, data);
  },
  editAcademic(state, { index, data }) {
    Vue.set(state.resume.academicBG, index, data);
  },

  // Certifications
  updateCertifications(state, certifications) {
    state.certifications = certifications;
  },
  addCertification(state, certification) {
    state.certifications.push(certification);
  },
  updateCertification(state, certification) {
    const certIndex = state.certifications.findIndex(((cert) => cert._id === certification._id));
    Vue.set(state.certifications, certIndex, certification);
  },
  deleteCertification(state, certId) {
    const certIndex = state.certifications.findIndex((cert) => cert._id === certId);
    Vue.delete(state.certifications, certIndex);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
