import OfferProgress from '@/components/TalentComponents/offer/offerProgress.vue';
import ApplicantProcess from '@/components/EmployeeComponents/JobManagement/processPartials/applicantProcess.vue';

const argsToObject = (argsArray) => {
  const obj = {};
  argsArray.forEach((arg) => {
    obj[arg.key] = arg.value;
  });
  return obj;
};

// const actionTypes = ['dashView', 'processView', 'calendarView', 'postView', 'applicationView'
// , 'videoChat'];
// Dashview -> args{tab: String}
// processView -> args{offerId, applicantId(employee)}
// calendarView -> args(none)
// postView -> args(tbd)
// applicantView -> args{offerId}
const notificationActionsMixin = {
  computed: {
    action() {
      const args = argsToObject(this.notification.action.args);
      return () => this[`${this.notification.action.type}Action`](args) || (() => {});
    },
  },
  methods: {
    // open the Dasboard
    dashViewAction(args) {
      if (this.$store.state.auth.type === 'Talent') {
        if (args.tab) {
          if (args.tab === 'applications') {
            this.$store.dispatch('navigateHomeTab', 0);
          }
        }
        this.$router.push('/myTalent');
      } else {
        this.$router.push('/myCompany');
      }
    },
    // Open the proces referenced
    processViewAction(args) {
      if (this.$store.state.auth.type === 'Talent') {
        const openOfferModalTalent = (offer) => {
          this.$modal.show(OfferProgress, {
            offer,
          }, {
            name: 'offer-process',
            height: 'auto',
            width: '70%',
            scrollable: true,
            adaptive: true,
            classes: ['overflow'],
          });
        };

        this.$store.dispatch('talent/getOffers').then(() => {
          openOfferModalTalent(this.$store.getters['talent/offer'](args.offerId));
        });
      } else if (this.$store.state.auth.type === 'Employee') {
        const openOfferModalEmployee = (applicant) => {
          this.$modal.show(ApplicantProcess,
            {
              applicant,
              processComp: ApplicantProcess,
              offerIdProp: args.offerId,
            },
            {
              name: 'applicant-process',
              height: 'auto',
              width: '70%',
              scrollable: true,
              adaptive: true,
              classes: ['overflow'],
            });
        };
        if (this.$store.state.company.loadingOffers) {
          this.$store.dispatch('company/getOffers').then(() => {
            openOfferModalEmployee(this.$store.getters['company/applicant'](args.offerId, args.applicationId));
          });
        } else {
          this.$store.dispatch('company/getApplication', { offerId: args.offerId, applicationId: args.applicationId }).then(() => {
            openOfferModalEmployee(this.$store.getters['company/applicant'](args.offerId, args.applicationId));
          });
        }
      }
    },
    // open Calendar
    calendarViewAction() {
      this.$router.push('calendar');
    },
    // View the current offer application
    applicationViewAction(args) {
      this.$store.dispatch('company/updateOffer', args.offerId).then(() => {
        this.$store.dispatch('company/setCurrentOfferApplicationId', args.offerId);
        this.$router.push('/application-management');
      });
    },
    // go to forum thread
    forumThreadAction(args) {
      this.$router.push(`/forum/thread/${args.threadId}`);
    },
    // open vc
    videoChatAction(args) {
      window.open(`${window.location.origin}/videochat/${args.eventId}`);
    },
    // go to followers page
    followerAction(args) {
      if (args.followerPage === 'Talent') {
        this.$router.push(`/talent/${args.followerId}`);
      } else if (args.followerPage === 'Company') {
        this.$router.push(`/company/${args.followerId}`);
      }
    },
  },
};

export default notificationActionsMixin;
