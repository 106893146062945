import Vue from 'vue';
import searchModule from './searchModule';

const state = {
  current: 'offer',
  searchText: '',
  previousSearches: [],
};
const actions = {
  // eslint-disable-next-line no-unused-vars
  setCurrentSearch({ commit, state }, page) {
    commit('setCurrentSearch', page);
  },
  // eslint-disable-next-line no-unused-vars
  setSearchString({ commit }, string) {
    commit('setSearchString', string);// commit new search string
  },
  setPreviousSearches({ commit }, searches) {
    commit('setPreviousSearches', searches);
  },
  setAndSearch({ dispatch }, string) {
    dispatch('setSearchString', string);
    dispatch('searchAll');
    Vue.prototype.$api.post('search/saveSearchText', { text: string });
  },
  searchAll({ dispatch }) {
    dispatch(`${state.current}/setSearchString`); // re-submit at current page
    ['offer', 'talent', 'company', 'post']
      .filter((mod) => mod !== state.current) // filter that out of remaining ones
      .forEach((mod) => dispatch(`${mod}/setSearchString`)); // re-search all sub-searches with new string
  },
  // eslint-disable-next-line no-empty-pattern

};
const mutations = {
  setCurrentSearch(state, page) {
    state.current = page;
  },
  setSearchString(state, string) {
    state.searchText = string;
    if (state.previousSearches.indexOf(string) === -1) {
      state.previousSearches.unshift(string);
    }
  },
  setPreviousSearches(state, previous) {
    state.previousSearches = previous;
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    offer: searchModule('offer'),
    talent: searchModule('talent'),
    company: searchModule('company'),
    post: searchModule('post'),

  },
};
