/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';

const getInitialState = () => ({
  offerData: false,
  retrievingOffer: false,
  offerId: false,
});
const state = getInitialState();

const getters = {
  hasOffer: (state) => !!state.offerData || state.retrievingOffer,
};

const actions = {
  getOffer({ commit, state }, offerId) {
    commit('sendingRequest');
    commit('setOfferId', offerId);
    return new Promise((resolve) => {
      Vue.prototype.$api.get(`offer/${offerId}`).then((res) => {
        commit('updateOfferData', res.data);
        resolve();
      });
    });
  },
  clearOffer({ commit, state }) {
    commit('clearOffer');
  },
  setOfferData({ commit }, offerData) {
    return new Promise((resolve) => {
      commit('updateOfferData', offerData);
      resolve();
    });
  },
};
const mutations = {
  sendingRequest(state) {
    state.retrievingOffer = true;
  },
  setOfferId(state, id) {
    state.offerId = id;
  },
  updateOfferData(state, data) {
    state.offerData = data;
    state.retrievingOffer = false;
  },
  clearOffer(state) {
    const cleanState = getInitialState();
    state.offerData = cleanState.offerData;
    state.offerId = cleanState.offerId;
    state.retrievingOffer = cleanState.retrievingOffer;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
