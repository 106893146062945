<template>
  <div class="label-social-picto" >

        <img
          v-if="site === 'Facebook'"
          :src="
          require('@/assets/pictosocial/social-facebook'+
          `${this.purple ? '-violet.svg' : '.svg'}`)"
        >
        <img
          v-else-if="site === 'Instagram'"
          :src="require('@/assets/pictosocial/social-instagram'+
          `${this.purple ? '-violet.svg' : '.svg'}`)"
        >
        <img
          v-else-if="site === 'Twitter'"
          :src="require('@/assets/pictosocial/social-twitter'+
          `${this.purple ? '-violet.svg' : '.svg'}`)"
        >
        <img
          v-else-if="site === 'Linkedin'"
          :src="require('@/assets/pictosocial/social-linkedin'+
          `${this.purple ? '-violet.svg' : '.svg'}`)"
        >
        <img
          v-else-if="site === 'Github'"
          :src="require('@/assets/pictosocial/social-github'+
          `${this.purple ? '-violet.svg' : '.svg'}`)"
        >
        <img
          v-else-if="site === 'Gitlab'"
          :src="require('@/assets/pictosocial/social-gitlab'+
          `${this.purple ? '-violet.svg' : '.svg'}`)"
        >
        <img
          v-else-if="site === 'Website'"
          :src="require('@/assets/pictosocial/social-web'+
          `${this.purple ? '-violet.svg' : '.svg'}`)"
        >
        <div class="" v-else>
          {{site}}
        </div>
      </div>
</template>

<script>
export default {
  name: 'SocialPicto',
  props: ['site', 'purple'],

};
</script>

<style>

</style>
