<template>
  <div id="app">
    <div id="main" v-if="this.$route.path !== '/signup'">
      <Header>
      </Header>
      <div class="container" id="globalcont"
      :class="($router.currentRoute.path.indexOf('myTalent') === 1
      || $router.currentRoute.path.indexOf('myCompany') === 1) ? '' : 'collapse'">
        <LateralNavigation v-if="showSideBar" ></LateralNavigation>

        <router-view v-if="!this.$store.state.loading"/>
        <div id="Loading" v-if="this.$store.state.loading">
          <loading/>
        </div>
      </div>
    </div>
    <div id="main-signup" v-else>
      <router-view v-if="!this.$store.state.loading"/>
      <div id="Loading" v-if="this.$store.state.loading">
        <loading/>
      </div>
    </div>
    <!-- <div
      v-if="this.$store.state.auth.loggedIn"
      id="notificationPanel"
      class="radius boxshadow-middle"
    >
      <NotificationList></NotificationList>
    </div> -->
  </div>
</template>

<script>

import Loading from '@/components/utilityPartials/_loading.vue';
import LateralNavigation from '@/components/navigation/LateralNavigation.vue';
import Header from '@/components/navigation/Header.vue';
import Defer from '@/modules/mixins/optimize/Defer';

export default {
  components: {
    LateralNavigation,
    Header,
    Loading,
  },
  mixins: [Defer()],

  computed: {
    showSideBar() {
      return this.$store.state.auth.loggedIn && this.$route.path !== '/signup';
    },

  },

};
</script>

<style>

body{
  margin: 0;
}
</style>

<style lang="scss">
  @font-face {
    font-family: 'MontserratRegular';
    src: url('./assets/fonts/MontserratRegular.eot');
    src: local('☺'), url('./assets/fonts/MontserratRegular.woff') format('woff'),
      url('./assets/fonts/MontserratRegular.ttf') format('truetype'),
      url('./assets/fonts/MontserratRegular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MontserratBold';
    src: url('./assets/fonts/MontserratBold.eot');
    src: local('☺'), url('./assets/fonts/MontserratBold.woff') format('woff'),
      url('./assets/fonts/MontserratBold.ttf') format('truetype'),
      url('./assets/fonts/MontserratBold.svg') format('svg');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'MontserratBlack';
    src: url('./assets/fonts/MontserratBlack.eot');
    src: local('☺'), url('./assets/fonts/MontserratBlack.woff') format('woff'),
      url('/assets/fonts/MontserratBlack.ttf') format('truetype'),
      url('./assets/fonts/MontserratBlack.svg') format('svg');
    font-weight: 800;
    font-style: normal;
  }

  @import "@/assets/scss/_variables.scss";
  @import "@/assets/scss/perfectscroll.scss";

  .input-error{
    border: 1px solid $red_color !important;
  }
  #app {
    // text-align: center;
    color: $text_color;
    background-color: #F8F8F8 ;
    font-family: 'MontserratRegular';
  }
</style>
