<template>
  <div class="header" :class="this.$store.state.auth.loggedIn?'':'statichome'">
    <div class="flex-container flex-2-tier-sdfeed reverse align-center"
      v-if="this.$store.state.auth.loggedIn">
      <div class="elem-flex">
        <div class="cont_logo classic">
          <router-link to="/">
            <img :src="require('@/assets/logo_black.svg')">
          </router-link>

        </div>
        <div class="cont_logo cube">
          <router-link to="/">
            <img :src="require('@/assets/logo_black.svg')">
          </router-link>
        </div>
      </div>
      <div class="elem-flex inner-header">
        <div class="head-cont-search">
          <div class="search-field">
            <div
              class="elem-search"
              v-if="this.$store.state.auth.loggedIn && !isSearch"
            >
              <search-bar :redirect="true"/>
            </div>
          </div>
          <div class="pre_head"
            v-if="!this.$store.state.loading && !this.$store.state.auth.loggedIn">
            <div @click="handleLogin" class="btn-green radius-small">
              {{this.$store.state.auth.loggedIn? $t("logout") : $t("signin")}}
            </div>
            <div
              @click="handleSignup"
              class="btn-green radius-small"
              v-if="!this.$store.state.auth.loggedIn"
            >
              {{ $t("signup") }}
            </div>
          </div>
        </div>
        <div class="cont-profile-head"
          v-if="this.$store.state.auth.loggedIn && !this.$store.state.loading">
          <div class="cont-notif-btn">
            <div
              class="notif-btn"
              @click="() => switchTab('messages')"
            >
              <img
                v-if="!this.$store.state.loading"
                class="picto-classic"
                :src="require(`@/assets/pictos/picto_mail_purple.svg`)
              ">
              <div class="marker-notif" v-if="getMessageUnseen"></div>
            </div>
            <div
              class="notif-btn"
              @click="openNotification"
            >
              <img
                v-if="!this.$store.state.loading"
                class="picto-classic"
                :src="require(`@/assets/pictos/picto_notification_purple.svg`)
              ">
              <div class="marker-notif" v-if="getNotificationUnseen"></div>
              <div
                v-if="this.$store.state.auth.loggedIn"
                id="notificationPanel"
                class="radius box-shadow"
              >
                <NotificationList v-show="showNotif"></NotificationList>
              </div>
            </div>
          </div>
          <div class="cont-profile" @mouseup="() => openProfileOption()">
            <div class="text-profile">
              <div class="name-profile">
                {{userInfo.name.firstName}} {{userInfo.name.lastName}}
              </div>
              <div class="company-profile" v-if="isCompany">
                @{{companyInfo.name}}
              </div>
              <div class="company-profile" v-else>
                {{talentInfo.jobWanted}}
              </div>
            </div>
            <div class="picture-profile box-shadow"
              v-if="companyInfo.companyPicture"
              :style="`background-image:url(${companyInfo.companyPicture});
                background-color:transparent;`">
            </div>
            <div class="picture-profile box-shadow"
              v-if="talentInfo.profilePicture"
              :style="`background-image:url(${talentInfo.profilePicture});
                background-color:transparent;`">
            </div>
            <div class="picture-profile box-shadow"
              v-if="!companyInfo.companyPicture && !talentInfo.profilePicture">
            </div>
            <div class="see-more-profile">
              <img class="picto-more"
                :src="require('@/assets/pictos/arrow_white.svg')"
              >
            </div>
            <div id="profile-option-panel" class="radius box-shadow">
              <div
                class="elem-option-panel"
                v-if="userType === 'Talent'"
                @click.exact="() => switchTab(`talent/${talentInfo._id}`)"
                @click.ctrl="() => switchNewTab(`talent/${talentInfo._id}`)"
                @auxclick="() => switchNewTab(`talent/${talentInfo._id}`)"
              >{{ $t("seetalentprofile") }}</div>
              <div
                class="elem-option-panel"
                v-if="isCompany"
                @click.exact="() => switchTab(`company/${companyInfo._id}`)"
                @click.ctrl="() => switchNewTab(`company/${companyInfo._id}`)"
                @auxclick="() => switchNewTab(`company/${companyInfo._id}`)"
              >{{ $t("seecompanyprofile") }}</div>
              <div
                class="elem-option-panel"
                v-if="userType === 'Talent'"
                @click.exact="() => switchTab('talent-settings')"
                @click.ctrl="() => switchNewTab('talent-settings')"
                @auxclick="() => switchNewTab('talent-settings')"
              >{{ $t("settings") }}</div>
              <div
                class="elem-option-panel"
                v-if="isCompany"
                @click.exact="() => switchTab('company-settings')"
                @click.ctrl="() => switchNewTab('company-settings')"
                @auxclick="() => switchNewTab('company-settings')"
              >{{ $t("settings") }}</div>
              <div
                class="elem-option-panel"
                @click="handleLogin"
              >{{ $t("logout") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-container" v-else>
      <div class="cont-header-public">
        <h1 class="cont-logo">
          <img :src="require('@/assets/logo_black.svg')" alt="quasting" title="quasting">
        </h1>
        <div class="cont-menu">
          <a href="#overview" class="elem-menu"><span>Overview</span></a>
          <a href="#optimize" class="elem-menu">Optimize</a>
          <a href="#features" class="elem-menu">Features</a>
          <a href="#jobs" class="elem-menu">Opportunities</a>
          <a href="#news" class="elem-menu">News</a>
        </div>
        <div class="cont-login" v-if="!this.$store.state.loading">
          <div @click="handleLogin" class="btn-green radius-small reverse">
            {{this.$store.state.auth.loggedIn? $t("logout") : $t("signin")}}
          </div>
          <div
            @click="handleSignup"
            class="btn-green radius-small"
            v-if="!this.$store.state.auth.loggedIn"
          >
            {{ $t("signup") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NotificationList from '@/components/UniversalComponents/Notifications/notificationsList.vue';
import SearchBar from '../UniversalComponents/Search/searchBar.vue';

export default {
  name: 'Header',
  components: {
    NotificationList,
    SearchBar,
  },
  mounted() {
    // this.$store.dispatch('company/getInfo');
    this.userType = this.$store.state.auth.type;
    if (this.userType === 'Employee') {
      this.isCompany = true;
      this.$store.dispatch('company/getInfo');
    } else if (this.userType === 'Talent') {
      this.$store.dispatch('talent/getTalentInfo');
    }
  },
  computed: {
    ...mapState('company', {
      companyInfo: (state) => state.companyInfo,
      loading: (state) => state.loadingInfo,
    }),
    ...mapState('talent', {
      talentInfo: (state) => state.talentInfo,
      loading: (state) => state.loadingInfo,
    }),
    userInfo() {
      return this.$store.getters['user/userData'];
    },
    getNotificationUnseen() {
      return this.$store.getters['user/unseenNotificationsCount'];
    },
    getMessageUnseen() {
      return this.$store.getters['user/unseenConversationsCount'];
    },
    isSearch() {
      return this.$route.name && this.$route.name.indexOf('UniversalSearch') > -1;
    },
  },
  data() {
    return {
      searchText: '',
      userType: '',
      isCompany: false,
      company: {},
      showNotif: false,
    };
  },
  methods: {
    openNotification() {
      this.showNotif = !this.showNotif;
      if (this.showNotif) {
        setTimeout(() => {
          document.addEventListener('click', this.closeNotification);
        }, 250);
      } else {
        document.removeEventListener('click', this.closeNotification);
      }
    },
    closeNotification() {
      this.showNotif = false;
      document.removeEventListener('click', this.closeNotification);
    },

    switchTab(value) {
      this.$router.push(`/${value}`).catch(() => {});
    },
    switchNewTab(value) {
      window.open(`${window.location.origin}/${value}`, '_blank');
    },
    handleLogin() {
      if (this.$store.state.auth.loggedIn) {
        this.$store.dispatch('auth/logout');
      } else {
        this.$store.dispatch('auth/login');
      }
    },
    handleSignup() {
      this.$store.dispatch('auth/signup');
    },
    onSubmit() {
      this.$store.dispatch('search/setSearchString', this.searchText);
      this.searchText = '';
      this.$router.push('/search');
    },
    openProfileOption() {
      if (document.getElementById('profile-option-panel').classList.contains('open')) {
        document.getElementById('profile-option-panel').classList.toggle('open');
        document.getElementById('profile-option-panel').parentNode.classList.toggle('open');
      } else {
        document.getElementById('profile-option-panel').classList.toggle('open');
        document.getElementById('profile-option-panel').parentNode.classList.toggle('open');
        setTimeout(() => {
          document.addEventListener('click', this.closeElem);
        }, 100);
      }
    },
    closeElem() {
      document.removeEventListener('click', this.closeElem);
      if (document.getElementById('profile-option-panel').classList.contains('open')) {
        document.getElementById('profile-option-panel').classList.remove('open');
        document.getElementById('profile-option-panel').parentNode.classList.remove('open');
      }
    },
    getCompanyInfo() {
      const companyInfo = this.$store.getters['company/info'];
      this.company = {
        _id: companyInfo._id,
        name: companyInfo.name,
      };
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .cont-header-public{
    // max-width: 1440px;
    width: 100%;
    // position: absolute;
    // top: 0;
    // left: 50%;
    padding: 4vh 0;
    // transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .cont-logo{
      display: block;
      width: 200px;
      img{
        width: 100%;
      }
    }
    .cont-menu{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 50%;
      a{
        color: $text-color;
      }
      .elem-menu{
        font-size: $fnt-14;
        font-weight: 600;
        position: relative;
        cursor: pointer;
        z-index: 1;
        span{
          z-index: 4;
        }
        &::before{
          z-index: 0;
          content: '';
          width: 0;
          transition: all 0.2s;
          height: 0.2vh;
          background-color: $green_color;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(100%);
        }
        &:hover{
          &::before{
            width: 100%;
          }
        }
      }
    }
    .cont-login{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .btn-green{
        margin-right: 1vh;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  .search-field{
    width: 100%;
  }
  #notificationPanel{
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 120%;
    max-height: 30vh;
    // min-height: 20vh;
    width: 35vh;
    z-index: 4000;
    background-color: #ffffff;

  }
</style>
