var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"process-edit"},[_c('div',{staticClass:"green-circle-button close-button",on:{"click":_vm.closeModal}},[_c('span',[_vm._v("X")])]),_c('div',{staticClass:"applicant-info",class:_vm.stepHide?'fullwidth':''},[_c('div',{staticClass:"applicant-title"},[_vm._v(" Talent's Info ")]),(_vm.stepHide)?_c('applicant-process-info',{attrs:{"applicationId":this.applicant._id,"offerId":this.offerId,"name":_vm.nameString,"seeprofile":function () { return this$1.seeprofileCurrent(); }}}):_c('applicant-process-info',{attrs:{"applicationId":this.applicant._id,"offerId":this.offerId,"name":_vm.nameString,"reject":function () { return this$1.rejectCurrent(); },"seeprofile":function () { return this$1.seeprofileCurrent(); }}})],1),(!_vm.stepHide)?_c('div',{staticClass:"steps-container",attrs:{"id":"step-list"}},[_vm._l((_vm.steps),function(step,index){return _c('div',{key:step._id},[_c('step-display',{attrs:{"step":step}},[_c('div',{class:{'param-left': step.status === 'Current', 'global-param': true  }},[_c('div',{staticClass:"param ghost"}),_c('div',{staticClass:"param"},[_c('img',{attrs:{"src":require("@/assets/pictos/picto_param_purple.svg")}}),_c('img',{staticClass:"hover",attrs:{"src":require("@/assets/pictos/picto_param_purple_full.svg")}})]),_c('div',{staticClass:"cont-param"},[_c('div',{staticClass:"elem-param",on:{"click":function () { return _vm.editStep(index); }}},[_vm._v("Edit step")]),_c('div',{staticClass:"elem-param",on:{"click":function () { return _vm.openFeedbackModal(index); }}},[_vm._v(" Edit/View feedback ")]),(_vm.steps[index + 1] && _vm.steps[index+1].status !== 'Initial')?_c('div',{staticClass:"elem-param",on:{"click":function () { return _vm.resetStep(index); }}},[_vm._v("Reset Step to Current")]):_vm._e()])]),(step.status === 'Current')?_c('div',{staticClass:"status"},[(index === _vm.availabilityIndex && (step.action === 'Video Meeting' ||
              step.action === 'Live Code' ||
              step.action === 'Phone Call' ||
              step.action === 'Other'))?_c('div',{staticClass:"availabilityContainer"},[_c('meeting-availability',{attrs:{"meetingTime":step.meeting.time || 1800000},model:{value:(step.meeting.possibleTimes),callback:function ($$v) {_vm.$set(step.meeting, "possibleTimes", $$v)},expression:"step.meeting.possibleTimes"}}),_c('div',{staticClass:"cont-btn-availability"},[_c('div',{staticClass:"btn-green radius center",on:{"click":function () { return _vm.submitAvailability(step); }}},[_vm._v(" Send ")])])],1):_vm._e(),_c('div',{staticClass:"btn-action"},[((step.action === 'Video Meeting' ||
              step.action === 'Live Code' ||
              step.action === 'Phone Call' ||
              step.action === 'Other'))?_c('div',{staticClass:"btn-green radius center",on:{"click":function () { return _vm.toggleAvailabilty(index); }}},[_vm._v(" "+_vm._s(_vm.availabilityIndex === -1 ? (step.meeting.possibleTimes.length? 'Edit Availability':'Set Availability') : 'Close')+" ")]):_vm._e(),(step.meeting && step.meeting.event && step.action === 'Video Meeting')?_c('div',{staticClass:"btn-green radius",on:{"click":function () { return _vm.startVideoChat(step.meeting.event); }}},[_vm._v(" "+_vm._s(step.meeting.event.link? 'Join Video Call': 'Start Video Call')+" ")]):_vm._e(),((step.action === 'Coding Test')
              && step.technicalTest
              && step.technicalTest.completedTestFile)?_c('div',{staticClass:"btn-green radius",on:{"click":function () { return _vm.downloadMedia(step.technicalTest.completedTestFile); }}},[_vm._v(" Download Test ")]):_vm._e()]),_c('div',{staticClass:"bottom-line"},[(step.meeting
                && step.meeting.eventAccepted
                && (step.action === 'Video Meeting' ||
                step.action === 'Live Code' ||
                step.action === 'Phone Call' ||
                step.action === 'Other'))?_c('div',{staticClass:"status-choice"},[_vm._v(" Waiting applicant to select a date ")]):_vm._e(),(step.meeting
                && step.meeting.eventAccepted
                &&  step.action === 'Phone Call')?_c('div',{staticClass:"status-choice"},[_vm._v(" "+_vm._s(step.meeting.phoneNumber ? ("Phone Number: " + (step.meeting.phoneNumber)) : 'Waiting for Applicant to submit Phone Number')+" ")]):_vm._e(),((step.action === 'Coding Test')
              && step.technicalTest
              && !step.technicalTest.completedTestFile)?_c('div',{staticClass:"status-choice"},[_vm._v(" "+_vm._s(!step.technicalTest.testFile ? 'Waiting for Test Setup' : 'Waiting for Applicant Submission')+" ")]):_vm._e(),((step.action === 'Coding Test')
              && step.technicalTest
              && step.technicalTest.completedTestFile)?_c('div',{staticClass:"status-choice"},[_vm._v(" Test Finished ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.buttonDisplay(step)),expression:"buttonDisplay(step)"}],staticClass:"btn-choice"},[(step.status !== 'Approved')?_c('div',{staticClass:"btn-lightpurple medium radius",on:{"click":function () { return _vm.updateStatus(index, 'Approved'); }}},[_vm._v(" Approve ")]):_vm._e(),(step.status !== 'Rejected')?_c('div',{staticClass:"btn-gray medium dark radius",on:{"click":function () { return _vm.updateStatus(index, 'Rejected'); }}},[_vm._v(" Reject ")]):_vm._e()])])]):_vm._e()])],1)}),_c('div',{staticClass:"cont-btn"},[_c('div',{staticClass:"btn-green radius",on:{"click":function () { return _vm.addStep; }}},[_vm._v(" Add Step ")])])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }