import PerfectScrollbar from 'perfect-scrollbar';

const $ = document.querySelector.bind(document);
/**
 * Iniitialize Perfect Scrollbar
 * @param {string} selector Css selector for element to initialize
 * @param {Promise|null} scrollUpdate Promise that triggers when end of scroll is reached
 * @param {object|null} config Config object with attributes default: {scrollDirection:'Down'}
 */
export default (selector, scrollUpdate = false, config = {}) => {
  let defaultOptions = {
    wheelPropagation: true,
    wheelSpeed: 1,
  };
  if (config.initOptions) {
    defaultOptions = { ...defaultOptions, ...config.initOptions };
  }

  const ps = new PerfectScrollbar(selector, defaultOptions);
  ps.update();

  let scrollEvent = 'ps-y-reach-end';
  if (config.scrollDirection === 'Up') {
    scrollEvent = 'ps-y-reach-start';
  }
  if (scrollUpdate) {
    const updateScroll = () => {
      $(selector).removeEventListener(scrollEvent, updateScroll);
      scrollUpdate().then((res) => {
        if (res) {
          setTimeout(() => {
            $(selector).addEventListener(scrollEvent, updateScroll);
          }, 1000);
        }
        ps.update();
      });
    };
    $(selector).addEventListener(scrollEvent, updateScroll);
  }
  return ps;
};
