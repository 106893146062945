<template>
  <div class="step radius box-shadow" :class="step.status">
        <!-- <div class="line" :class="step.status"></div> -->
        <div class="right-step">
          <div class="picto-step box-shadow" :class="step.status">
            <img class=""
              :src="stepIcon()"
            />
            <!-- <label>{{step.status}}</label> -->
          </div>
          <div class="info-step">
            <div class="step-title">
              {{step.name}}
            </div>
            <!-- {{step}} -->
            <div class="date-step"
            >
              <img class="picto-date"
                :src="require('@/assets/pictos/picto_calendar_darkpurple.svg')">
                <template v-if="step.meeting && step.meeting.event">
                  {{ step.meeting.event.date.start | moment(' DD/MM/YYYY ') }}
                </template>
                <template v-else>
                  TBD
                </template>
              <img class="picto-date"
              style="margin-left: 1vh"
              :src="require('@/assets/pictos/picto_time_darkpurple.svg')">
              <template v-if="step.meeting && step.meeting.event">
                {{ step.meeting.event.date.start | moment(' HH:mm a ') }}
              </template>
              <template v-else>
                  TBD
                </template>

            </div>

            <div class="description" v-html="step.description"></div>
          </div>
          <div class="current-marker" v-if="step.status === 'Current'">
            Current
          </div>
        </div>
      <!-- </div> -->
      <!-- This allows the step display to have different components for each view  -->
      <slot></slot>
    <!-- <hr /> -->

  </div>
</template>

<script>
import stepTypes from '@/components/EmployeeComponents/JobManagement/processPartials/stepTypes';

export default {
  name: 'StepDisplay',
  props: ['step'],

  methods: {
    hasTime() {
      return this.step.meeting && this.step.meeting.event && this.step.meeting.event.date;
    },
    stepIcon() {
      const icon = stepTypes.find((stepIcon) => stepIcon.name === this.step.action);
      if (!icon) {
        return '';
      }
      if (this.step.status === 'Current' || this.step.status === 'Rejected') {
        return icon.iconRoute;
      }
      if (this.step.status === 'Approved') {
        return icon.iconRouteDarkPurple;
      }
      if (this.step.status === 'Initial') {
        return icon.iconRouteGray;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.step{
  overflow: hidden;
  display: flex;
}
.Current{
  border: 1px #8c28e1 solid;
}
  .info-step{
    margin: 1vh 1vh 1vh 1vh;
    .date-step{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.5vh;
      color: #c4c1cc;
      .picto-date{
        width: 2.5vh;
        color: #c4c1cc;
      }
    }
  }
  .step-title{
    font-size: 2vh;
    width: calc(100% - 1vh);
    color: #000;
    font-weight: bold;
    margin-bottom: 2vh;
  }

  .current-marker{
    position: absolute;
    top: 0;
    right: 0;
    background-color: $logo_color;
    height: 3.6vh;
    width:  12%;
    border-bottom-left-radius: 10px;
    color: white;
    text-align: center;
    line-height: 3.6vh;
    font-size: 14px;
  }
  .description{
    max-height: 55px;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.25vh;

  }

</style>
