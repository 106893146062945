<template>
<div class="upload-input audio">
  <!-- <audio-recorder
    :attempts="0"
    :time="2"
    :showUploadButton="false"
    :selectRecord="this.select"
    :sample-rate="16000"
  /> -->
  <audio-recorder
    :attempts="0"
    :time="0"
    :showUploadButton="false"
    :selectRecord="this.select"
  />
</div>
</template>

<script>
export default {
  name: 'AudioUpload',

  data() {
    return {
      selected: false,
    };
  },
  methods: {
    upload() {
      return new Promise((resolve) => {
        const file = this.selected.blob;
        if (file) {
          this.$api.post('getSignedUrl', { filename: `${this.selected.id}`, filetype: file.type }).then((res) => {
            this.fileUploadPath = res.data.link;
            const fileEndpoint = res.data.s3Endpoint;
            this.$api.raw('PUT', this.fileUploadPath, file).then(() => {
              resolve(fileEndpoint);
            });
          });
        } else {
          resolve(false);
        }
      });
    },
    select(record) {
      this.selected = record;
      if (record) {
        this.$emit('ready', true);
      } else {
        this.$emit('ready', false);
      }
    },
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.upload-input{
  &.audio{
    height: unset !important;
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0,0,0);
  }
  .ar{
    width: calc(100%);
    border-radius: 1vh;
    padding: 2vh;
    .ar-content{
      padding: 1vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      > div{
        margin: 0;
      }
      .ar-records{
        height: auto;
        width: 42vh;
        .ar-records__record{
          cursor: pointer;
          transition: all .2s;
          border: 0.1vh solid #E8E8E8;
          border-radius: 24px;
          background-color: #FFFFFF;
          margin-top: 1vh;
          padding: 0 34px;
          &:first-child{
            margin-top: 0;
          }
          &.ar-records__record--selected{
            border: 0.1vh solid $green_color;
          }
          &:hover{
            background-color: rgba(140, 40, 225, 0.2);
          }
        }
      }
      .ar-icon{
        border: 1px solid $green_color;
      }
      .ar-recorder{
        flex-direction: row;
        .ar-recorder__stop{
          margin-left: 2vh;
          position: relative;
          right: unset;
          top: unset;
        }
      }
      .ar-recorder__records-limit{
        position: relative;
      }
      .ar-recorder__time-limit{
        position: relative;
      }
      .ar-player{
        .ar-player-actions{
          margin-right: 1vh;
        }
      }
    }
  }
}
</style>
