export default [
  {
    name: 'Dashboard',
    trlabel: 'menu_dashboard',
    path: '',
    picto: 'picto_dashboard_purple',
    pictoWhite: 'picto_dashboard_white',
    pictoGreen: 'picto_dashboard_green',
  },
  {
    name: 'Newsfeed',
    trlabel: 'menu_feed',
    path: 'feed',
    picto: 'picto_feed_purple',
    pictoWhite: 'picto_feed_white',
    pictoGreen: 'picto_feed_green',
  },
  {
    name: 'Forum',
    trlabel: 'menu_forum',
    path: 'forum',
    picto: 'picto_mail_purple',
    pictoWhite: 'picto_mail_white',
    pictoGreen: 'picto_mail_green',
  },
  {
    name: 'Calendar',
    trlabel: 'menu_calendar',
    path: 'calendar',
    picto: 'picto_calendar_purple',
    pictoWhite: 'picto_calendar_white',
    pictoGreen: 'picto_calendar_green',
  },
  {
    name: 'Media Library',
    trlabel: 'menu_medialibrary',
    path: 'media-library',
    picto: 'picto_allmedia_purple',
    pictoWhite: 'picto_allmedia_white',
    pictoGreen: 'picto_allmedia_green',
  },
  {
    name: 'Profile',
    trlabel: 'menu_talentprofile',
    path: 'talent-profile',
    picto: 'picto_profile_purple',
    pictoWhite: 'picto_profile',
    pictoGreen: 'picto_profile',
  },
  {
    path: 'talent-settings',
    trlabel: 'menu_settings',
    name: 'Settings',
    picto: 'picto_pagesetting_purple',
    pictoWhite: 'picto_pagesetting_white',
    pictoGreen: 'picto_pagesetting_green',
  },
  // {
  //   name: 'Messages',
  //   path: 'messages',
  //   picto: 'picto_mail_purple',
  //   pictoWhite: 'picto_mail_white',
  //   pictoGreen: 'picto_mail_green',
  // },
  // {
  //   name: 'Notification',
  //   path: '.notification',
  //   picto: 'picto_notification_purple',
  //   pictoWhite: 'picto_notification_white',
  // },
  // {
  //   path: 'Resume',
  //   name: 'Resume',
  //   picto: 'picto_diploma',
  // },
  // {
  //   path: 'Offers',
  //   name: 'Offers',
  //   picto: 'picto_application',
  // },
];
