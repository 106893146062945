/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';

const state = () => ({
  myPosts: [],
  lastLoaded: false,
  requestSending: false,
});

const getters = {
  loaded: (state) => !!state.lastLoaded && !state.requestSending,
  postFromId: (state) => (id) => state.myPosts.find((post) => post._id === id),
};

const actions = {
  addPost({ commit }, post) {
    commit('addPost', post);
  },
  updateMyPosts({ commit, state }) {
    let reqUrl = 'feed/myPosts';
    if (state.lastLoaded) {
      reqUrl += `?time=${state.lastLoaded}`;
    }
    commit('sendRequest', Date.now());
    return new Promise((resolve) => Vue.prototype.$api.get(reqUrl).then((res) => {
      commit('updatePosts', res.data);
      resolve();
    }));
  },
  getNextMyPosts({ commit, state }) {
    let reqUrl = 'feed/myPosts';
    if (state.myPosts.length > 0) {
      reqUrl += `?skip=${state.myPosts.length}`;
    }
    return new Promise((resolve) => {
      Vue.prototype.$api.get(reqUrl).then((res) => {
        commit('nextPagePosts', res.data);
        resolve(res.data.length > 0);
      });
    });
  },

  deletePost({ commit, state }, id) {
    Vue.prototype.$api.delete(`feed/post/${id}`).then((res) => {
      if (res.data) {
        commit('removePost', res.data._id);
      }
    }).catch(() => {
      commit('removePost', id);
    });
  },
  updateLike({ commit, state }, post) {
    const postIndex = state.myPosts.findIndex((feedPost) => feedPost._id === post._id);
    if (postIndex > -1) {
      commit('updateLike', { postIndex, post });
    }
  },
  getInitialPosts({ commit, dispatch }) {
    commit('resetLastLoaded');
    dispatch('updateMyPosts');
  },
};
const mutations = {
  updatePosts(state, posts) {
    state.myPosts = posts.concat(state.myPosts);
    state.requestSending = false;
  },
  addPost(state, post) {
    state.myPosts.unshift(post);
  },
  nextPagePosts(state, posts) {
    state.myPosts = state.myPosts.concat(posts);
  },
  sendRequest(state, time) {
    state.lastLoaded = time;
    state.requestSending = true;
  },
  removePost(state, id) {
    const index = state.myPosts.findIndex((post) => post._id === id);
    state.myPosts.splice(index, 1);
  },
  updateLike(state, { postIndex, post }) {
    state.myPosts[postIndex].likes = post.likes;
    state.myPosts[postIndex].youLiked = post.youLiked;
  },
  resetLastLoaded(state) {
    state.lastLoaded = false;
    state.myPosts = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
