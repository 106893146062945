export default [
  {
    name: 'Dashboard',
    trlabel: 'menu_dashboard',
    path: '',
    picto: 'picto_dashboard_purple',
    pictoWhite: 'picto_dashboard_white',
    pictoGreen: 'picto_dashboard_green',
  },
  {
    name: 'Newsfeed',
    trlabel: 'menu_feed',
    path: 'feed',
    picto: 'picto_feed_purple',
    pictoWhite: 'picto_feed_white',
    pictoGreen: 'picto_feed_green',
  },
  {
    name: 'Application Mngt',
    trlabel: 'menu_applicationmanagement',
    path: 'application-management',
    picto: 'picto_appmngt_purple',
    pictoWhite: 'picto_appmngt_white',
    pictoGreen: 'picto_allmedia_green',
  },
  {
    name: 'Talents Search',
    trlabel: 'Talents Search',
    path: 'applicant-search',
    picto: 'picto_appsearch_purple',
    pictoWhite: 'picto_appsearch_white',
    pictoGreen: 'picto_allmedia_green',
    permissions: ['edit_offer'],
  },
  {
    name: 'Job Mngt',
    trlabel: 'menu_jobmanagement',
    path: 'job-management',
    picto: 'picto_jobmngt_purple',
    pictoWhite: 'picto_jobmngt_white',
    pictoGreen: 'picto_allmedia_green',
    permissions: ['edit_offer'],
  },
  {
    name: 'Process Mngt',
    trlabel: 'menu_processmanagement',
    path: 'process-management',
    picto: 'picto_process_purple',
    pictoWhite: 'picto_process_white',
    pictoGreen: 'picto_allmedia_green',
    permissions: ['edit_process'],
  },
  {
    name: 'Forum',
    trlabel: 'menu_forum',
    path: 'forum',
    picto: 'picto_mail_purple',
    pictoWhite: 'picto_mail_white',
    pictoGreen: 'picto_mail_green',
  },
  {
    name: 'Calendar',
    trlabel: 'menu_calendar',
    path: 'calendar',
    picto: 'picto_calendar_purple',
    pictoWhite: 'picto_calendar_white',
    pictoGreen: 'picto_calendar_green',
  },
  {
    name: 'Media Library',
    trlabel: 'menu_medialibrary',
    path: 'media-library',
    picto: 'picto_allmedia_purple',
    pictoWhite: 'picto_allmedia_white',
    pictoGreen: 'picto_allmedia_green',
  },
  {
    name: 'Company',
    trlabel: 'menu_companyprofile',
    path: 'edit-company-profile',
    picto: 'picto_company_purple',
    pictoWhite: 'picto_company_white',
    pictoGreen: 'picto_pagesetting_green',
    permissions: ['edit_company_info'],

  },
  {
    name: 'Settings',
    trlabel: 'menu_settings',
    path: 'company-settings',
    picto: 'picto_pagesetting_purple',
    pictoWhite: 'picto_pagesetting_white',
    pictoGreen: 'picto_pagesetting_green',
  },
];
