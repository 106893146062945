import axios from 'axios';

export default {
  methods: {
    downloadMedia(media, key = 'file') {
      axios.get(media[key], { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${media.name}.${media.type ? media.type.toLowerCase() : media[key].split('.').pop()}`;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
    },
  },
};
