<template>
<div class="notification-wrapper">
  <div
    class="elem-notif radius-small box-shadow"
    :class="!notification.seen ? 'unseen' : ''"
    @click="notificationAction"
  >
    <!-- {{notification}} -->
    <!-- <div class="date" v-html="calcDateFromNow(notification.createdAt)"></div> -->
    <!-- <div class="marker-seen" ></div> -->
    <div class="marker"></div>
    <div class="content-notif">

      <div class="time-notif" v-html="calcDateFromNow(notification.createdAt)">
      </div>
      <div class="label-notif">
        {{this.notification.message}}
      </div>
    </div>

  </div>
  <div class="delete" @click="deleteNotification">
    <img :src="require('@/assets/pictos/picto_purple_trash.svg')" />
  </div>
</div>

  <!-- <div class="elem-notif radius box-shadow unread">
    <div class="marker"></div>
    <div class="content-notif">
      <div class="title-notif">
        [Offer] Web Developer Front End
      </div>
      <div class="time-notif">
        Less than 1 day
      </div>
      <div class="label-notif">
        Emna Soula accepted meeting «Introduction»
      </div>
    </div>
  </div> -->
</template>

<script>
import NotificationActionsMixin from '@/modules/mixins/notificationActions';
import calcDateFromNow from '@/modules/mixins/calcDateFromNow';

export default {
  name: 'NotificationView',
  props: ['notification', 'closeNotifications', 'closeActive'],
  mixins: [NotificationActionsMixin, calcDateFromNow],
  methods: {
    seenNotification() {
      this.$store.dispatch('user/seenNotification', this.notification._id);
    },
    notificationAction() {
      if (!this.notification.seen) {
        this.seenNotification();
      }
      this.action();
      if (this.closeActive !== false) {
        this.closeNotifications();
      }
    },

    deleteNotification(e) {
      e.stopPropagation();
      this.$store.dispatch('user/deleteNotification', this.notification._id);
    },

  },
  computed: {
  },

};
</script>

<style lang="scss" >
  @import "@/assets/scss/_variables.scss";
  .notification-wrapper{
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .5vh;
    margin-bottom: 1vh;
    .delete{
    display: flex;
    width: 5%;
    justify-content: center;
    margin-left: .5vh;
    &:hover{
      opacity: 0.5 !important;
    }
  }
  }
  .elem-notif{
    cursor: pointer;
    width: 90%;
    padding: 0.5vh 1vh;
    border: 1px solid #ccc;
    position: relative;
    font-size: $mini_fs;
    transition: all .1s;
    .date{
      width: 100%;
      text-align: right;
      padding: 0;
      margin: 0;
    }
    &:hover{
      opacity: 0.5 !important;
    }

  }

</style>
