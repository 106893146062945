<template>
  <div>
    <div v-if="employees" class="meetingForm">
      <div class="cont-input">
        <div class="staff-selection" v-if="!this.template">
          <div class="title">Staff on Meeting</div>
          <v-select
            v-model="value.meetingStaff"
            :multiple="true"
            :options="employees"
            placeholder="Employee"
            label="nameString"
            :reduce="(option) => option._id"
          />
        </div>
        <div class="duration-selection">
          <div class="title">Meeting Length <span>(minutes)</span></div>
          <v-select
            v-model="time"
            :multiple="false"
            :options="timespan"
            placeholder="Time span"
            label="nameString"
          />
        </div>
      </div>
      <!-- <div class="cont-input">
        <label>Meeting Length (minutes)</label>
        <input type="number" v-model.number="time" />
      </div> -->

    </div>
    <div v-else>
      <loading></loading>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/utilityPartials/_loading.vue';

export default {
  name: 'StepMeetingForm',
  components: {
    Loading,
  },
  data() {
    const startingTime = this.value.time ? this.value.time / 60000 : 15;
    return {
      time: startingTime,
      timespan: [15, 30, 45, 60, 90, 120],
    };
  },
  computed: {
    employees() {
      if (!this.$store.state.company.companyEmployees) {
        return [];
      }
      return this.$store.state.company.companyEmployees.map((employee) => {
        const updatedEmployee = employee.user;
        updatedEmployee.nameString = `${employee.user.name.firstName || ''} ${employee.user.name.lastName || ''}`;
        return updatedEmployee;
      });
    },
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    template: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.$store.state.company.companyEmployees) {
      this.$store.dispatch('company/getEmployees');
    }
    if (this.value.meetingStaff.length === 0) {
      this.value.meetingStaff.push(this.$store.state.user._id);
    }
    this.value.time = 30 * 60000;
  },
  watch: {
    time(newValue) {
      this.value.time = newValue * 60000;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.meetingForm{
  width: 100%;
  .title{
    font-size: $label_fs;
    margin-bottom: 10px;
    span{
      font-size: $classic_fs;
      color: $font_gray_color;
    }
  }
  .duration-selection{
    margin-top: 20px;
  }
}

</style>
