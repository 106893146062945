var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mediaSelect"},[_c('div',{staticClass:"green-circle-button close-button",on:{"click":_vm.closeModal}},[_c('span',[_vm._v("X")])]),(!_vm.lockFilter)?_c('div',{staticClass:"sort box-shadow contentbox radius marginB"},[_c('div',{staticClass:"subtitle-page"},[_vm._v("Filter")]),_c('v-select',{attrs:{"clearable":false,"searchable":false,"options":_vm.types,"placeholder":"Filter type","label":"name"},model:{value:(_vm.filterType),callback:function ($$v) {_vm.filterType=$$v},expression:"filterType"}})],1):_vm._e(),(_vm.mediaLibrary[0].media.length > 0)?_c('div',{staticClass:"cont-media box-shadow contentbox radius"},_vm._l((_vm.mediaLibrary[0].media),function(media){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
          content: media.name,
          offset: -100,
          delay: {
            show: 1000,
            hide: 0,
          }
        }),expression:"{\n          content: media.name,\n          offset: -100,\n          delay: {\n            show: 1000,\n            hide: 0,\n          }\n        }",modifiers:{"top-center":true}}],key:media._id,staticClass:"radius",class:{media: true, selected: _vm.mediaSelected && _vm.mediaSelected.id === media._id},on:{"click":function () { return _vm.selectMedia(media); }}},[_c('file-preview',{staticClass:"elem-media boxshadow-middle",attrs:{"filePreview":media.data,"fileType":media.type}}),_c('label',{staticClass:"label-media"},[_vm._v(_vm._s(media.name))])],1)}),0):_c('div',{staticClass:"cont-media box-shadow contentbox radius"},[_vm._v(" No media ")]),(_vm.mediaSelected)?_c('div',{staticClass:"cont-btn"},[_c('div',{staticClass:"radius btn-green",on:{"click":_vm.confirm}},[_vm._v(" Attach ")])]):_vm._e(),(!_vm.mediaSelected)?_c('div',{staticClass:"cont-btn"},[_c('div',{staticClass:"radius btn-green",on:{"click":_vm.uploadNew}},[_vm._v(" Upload New ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }