<template>
  <div class="form-step" v-if="!sending">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="inner-form-step" id="scrollcontent">
      <div class="contentbox box-shadow marginB">
        <div class="title-section">
          <div class="subtitle-page no-margin">
            Edit
          </div>
          <div class="cont-delete">
            <div
              class="btn-green radius-small reverse small"
              v-if="this.id && !sending"
              @click="deleteStep"
            >
              Delete
            </div>
            <div
              @click="submit"
              class="btn-green radius-small small"
              v-if="!sending"
            >
              Submit
            </div>
            <div class="sending" v-if="sending">
              updating...
            </div>
          </div>
        </div>
      </div>
      <div class="contentbox box-shadow marginB">
        <div class="cont-input radius boxshadow-middle">
          <div class="cont-input name-input">
            <input class="v-select" :placeholder="'Name'" v-model="name" requiredVal="name" />
          </div>
          <div class="cont-input description-input">
            <wysiwyg class="wysiwyg radius" v-model="description" />
          </div>
        </div>
      </div>
      <div class="contentbox box-shadow marginB">
        <div class="cont-input radius boxshadow-middle">
          <div class="subtitle-section">Step's type</div>
          <select-switch
            :dataSwitch="{data: stepTypes.map(step => step.name)}"
            :elementIcons="stepTypes"
            v-model="action"
            class="select-switch-step"
            requiredVal="action"
          />
        </div>
      </div>
      <expand-section :title="'Meeting parameters'"
        v-if="action !== 'Coding Test'" style="width: 100%">
        <step-meeting-form v-model="meeting" :template="template" />
      </expand-section>

      <expand-section :title="'Test Settings'"
        v-if="action === 'Coding Test'" style="width: 100%">
        <tech-test-form v-model="technicalTest" :template="template" ref="techTest" />
      </expand-section>

      <div class="cont-bottom">
        <div class="title-section">
          <div class="subtitle-page no-margin">
            <br>
          </div>
          <div class="cont-delete">
            <!-- <div
              class="btn-green radius reverse small"
              v-if="this.id"
              @click="deleteStep"
            >
              Delete
            </div> -->
            <div
              @click="submit"
              class="btn-green radius"
            >
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import SelectSwitch from '@/components/utilityPartials/selectSwitch.vue';
import ExpandSection from '@/components/utilityPartials/expandSection.vue';
import TechTestForm from '@/components/EmployeeComponents/JobManagement/processPartials/technical/TechTestForm.vue';
import validateMixin from '@/modules/mixins/validate';
import stepTypes from '@/components/EmployeeComponents/JobManagement/processPartials/stepTypes';
import StepMeetingForm from './stepMeetingForm.vue';

export default {
  name: 'StepForm',
  mixins: [validateMixin],
  components: {
    SelectSwitch,
    ExpandSection,
    StepMeetingForm,
    TechTestForm,
  },
  props: {
    step: {
      type: Object,
      default: () => ({}),
    },
    callbackSubmit: {
      type: Function,
      default: () => {},
    },
    deleteStep: {
      type: Function,
      default: () => {},
    },
    template: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const meeting = this.step.meeting ? this.step.meeting : {
      meetingStaff: [],
      possibleTimes: [],
    };
    const technicalTest = this.step.technicalTest ? this.step.technicalTest : {};
    return {
      name: this.step.name || '',
      description: this.step.description || '',
      id: this.step._id || false,
      action: this.step.action,
      status: this.step.status || 'Initial',
      sending: false,
      meeting,
      technicalTest,
      stepTypes,
    };
  },
  mounted() {
    const ps = new PerfectScrollbar('#scrollcontent', {
      wheelPropagation: false,
      suppressScrollX: true,
    });
    ps.update();
  },
  methods: {
    async submit() {
      if (this.validateFields()) {
        const submitObject = {
          name: this.name,
          description: this.description,
          action: this.action,
          status: this.status,
        };
        this.sending = true;

        if (this.action === 'Coding Test') {
          submitObject.technicalTest = this.technicalTest;
        } else {
          submitObject.meeting = this.meeting;
        }
        if (this.id) {
          submitObject._id = this.id;
          this.callbackSubmit(submitObject);
        } else {
          this.callbackSubmit(submitObject);
        }
      }
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'edit-step');
      // this.$modal.hide(this.modalName ? this.modalName : 'new-step');
    },
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.form-step{
  width: calc(100% - 1vh);
  padding: 0.5vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .inner-form-step{
    width: calc(100%);
    height: 100%;
    max-height: 70vh;
    overflow: auto;
    .title-section{
      padding: 1.5vh;
      width: calc(100% - 3vh);
      // margin: 0 auto;
      text-align: left;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .cont-delete{
        display: flex;
        flex-direction: row;
        align-items: center;
        .btn-green{
          &.reverse{
            margin-right: 1vh;
          }
          margin-top: 0;
        }
      }
    }
    .cont-input{
      width: 100%;
      margin-bottom: 1vh;
      .subtitle-section{
        padding: 1vh 0;
      }
      &.radius{
        width: calc(100% - 2vh);
        padding: 1vh;
      }
      &.description-input{
        width: calc(100% - 0.2vh);
      }
      .wysiwyg{
        height: 22vh;
      }
      .select-switch{
        width: 100%;
        margin: 0 auto;
        margin-top: 0.5vh;
        .elem-select-switch{
          width: calc(calc(100% - 22vh) / 5) !important;
          margin-right: 1vh;
          &:last-child{margin-right: 0;}
          // padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img{
            max-width: 10vh;
          }
        }
      }
      .editr--content{
        min-height: 5vh;
      }
      .v-select{
        width: 100%;
      }

      .select-switch{
        justify-content: space-between;
        .elem-select-switch{
          background-color: $logo_color_dark;
          border: 0.5vh solid $logo_color_dark;
          color: #ffffff;
          font-size: $mini_fs;
          padding: 0.5vh 1vh;
          &.selected{
            border: 0.5vh solid $green_color;
          }
        }
      }
    }
    .btn-green{
      // margin-top: 2vh;
    }
  }
}

.cont-btn{
  width: 100%;
  display:flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  .btn-green{
    justify-self: flex-end;
  }
}
</style>

<style lang="scss">
.select-switch-step{
  width: 100%;
  margin: 0 auto;
  margin-top: 0.5vh;
  .elem-select-switch{
    width: calc(calc(100% - 16.5vh) / 6) !important;
    height: 10vh;
    margin-right: 1vh;
    &:last-child{
      margin-right: 0;
    }
    // padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      max-width: 10vh;
    }
  }
}
</style>
