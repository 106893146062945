<template>
  <div class="container_loading_anim">
    <div class="global_loading_anim">
      <div class="center_loading"></div>
      <div class="circle_01_loading circle_loading"></div>
      <div class="circle_02_loading circle_loading"></div>
      <div class="circle_03_loading circle_loading"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style lang="scss" scoped>
  .container_loading_anim{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 5vw;
    min-width: 5vw;
    .global_loading_anim{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      .center_loading{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        height: 1vw;
        width: 1vw;
        border-radius: 50%;
        background-color: #8C28E1;
      }
      .circle_loading{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        height: 0.1vw;
        width: 0.1vw;
        border-radius: 50%;
        border: 0.3vw solid #8C28E1;
      }
      .circle_01_loading{
        animation: 1.5s linear infinite circleOut;
        animation-delay: 0.5s;
      }
      .circle_02_loading{
        animation: 1.5s linear infinite circleOut;
        animation-delay: 1s;
      }
      .circle_03_loading{
        animation: 1.5s linear infinite circleOut;
        animation-delay: 1.5s;
      }
    }
  }

  @keyframes circleOut {
    0% {
      opacity: 1;
      height: 0.1vw;
      width: 0.1vw;
    }
    100% {
      opacity: 0;
      height: 4vw;
      width: 4vw;
    }
  }
</style>
