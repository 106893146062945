export default {
  methods: {
    calcDateFromNow(start) {
      const now = new Date(Date.now());
      const postDate = new Date(Date.parse(start));
      const timeElapsed = now.getTime() - postDate.getTime();
      const differenceInDays = timeElapsed / (1000 * 3600 * 24);
      let textResult = 'error';
      if (differenceInDays < 1) {
        textResult = 'today';
      } else if (differenceInDays > 1 && differenceInDays <= 30) {
        textResult = `${Math.round(differenceInDays)} days ago`;
      } else {
        textResult = `${Math.round(differenceInDays / 30)} month ago`;
      }
      return textResult;
    },
  },
};
