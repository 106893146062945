<template>
  <div class="lateral radius-large" id="lateral-panel"
    :class="($router.currentRoute.path.indexOf('myTalent') === 1
      || $router.currentRoute.path.indexOf('myCompany') === 1) ? '' : 'collapse'"
  >

    <!-- <div class="header">
      <div class="cont_logo classic">
        <router-link to="/">
          <img :src="require('@/assets/logo_black.svg')">
        </router-link>
      </div>
      <div class="cont_logo cube">
        <router-link to="/">
          <img :src="require('@/assets/logo_black.svg')">
        </router-link>
      </div>
    </div> -->
    <div class="navigation"
      v-if="this.$store.state.auth.loggedIn && !loading">
      <div
        class="elem-nav radius-spe"
        :class="[
          currentPage === nav.path ? 'current' : '',
          nav.name === 'Settings' ? 'lastone box-shadow-spe' : '',
        ]"
        v-for="nav in tabs"
        :key="nav.name"
        @click.exact="() => switchTab(nav.path)"
        @click.ctrl="newTabBrowser(nav.path)"
        @auxclick="newTabBrowser(nav.path)"
        v-tooltip.top-center="{
          content: $t(nav.trlabel),
          delay: {
            show: 0,
            hide: 0,
          }
        }"
      >
        <div class="picto-nav"
        >
          <img class="picto-current" :src="require(`@/assets/pictos/${nav.picto}.svg`)">
          <img class="picto-classic" :src="require(`@/assets/pictos/${nav.pictoWhite}.svg`)">
        </div>
        <div class="label-nav">{{ $t(nav.trlabel) }}</div>
        <div class="corner-top"></div>
        <div class="corner-bot"></div>
      </div>
      <!-- <div
        class="collapse-btn"
        id="collapser"
        @click="() => handleCollapseNav()"
      >
        <img
          class="picto-more"
          :src="require('@/assets/img/arrow_black.png')"
        >
      </div> -->
    </div>

    <div class="navigation radius"
      :class="!isDashboard ? 'collapse' : ''"
      v-if="!this.$store.state.auth.loggedIn && !loading">
      <router-link
        class="elem-nav radius"
        to="/jobs"
      >
        <div class="picto-nav"></div>
        <div class="label-nav">Jobs</div>
      </router-link>
      <div class="pre_head" v-if="!loading">
        <div @click="handleLogin" class="btn-green radius">
          {{this.$store.state.auth.loggedIn? 'Logout': 'Signin'}}
        </div>
        <div
          @click="handleSignup"
          class="btn-green radius"
          v-if="!this.$store.state.auth.loggedIn"
        >
          Signup
        </div>
      </div>
      <div
        class="collapse-btn"
        id="collapser"
        @click="() => handleCollapseNav()"
      >
        <img
          class="picto-more"
          :src="require('@/assets/img/arrow_black.png')"
        >
      </div>
    </div>
    <div class="navigation radius" v-if="loading">
      <loading></loading>
    </div>
    <!-- <div class="mentions">
      <span>Copyright&nbsp;©&nbsp;2021 quasting.com</span><br>
      <span>All right reserved.</span>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/utilityPartials/_loading.vue';
import employeeTabs from '@/components/EmployeeComponents/employeeDashTabs';
import talentTabs from '@/components/TalentComponents/talentDashTabs';
// import _loading from '../utilityPartials/_loading.vue';

export default {
  name: 'LateralNavigation',
  components: {
    Loading,
    // _loading,
  },
  data() {
    return {
      url: this.baseURL || '',
      tabs: [],
      prefix: '',
      loading: true,
      isDashboard: false,
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    currentPage() {
      if (this.$route.path.split('/').pop() === 'myCompany' || this.$route.path.split('/').pop() === 'myTalent') {
        return '';
      }
      return this.$route.path.split('/')[1];
    },
    authType() {
      return this.$store.state.auth.type;
    },
  },
  methods: {
    switchTab(value) {
      if (value === '') {
        document.getElementById('lateral-panel').classList.remove('collapse');
      } else {
        document.getElementById('lateral-panel').classList.add('collapse');
      }
      this.$router.push(`${this.url}/${value}`).catch(() => {});
    },
    handleCollapseNav() {
      if (document.getElementById('lateral-panel').classList.contains('collapse')) {
        document.getElementById('lateral-panel').classList.remove('collapse');
        document.getElementById('globalcont').classList.remove('collapse');
      } else {
        document.getElementById('globalcont').classList.add('collapse');
        document.getElementById('lateral-panel').classList.add('collapse');
      }
    },
    newTabBrowser(path) {
      window.open(`${window.location.origin}/${path}`, '_blank');
    },
    initializeSidebar() {
      if (this.$store.state.auth.type === 'Employee') {
        this.tabs = employeeTabs.filter((tab) => {
          if (tab.permissions) {
            return this.$permissionList(tab.permissions);
          }
          return true;
        });
        this.prefix = 'myCompany';
        this.loading = false;
      } else if (this.$store.state.auth.type === 'Talent') {
        this.tabs = talentTabs;
        this.prefix = 'myTalent';
        this.loading = false;
      }
    },
  },
  watch: {
    authType() {
      this.initializeSidebar();
    },
  },
  mounted() {
    this.initializeSidebar();
  },
  updated() {

  },
};
</script>

<style lang="scss">

</style>
