<template>
  <div class="globalnotif">
    <div class="cont-list" id="notificationlist">
      <div class="inner-list" v-if="notifications.length > 0">
        <notification
          v-for="noti in notifications.slice(0, 10)"
          :notification="noti"
          :key="noti._id"
          :closeNotifications="closeNotifications"
          :closeActive="true"
        />
      </div>
      <div class="inner-list no-notif" v-else>
        No Notification
      </div>
    </div>
    <div
      class="goall"
      @click="notificationAllAction"
    >
      See All Notifications
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import NotificationActionsMixin from '@/modules/mixins/notificationActions';
import notification from './_notification.vue';

export default {
  name: 'NotificationList',
  mixins: [NotificationActionsMixin],
  components: {
    notification,
  },
  computed: {
    notifications() {
      const elem = [...this.$store.state.user.notifications];
      if (elem.length > 0) {
        elem.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      }
      return elem;
    },
  },
  methods: {
    closeNotifications() {
      this.$modal.hide('notification-list');
    },
    notificationAllAction() {
      if (this.$store.state.auth.type === 'Talent') {
        this.$router.push('/talent-settings?tab=1');
      } else {
        this.$router.push('/company-settings?tab=1');
      }
    },
  },
  mounted() {
    const ps = new PerfectScrollbar('#notificationlist', {
      wheelPropagation: false,
      wheelSpeed: 1,
    });
    ps.update();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
// @import "@/assets/scss/perfectscroll.scss";
.globalnotif{
  position: relative;
  width: 100%;
  height: 30vh;
  .cont-list{
    position: relative;
    height: 100%;
    min-height: 50px;
    max-height: 28vh;
    width: 100%;
    overflow: hidden;
    text-align: left;
    .inner-list{
      padding-right: 6px;
      &.no-notif{
        color: $font_gray_color;
        font-size: $classic_min_fs;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 2vh;
        padding: 0;
      }
    }
  }
  .goall{
    background-color: #ffffff;
    z-index: 1;
    font-size: $mini_fs;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}
</style>
