const EmployeeDashboard = () => import('@/views/Employee/EmployeeDashboard/EmployeeDashboard.vue');
const EditCompanyProfile = () => import('@/views/Employee/EmployeeDashboard/EditCompany.vue');
const ApplicationManagement = () => import('@/views/Employee/EmployeeDashboard/ApplicationManagement.vue');
const ProcessManagement = () => import('@/views/Employee/EmployeeDashboard/ProcessManagement.vue');
const JobManagement = () => import('@/views/Employee/EmployeeDashboard/JobManagement.vue');
const CompanySettings = () => import('@/views/Employee/EmployeeDashboard/CompanySettings.vue');
const OfferPreview = () => import('../views/Employee/OfferPreview.vue');
const ApplicantSearch = () => import('@/views/Employee/EmployeeDashboard/ApplicantSearch.vue');

const routes = [

  {
    path: '/myCompany',
    name: 'CompanyDashboard',
    component: EmployeeDashboard,
  },
  {
    path: '/offerPreview',
    name: 'OfferPreview',
    component: OfferPreview,
  },
  {
    path: '/edit-company-profile',
    name: 'Edit Company',
    component: EditCompanyProfile,
    meta: {
      authorize: ['edit_company_info'],
    },
  },
  {
    name: 'Application Management',
    path: '/application-management',
    component: ApplicationManagement,
  },
  {
    name: 'Talent Search',
    path: '/applicant-search',
    component: ApplicantSearch,
  },
  {
    name: 'Process Management',
    path: '/process-management',
    component: ProcessManagement,
    meta: {
      authorize: ['edit_process'],
    },
  },
  {
    name: 'Job Management',
    path: '/job-management',
    component: JobManagement,
    meta: {
      authorize: ['edit_offer'],
    },
  },
  {
    path: '/company-settings',
    name: 'Company Settings',
    component: CompanySettings,

  },

];

export default routes.map((route) => {
  const metaRoute = route;
  metaRoute.meta = { ...metaRoute.meta, type: 'Employee', loggedIn: true };
  return metaRoute;
});
