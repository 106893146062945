const message = {
  en: {
    login: 'Login',
    logout: 'Logout',
    signup: 'Sign Up',
    signin: 'Sign In',
    search: 'Search',
    settings: 'Settings',
    seecompanyprofile: 'Company Profile',
    seetalentprofile: 'Profile',
    dash_upcoming_subtitle: 'Upcoming Events <i>(today)</i>',
    upcoming_no_event: 'No Upcoming Event',
    dash_notification: 'Last Notification',
    notification_no: 'No Notification',
    seeall: 'See All',
    dash_currentjoboffer_subtitle: 'Current Job Offers',
    dash_lastnewsfeed_subtitle: 'Last News',
    menu_dashboard: 'Dashboard',
    page_title_dashboard: 'Dashboard',
    menu_feed: 'Newsfeed',
    page_title_feed: 'Newsfeed',
    menu_applicationmanagement: 'Applications Management',
    page_title_applicationmanagement: 'Applications Management',
    menu_jobmanagement: 'Offers Management',
    page_title_jobmanagement: 'Offers Management',
    menu_processmanagement: 'Process Management',
    page_title_processmanagement: 'Process Management',
    menu_forum: 'Forum',
    page_title_forum: 'Forum',
    menu_calendar: 'Calendar',
    page_title_companyprofile: 'Company',
    menu_companyprofile: 'Company',
    menu_talentprofile: 'Profile',
    page_title_medialibrary: 'Media library',
    menu_medialibrary: 'Media Library',
    page_title_settings: 'Settings',
    menu_settings: 'Settings',
    lessthanoneday: 'today',
    day: 'day',
    days: 'days',
    month: 'month',
    months: 'months',
    year: 'year',
    years: 'years',
    // FEED
    createpost: 'Create Your Post',
    myposts: 'My Posts',
    addpicture: 'Add Picture',
    addvideo: 'Add Video',
    addpdf: 'Add PDF',
    addaudio: 'Add Audio',
    followed: 'Followed',
    placeholder: 'What do you want to share?',
    nbcar: 'car.',
    // APPLICATION MANAGEMENT
    selectajoboffer: 'Select a Job Offer',
    applications: 'Applications',
    lastapplications: 'Last Applications',
    rejectedapplications: 'Rejected Applications',
    approvedapplications: 'Approved Applications',
    applicantsstatus: 'Applicant Status',
    viewoffer: 'View Offer',
    editoffer: 'Edit Offer',
    editnotifications: 'Edit Notifications',
    deleteoffer: 'Delete Offer',
    currentprocess: 'Current Process',
    // JOB MANAGEMENT
    // global settings
    globalsettings: 'Global Settings',
    jobsname: 'Job\'s Name',
    jobsdescrition: 'Job\'s Descrition',
    sector: 'Field',
    location: 'Location',
    desiredprofiledesc: 'Profile Description',
    desiredlvlstudy: 'Eductation',
    expectedexp: 'Year(s) of Experience',
    nomatch: 'No Match',
    current: 'Current',
    approved: 'Approved',
    rejected: 'Rejected',
    // offer settings
    offersettings: 'Offer Settings',
    begincontract: 'Befinning of the Contract',
    interviewprocess: 'Interview Process',
    contracttype: 'Type of Contract',
    fulltime: 'Full-Time',
    freelance: 'Freelance',
    parttime: 'Part-Time',
    internship: 'Intership',
    teleworking: 'Teleworking?',
    yes: 'Yes',
    no: 'No',
    partial: 'Partial',
    displaynumapplication: 'Display number of application?',
    critera: 'Critera',
    edit: 'Edit',
    category: 'Category',
    typeaskill: 'Type a skill',
    selecttechno: 'Select a technology',
    numberofyear: 'Number of year',
    color: 'Color',
    // salary settings
    salarysettings: 'Salary settings',
    salaryfrequency: 'Salary Frequency',
    annual: 'Annual',
    monthly: 'Monthly',
    daily: 'Daily',
    unspecified: 'Unspecified',
    salarycurrency: 'Salary currency',
    salaryinfo: 'Salary info',
    number: 'Number',
    range: 'Range',
    salaryamount: 'Salary amount',
    salaryrange: 'Salary range',
    // options
    options: 'Options',
    diffusion: 'Audiance',
    public: 'Public',
    platform: 'Platform',
    intern: 'Intern',
    motivationletter: 'Cover Letter',
    resumefile: 'Resume (file)',
    resumefromprofile: 'Resume (from profile)',
    portfolio: 'Portfolio (link)',
    profilcompletion: 'Profile Completion Required',
    percentagecompletion: 'Percentage of profile completion',
    countrypermission: 'Country Permission Required',
    country: 'Country',
    mandatory: 'Mandatory',
    optional: 'Optional',
    disabled: 'Disabled',
    // visual
    visual: 'Visual',
    uploadfile: 'Upload file',
    preview: 'Preview',
    btn_add: 'Add',
    btn_submit: 'Submit',
    btn_addnewjoboffer: 'Add a New Job Offer',
    delete: 'Delete',
    recommend: 'Recommend',
    share: 'Share',
  },
  fr: {
  },
};
export default message;
