<template>
  <div class="select-switch">
    <div class="elem-select-switch radius box-shadow"
      v-for="(elem, index) in dataSwitch.data"
      :key="index"
      :class="{selected: index === selectedIndex}"
      @click="() => handleSwitch(index)"
    >
      <img :src="elementIcon(elem)"
        v-if="elementIcons && elementIcon(elem)"
      >

      <span>{{returnDataElem(elem)}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: ['dataSwitch', 'value', 'elementIcons'],
  data() {
    return {
      selectedIndex: this.dataSwitch.data.indexOf(this.value),
    };
  },

  mounted() {
  },
  methods: {
    handleSwitch(index) {
      this.$emit('input', this.dataSwitch.data[index]);
    },
    returnDataElem(elem) {
      // let valreturn = elem;
      if (elem === true || elem === false) {
        if (elem === true) {
          return 'Yes';
        }
        return 'No';
      }
      return elem;
    },
    elementIcon(element) {
      const icon = this.elementIcons.find((elem) => elem.name === element);
      return icon ? icon.iconRoute : false;
    },
  },
  watch: {
    value(newValue) {
      this.selectedIndex = this.dataSwitch.data.indexOf(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .select-switch{
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .elem-select-switch{
      font-size: $fnt-14;
      width: calc(calc(calc(100% - 3vh) / 4) - 4vh);
      text-align: center;
      padding: 1.25vh 2vh;
      cursor: pointer;
      margin-right: 1vh;
      background-color: #ffffff;
      &:last-child{
        margin-right: 0;
      }
      &.selected{
        color: #ffffff;
        border: 0;
        background-color: $logo_color;
      }
      &:hover{
        background-color: $green-color;
        color: $text_color;
      }
    }
    &.full{
      &.tier{
        .elem-select-switch{
          width: calc(calc(calc(100% - 2vh) / 3) - 4vh);
        }
      }

      &.half{
        .elem-select-switch{
          width: calc(calc(calc(100% - 1vh) / 2) - 4vh);
        }
      }
    }
  }
</style>
