import { render, staticRenderFns } from "./selectSwitch.vue?vue&type=template&id=d241e748&scoped=true&"
import script from "./selectSwitch.vue?vue&type=script&lang=js&"
export * from "./selectSwitch.vue?vue&type=script&lang=js&"
import style0 from "./selectSwitch.vue?vue&type=style&index=0&id=d241e748&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d241e748",
  null
  
)

export default component.exports