import VueRouter from 'vue-router';
import UniversalRoutes from './universalRoutes';
import EmployeeRoutes from './employeeRoutes';
import TalentRoutes from './talentRoutes';

const routes = [...UniversalRoutes, ...EmployeeRoutes, ...TalentRoutes];
const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
