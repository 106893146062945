import axios from 'axios';
import rfdc from 'rfdc';

const clone = rfdc();

axios.defaults.headers = { 'Access-Control-Origin': '*' };

const apiInterface = (store) => {
  axios.interceptors.request.use((config) => {
    if (config.url
        && config.url.indexOf('/feed/') !== -1
        && store.state.feed.companyView
    ) {
      const updatedConfig = config;
      if (updatedConfig.params) {
        updatedConfig.params.company = true;
      } else {
        updatedConfig.params = { company: true };
      }
      return updatedConfig;
    }
    return config;
  });
  const apiObject = {
    get: (route, routeOptions = {}, headerOptions = {}) => axios.get(
      `${process.env.VUE_APP_API_URL}/${route}`,
      {
        headers:
        { Authorization: `Bearer ${store.state.auth.token}`, ...headerOptions },
        ...routeOptions,
      },
    ),
    post: (route, data = {}, routeOptions = {}, headerOptions = {}) => axios.post(
      `${process.env.VUE_APP_API_URL}/${route}`,
      { ...data },
      {
        headers:
        { Authorization: `Bearer ${store.state.auth.token}`, ...headerOptions },
        ...routeOptions,
      },
    ),
    put: (route, data = {}, routeOptions = {}, headerOptions = {}) => axios.put(
      `${process.env.VUE_APP_API_URL}/${route}`,
      { ...data },
      {
        headers:
        { Authorization: `Bearer ${store.state.auth.token}`, ...headerOptions },
        ...routeOptions,
      },
    ),
    delete: (route, routeOptions = {}, headerOptions = {}) => axios.delete(
      `${process.env.VUE_APP_API_URL}/${route}`,
      {
        headers:
        { Authorization: `Bearer ${store.state.auth.token}`, ...headerOptions },
        ...routeOptions,
      },
    ),
    raw: (method, route, data = false, headerOptions = {}) => {
      const params = {
        method,
        url: route,
        headers: { ...headerOptions },
      };
      if (data) {
        params.data = data;
      }
      return axios(params);
    },
    pruneObject: (object) => {
      const prune = (current) => {
        Object.keys(current).forEach((key) => {
          if (current[key] && typeof current[key] === 'object') {
            prune(current[key]);
            if (Object.keys(current[key]).length === 0) {
              // eslint-disable-next-line no-param-reassign
              delete current[key];
            }
          } else if (current[key] === '') {
            // eslint-disable-next-line no-param-reassign
            delete current[key];
          }
        });
        return current;
      };
      return prune(clone(object));
    },
  };

  return apiObject;
};

export default {
  // eslint-disable-next-line no-shadow
  install(Vue, store) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$api = apiInterface(store);
  },
};
