<template>
  <div class="elem-preview-file" :class="onlyPreview ? 'onlyPreview' : ''">
    <div
      class="radius"
      v-if="filePreview"
      :class="type === 'AUDIO'?'audio-preview':''"
    >
      <div class="icon-type-file">
        <img :src="require('@/assets/pictos/picto_photo_white.svg')" v-if="type === 'IMAGE'">
        <img :src="require('@/assets/pictos/picto_video_white.svg')" v-if="type === 'VIDEO'">
        <img :src="require('@/assets/pictos/picto_pdf_white.svg')" v-if="type === 'PDF'">
        <img :src="require('@/assets/pictos/picto_audio_white.svg')" v-if="type === 'AUDIO'">
      </div>
      <div class="play-icon" v-if="type === 'VIDEO'">
        <img :src="require('@/assets/pictos/picto_play_video.svg')">
      </div>
      <img class="preview" v-if="type === 'IMAGE'" :src="filePreview" />
      <video class="preview"  v-else-if="type === 'VIDEO'" :src="filePreview" />
      <embed class="preview pdf-preview"
        v-else-if="type === 'APPLICATION' || type === 'PDF'"
        :src="filePreview" />
      <img class="preview"
        v-else-if="type === 'AUDIO'"
        :src="require('@/assets/pictos/picto_soundwave.svg')"
      />
      <div class="preview" v-else>
        File Selected!
      </div>
    </div>
    <div class="overlay-shadow"></div>
  </div>
</template>

<script>
export default {
  name: 'FilePreview',
  props: ['filePreview', 'fileType', 'onlyPreview'],
  computed: {
    type() {
      return this.fileType.toUpperCase();
    },
  },
};
</script>

<style lang="scss">

.elem-preview-file{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  .radius{
    position: relative;
    width: 100%;
    height: 100% !important;
    background-color: transparent;
    &.audio-preview{
      background-color: #DADCDE;
      .preview{
        width: 20vh !important;
      }
    }
    .icon-type-file{
      position: absolute;
      top: 1vh;
      right: 1vh;
      background-color: rgba(159, 167, 175, 0.5);
      height: 4vh;
      width: 4vh;
      border-radius: 50% !important;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 3vh !important;
      }
    }
    .play-icon{
      position: absolute;
      top: 50%;
      left: 50%;
      height: 7vh;
      width: 7vh;
      transform: translateX(-50%) translateY(-50%);
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        // width: 3vh !important;
      }
    }
    img{
      font-size: 0;
      width: 100%;
    }
    video{
      width: 100%;
    }
    .preview{
      width: 100%;
      height: auto;
      pointer-events: none;
    }
    .pdf-preview{
      height: 100%;
      position: absolute;
      z-index: 20;
    }
  }
  .overlay-shadow{
    width: 100%;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 0.5vh rgba(22, 0, 51, 0.45);
    background-color: transparent !important;
    transition: all .2s;
  }
  &:hover{
    .overlay-shadow{
      box-shadow: inset 0 0 0.75vh rgba(72, 255, 175, 1);
    }
  }
  &.onlyPreview{
    border-radius: 1vh;
    overflow: hidden;
    .radius{
      text-align: center;
      font-size: 0;
      .preview{
        height: 100%;
        width: auto;
      }
    }
    &:hover{
      .overlay-shadow{
        box-shadow: inset 0 0 0.5vh rgba(22, 0, 51, 0.45);
      }
    }
  }
}

</style>
