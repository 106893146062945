<template>
  <div class="upload-input video">
    <div class="cont-video">
      <video id="myVideo" class="video-js vjs-default-skin" playsinline>
      </video>
      <div class="cont-video-btn-center">
        <div class="btn btn-green radius"
          v-if="!isDeviceSelected" @click="selectDevice"
        >
          Select devices
        </div>
        <div class="popin-select-device radius box-shadow" v-if="isDeviceGet && !isDeviceSelected">
          <div class="title-select-device">
            Select your devices
          </div>
          <div class="sub-select-device">
            Audio source :
          </div>
          <v-select
            class="small-vs"
            :clearable="false"
            :searchable="false"
            :options="this.audioDevice"
            placeholder="Audio device"
            v-model="selectedAudio"
            label="label"
          >
          </v-select>
          <div class="sub-select-device">
            Camera source :
          </div>
          <v-select
            class="small-vs"
            :clearable="false"
            :searchable="false"
            :options="this.cameraDevice"
            placeholder="Camera device"
            v-model="selectedCamera"
            label="label"
          >
          </v-select>

          <div class="cont-btn">
            <div class="btn btn-green radius"
              v-if="isCameraSelected && isAudioSelected" @click="getDevice"
            >
              Set recording
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="cont-video-btn">
      <div class="btn btn-green radius"
        v-if="!recording && !paused && readytorecord && timeData === ''"
        @click="startRecording" >Start recording</div>
      <div class="btn btn-green radius"
        v-if="!recording && !paused && readytorecord && timeData !== ''"
        @click="startRecording" >Restart recording</div>
      <div class="btn btn-green radius"
        v-if="recording && !paused" @click="pauseRecording" >Pause</div>
      <div class="btn btn-green radius"
        v-if="recording && paused" @click="resumeRecording" >Resume</div>
      <div class="btn btn-green radius"
        v-if="!recording && !paused && timeData !== ''" @click="playVideo">Play</div>
      <div class="btn btn-green radius"
        v-if="recording || paused" @click="stopRecording" >Stop recording</div>
      <div class="timing">
        {{timeData}}
      </div>
    </div>

  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import 'videojs-record/dist/css/videojs.record.css';

import 'webrtc-adapter';
import RecordRTC from 'recordrtc';
// eslint-disable-next-line no-unused-vars
import Record from 'videojs-record/dist/videojs.record';

const videoJsImport = () => import('video.js');

export default {
  name: 'VideoUpload',
  computed: {
    timeRecord() {
      return this.player.record().getCurrentTime();
    },
  },
  methods: {
    upload() {
      return new Promise((resolve) => {
        const file = this.recordedFile;
        if (file) {
          this.$api.post('getSignedUrl', { filename: file.name, filetype: file.type }).then((res) => {
            const fileUploadPath = res.data.link;
            const fileEndpoint = res.data.s3Endpoint;
            this.$api.raw('PUT', fileUploadPath, file).then(() => {
              resolve(fileEndpoint);
            });
          });
        } else {
          resolve(false);
        }
      });
    },
    selectDevice() {
      return this.player.record().enumerateDevices();
    },
    getDevice() {
      this.player.record().setAudioInput(this.selectedAudio._id);
      this.player.record().setVideoInput(this.selectedCamera._id);
      this.player.record().getDevice();
      this.isDeviceSelected = true;
    },
    stopRecording() {
      this.player.record().stop();
      this.paused = false;
    },
    startRecording() {
      this.player.record().start();
      this.paused = false;
    },
    pauseRecording() {
      this.player.record().pause();
      this.paused = true;
    },
    resumeRecording() {
      this.player.record().resume();
      this.paused = false;
    },
    playVideo() {
      document.getElementById('myVideo_html5_api').play();
    },
  },
  data() {
    return {
      paused: false,
      recording: false,
      recordedFile: '',
      readytorecord: false,
      player: '',
      timeData: '',
      isDeviceGet: false,
      isDeviceSelected: false,
      isCameraSelected: false,
      isAudioSelected: false,
      audioDevice: [],
      selectedAudio: {
        label: 'Select audio source',
      },
      cameraDevice: [],
      selectedCamera: {
        label: 'Select camera source',
      },
      options: {
        controls: false,
        autoplay: false,
        fluid: false,
        loop: false,
        width: 320,
        height: 240,
        bigPlayButton: true,
        controlBar: {
          volumePanel: false,
        },
        plugins: {
          record: {
            audio: true,
            video: true,
            debug: true,
            maxLength: 3600,
            timeSlice: 1000,
          },
        },
      },
    };
  },

  mounted() {
    videoJsImport().then((videojsModule) => {
      const videojs = videojsModule.default;
      this.player = videojs('#myVideo', this.options, () => {
        // print version information at startup
        const msg = `Using video.js ${videojs.VERSION} with videojs-record ${videojs.getPluginVersion('record')} 
      and recordrtc ${RecordRTC.version}`;
        videojs.log(msg);
      });

      // device is ready
      this.player.on('deviceReady', () => {
        console.log('device is ready!');
        this.readytorecord = true;
      });

      // user clicked the record button and started recording
      this.player.on('startRecord', () => {
        this.recording = true;
        this.$emit('ready', false);
      });

      // user completed recording and stream is available
      this.player.on('finishRecord', () => {
        this.recording = false;
        this.paused = false;
        this.recordedFile = this.player.recordedData;
        this.$emit('ready', true);
      });

      // error handling
      this.player.on('error', (element, error) => {
        console.warn(error);
      });

      this.player.on('deviceError', () => {
        console.error('device error:', this.player.deviceErrorCode);
      });

      this.player.on('enumerateReady', () => {
        const devicesElem = this.player.record().devices;
        devicesElem.forEach((device) => {
          if (device.kind === 'audioinput') {
            this.audioDevice.push({
              label: device.label,
              _id: device.deviceId,
            });
          } else if (device.kind === 'videoinput') {
            this.cameraDevice.push({
              label: device.label,
              _id: device.deviceId,
            });
          }
        });
        this.isDeviceGet = true;
      });
      this.player.on('timestamp', () => {
        const dateObj = new Date(this.player.currentTimestamp - this.player.allTimestamps[0]);
        let second = dateObj.getSeconds();
        let minutes = dateObj.getMinutes();
        if (second < 10) {
          second = `0${second}`;
        }
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }
        this.timeData = `${minutes}:${second}`;
      });
    });
  },
  watch: {
    selectedAudio(item) {
      console.log('itemAudio : ', item);
      this.isCameraSelected = true;
    },
    selectedCamera(item) {
      console.log('itemVideo : ', item);
      this.isAudioSelected = true;
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.upload-input{
  &.video{
    height: unset !important;
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0,0,0);
  }
  .cont-video-btn{
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .btn{
      margin-right: 1vh;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .cont-video{
    position: relative;
    width: 100%;
    height: 450px;
    .video-js{
      position: relative;
      width: 100%;
      height: 450px;
      .vjs-icon-av-perm{
        display: none;
      }
    }
    video{
      width: 100%;
      height: 450px;
    }
    .cont-video-btn-center{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      .popin-select-device{
        position: absolute;
        padding: 1vh;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: #ffffff;
        min-width: 30vw;
        .title-select-device{
          font-size: 3vh;
          font-weight: 600;
          color: $logo_color;
        }
        .sub-select-device{
          margin-top: 2vh;
          margin-bottom: 1vh;
        }

        .cont-btn{
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }
}
</style>
