/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';

const state = () => ({
  loaded: false,
  events: [],
  potentialDates: false,
});

const getDatesOfDay = (date) => {
  const start = new Date(date);
  start.setHours(0, 0, 0, 0);
  const end = new Date(date); // copy
  end.setHours(24, 0, 0, 0);
  return { start, end };
};

const filterPotential = (date, event) => {
  const end = new Date(event.date.end);
  if (date) {
    const dateLimits = getDatesOfDay(date);
    return dateLimits.start <= end && end <= dateLimits.end;
  }
  const dateTime = Date.now();
  return dateTime <= end;
};

const getters = {
  loaded: (state) => state.loaded,
  upcoming: (state) => (date = false) => (
    state.loaded
      ? state.events
        .filter((event) => filterPotential(date, event))
        .sort((a, b) => {
          const aDate = new Date(a.date.start);
          const bDate = new Date(b.date.start);
          if (aDate > bDate) {
            return -1;
          }
          if (aDate < bDate) {
            return 1;
          }
          return 0;
        })
        .sort((a, b) => new Date(a.date.start) - new Date(b.date.start))
      : false
  ),
  upcomingPotential: (state) => (date = false) => (
    state.potentialDates
      ? state.potentialDates
        .filter((event) => filterPotential(date, event))
        .sort((a, b) => {
          const aDate = new Date(a.date.start);
          const bDate = new Date(b.date.start);
          if (aDate > bDate) {
            return -1;
          }
          if (aDate < bDate) {
            return 1;
          }
          return 0;
        })
        .sort((a, b) => new Date(a.date.start) - new Date(b.date.start))
      : false
  ),

};

const actions = {
  getCalendar({ commit }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.get('calendar').then((res) => {
        commit('updateEvents', res.data);
        resolve(res.data);
      });
    });
  },
  addEvent({ commit }, event) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('calendar', { eventInfo: event }).then((res) => {
        commit('addEvent', res.data);
        resolve(res.data);
      });
    });
  },

  editEvent({ commit }, { newEventData, eventId }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.put(`calendar/${eventId}`, { event: newEventData }).then((res) => {
        if (res.status !== 401) {
          commit('updateEvent', res.data);
          resolve(res.data);
        }
      });
    });
  },

  deleteEvent({ commit }, eventId) {
    return new Promise((resolve) => {
      Vue.prototype.$api.delete(`calendar/${eventId}`).then((res) => {
        if (res.status !== 401) {
          commit('removeEvent', eventId);
          resolve(eventId);
        }
      });
    });
  },

  leaveEvent({ commit }, eventId) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('calendar/leaveEvent', { eventId }).then((res) => {
        if (res.status !== 401) {
          commit('removeEvent', eventId);
          resolve(eventId);
        }
      });
    });
  },

  getPotentialDatesTalent({ commit, state, rootState }) {
    const approvedOffers = rootState.talent.offers.filter((offer) => offer.application.status === 'approved');
    const potentialDates = [];
    approvedOffers.forEach((offer) => {
      // Compname offer.offer.comnpanyName
      // offerId: offer.application._id
      // applicant: offer.application._id

      // status === 'Current' && !eventAccepted && possibleTimes.length > 0
      // possibleTimes end Start
      // stepId = currentId._id
      const currentStep = offer.application.process.find(
        (step) => step.status === 'Current'
        && step.meeting
        && !step.meeting.eventAccepted
        && step.meeting.possibleTimes
        && step.meeting.possibleTimes.length > 0,
      );
      if (currentStep) {
        currentStep.meeting.possibleTimes.forEach((time) => {
          potentialDates.push({
            processRef: {
              applicant: offer.application._id,
              offer: offer.offer._id,
              step: currentStep._id,
            },
            start: new Date(time.start),
            end: new Date(time.end),
            date: time,
            class: 'potential',
            title: `Possible time for ${currentStep.name} @ ${offer.offer.companyName}`,
          });
        });
      }
    });
    commit('setPotentialDates', potentialDates);
  },

  async getPotentialDatesEmployee({ commit, rootState }) {
    // applicant: offer.applicants[x].user.name.firstName
    // offerId: offer._id
    // applicant: offer.applicants[x]._id

    // status === 'Current' && !eventAccepted && possibleTimes.length > 0
    // possibleTimes end Start
    // stepId = currentId._id
    const openOffers = rootState.company.offers.filter((offer) => offer.applicants.length > 0);
    const potentialDates = [];

    openOffers.forEach((offer) => {
      offer.applicants
        .filter((app) => app.status === 'approved')
        .forEach((applicant) => {
          const currentProcess = applicant.process.find(
            (step) => step.status === 'Current'
            && step.meeting
            && !step.meeting.eventAccepted
            && step.meeting.possibleTimes
            && step.meeting.possibleTimes.length > 0,
          );
          if (currentProcess) {
            currentProcess.meeting.possibleTimes.forEach((time) => {
              potentialDates.push({
                processRef: {
                  applicant: applicant._id,
                  offer: offer._id,
                  step: currentProcess._id,
                },
                start: new Date(time.start),
                end: new Date(time.end),
                date: time,
                class: 'potential',
                title: `Possible time for ${currentProcess.name} @ ${applicant.user.name.firstName} ${applicant.user.name.lastName} `,
              });
            });
          }
        });
    });
    commit('setPotentialDates', potentialDates);
  },

};
const mutations = {
  updateEvents(state, events) {
    state.events = events;
    state.loaded = true;
  },
  addEvent(state, event) {
    state.events.push(event);
  },
  updateEvent(state, newEvent) {
    const eventIndex = state.events.findIndex((calEvent) => calEvent._id === newEvent._id);
    Vue.set(state.events, eventIndex, newEvent);
  },
  removeEvent(state, eventId) {
    const eventIndex = state.events.findIndex((calEvent) => calEvent._id === eventId);
    Vue.delete(state.events, eventIndex);
  },
  setPotentialDates(state, dates) {
    state.potentialDates = dates;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
