function fetchFromObject(obj, prop) {
  if (typeof obj === 'undefined') {
    return false;
  }

  const index = prop.indexOf('.');
  if (index > -1) {
    return fetchFromObject(obj[prop.substring(0, index)], prop.substr(index + 1));
  }

  return obj[prop];
}

const validateMixin = {
  methods: {
    // callback object can have
    // onFailure / onSuccess / onCall
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const result = re.test(String(email).toLowerCase());
      return result;
    },
    validateUrl(url) {
      const re = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
      const result = re.test(String(url).toLowerCase());
      return result;
    },
    validateFields(callbackObject = {}) {
      let check = true;
      this.$el.querySelectorAll('[requiredVal]').forEach((elem) => {
        elem.setAttribute('required', 'required');

        const valuePath = elem.attributes.requiredVal.value;
        const value = fetchFromObject(this.$data, valuePath);
        if (value === '' || value === [] || !value || value === {}) {
          check = false;
          elem.classList.add('input-error');
        } else {
          elem.classList.remove('input-error');
        }
      });
      if (!check && callbackObject.onFailure) {
        callbackObject.onFailure();
      } else if (check && callbackObject.onSuccess) {
        callbackObject.onSuccess();
      }
      if (callbackObject.onCall) {
        callbackObject.onCall();
      }
      return check;
    },
    clearValidatedFields() {
      this.$el.querySelectorAll('.input-error').forEach((elem) => {
        elem.classList.remove('input-error');
      });
    },
    highlightParentError(parentClass) {
      this.$el.querySelectorAll('.input-error').forEach((elem) => {
        elem.closest(`.${parentClass}`).classList.add('input-error');
      });
    },
    highlightParentSection(parentClass) {
      this.$el.querySelectorAll('.input-error').forEach((elem) => {
        const section = elem.closest(`.${parentClass}`).attributes.section.value;
        document.getElementById(`${section}-setting`).classList.add('input-error');
      });
    },
  },

};

export default validateMixin;
