<template>
  <div>
    <autocomplete
      type="text"
      :placeholder="this.placeHolder"
      @submit="updateVal"
      :search="updateSearch"
      baseClass="auto-input"
    />

  </div>

</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';

export default {
  name: 'SearchBar',
  components: {
    Autocomplete,
  },
  props: ['value', 'terms', 'placeHolder'],
  methods: {
    updateVal(text) {
      this.$emit('input', text);
    },
    updateSearch(text) {
      this.updateVal(text);
      return this.terms.filter(
        (option) => option.toLowerCase().indexOf(text.toLowerCase()) > -1,
      );
    },

    updateSearchText(result) {
      if (result && typeof result === 'string') {
        this.searchText = result;
      }
      this.$store.dispatch('search/setAndSearch', this.searchText);
      if (this.redirect) {
        this.$router.push('/search');
      }
    },
  },

};
</script>

<style  >

.auto-input-input {
  border: 1px solid #eee;
  border-radius: 0.5vh;
  width: 100%;
  padding: 1.5vh 1vh;
  box-sizing: border-box;
  position: relative;
  font-size: 1.4vh;
  flex: 1;
  background-color: #eee;
}

.auto-input-input:focus,
.auto-input-input[aria-expanded="true"] {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  outline: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
}

[data-position="below"] .auto-input-input[aria-expanded="true"] {
  border-bottom-color: transparent;
  border-radius: 8px 8px 0 0;
}

[data-position="above"] .auto-input-input[aria-expanded="true"] {
  border-top-color: transparent;
  border-radius: 0 0 8px 8px;
  z-index: 2;
}

/* Loading spinner */
.auto-input[data-loading="true"]::after {
  content: "";
  border: 3px solid rgba(0, 0, 0, 0.12);
  border-right: 3px solid rgba(0, 0, 0, 0.48);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  animation: rotate 1s infinite linear;
}

.auto-input-result-list {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0;
  box-sizing: border-box;
  max-height: 296px;
  overflow-y: auto;
  background: #fff;
  list-style: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
}

[data-position="below"] .auto-input-result-list {
  margin-top: -1px;
  border-top-color: transparent;
  border-radius: 0 0 8px 8px;
  padding-bottom: 8px;
}

[data-position="above"] .auto-input-result-list {
  margin-bottom: -1px;
  border-bottom-color: transparent;
  border-radius: 8px 8px 0 0;
  padding-top: 8px;
}

/* Single result item */
.auto-input-result {
  cursor: default;
  padding: 12px 12px 12px 4px;
}

.auto-input-result:hover,
.auto-input-result[aria-selected="true"] {
  background-color: rgba(0, 0, 0, 0.06);
}

@keyframes rotate {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateY(-50%) rotate(359deg);
  }
}
</style>
