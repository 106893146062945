<template>
  <div class="process-edit" >
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="company-info">
      <div class="company-title">
        Company Info
      </div>
      <company-info :offer="this.offer" :leaveApplication="leaveApplication"/>
    </div>
     <div class="steps-container" id="step-list">
        <step-display v-for="(step, index) in this.offer.application.process"
        :key="step._id" :step="step"
        >
          <!-- {{step}} -->

          <div class="status" v-if="step.status && step.status !== 'Initial'">
            <div
              class="btn-green radius small"
              v-if="step.publicFeedback && step.publicFeedback !== 'undefined'"
              @click="() => seeFeedback(step)"
            >
              Check Feedback
            </div>
          </div>
          <div class="apointment" v-if="appointmentOpen(step) && step.status === 'Current'">
            <div class="btn-choice">
              <div
                class="btn-green radius"
                v-if="choosingAppointment !== index "
                @click="() =>chooseAppointment(step, index)">
                Set Date
              </div>
            </div>
            <div class="cont-multiple-date" v-if="choosingAppointment === index && possibleTimes" >
              <div class="label">Select a appointment date</div>
              <div class="inner-multiple-date">
                <div
                  v-for="(timeSlot, index) in possibleTimes"
                  :key="index"
                  @click="() => setDate(step, timeSlot.start)"
                  class="date-option radius boxshadow"
                >
                  <div class="date">{{timeSlot.start | moment('DD/MM/YY')}}</div>
                  <div class="hours">
                    from {{timeSlot.start | moment('HH:mm a')}}
                    to {{timeSlot.end | moment('HH:mm a')}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="cont-phone" v-if="step.action === 'Phone Call'">
              <div class="edit-phone" v-if="editingPhone">
                <input
                  class="v-select"
                  type="number"
                  placeholder="ex : +33 (0)6 01 02 03 04 or 336-33-336"
                  v-model="step.meeting.phoneNumber"
                />
                <div class="btn-green radius"
                  @click="() =>setPhoneNumber(step, step.meeting.phoneNumber)">Submit</div>
              </div>
              <div class="phone" v-else>
                <span>Best Number to Reach You:</span>
                {{step.meeting.phoneNumber ? step.meeting.phoneNumber : 'Please Submit a Number'}}
              </div>
              <div class="btn-choice" v-if="editingPhone === false">
                <div
                  class="btn-green radius center"
                  @click="() => {editingPhone = true;}"
                  v-if="step.meeting.phoneNumber"
                >
                  Edit
                </div>
                <div
                  class="btn-green radius center"
                  @click="() => {editingPhone = true;}"
                  v-else
                >
                  Set a phone number
                </div>
              </div>
          </div>

          <div class="apointment"
            v-if="step.meeting.event &&
              step.meeting.event.meetingInProgress &&
              step.status === 'Current'"
          >
            <div class="btn-choice">
              <div class="btn-green radius"
                @click="() => joinVideoCall(step.meeting.event._id)">Join Call </div>
            </div>
          </div>
          <div class="test-space" v-if="step.action === 'Coding Test' && step.technicalTest
          && step.status === 'Current'">
            <div class="button-download" v-if="step.technicalTest.testFile.file">
                <div class="btn-green"
                @click="() => downloadMedia(step.technicalTest.testFile)">
                  Download Test
                </div>
                <upload-test v-model="step.technicalTest"
                  @submit="() => uploadCompletedFile(step)"/>
            </div>
            <div class="link-techtest"
              v-if="step.technicalTest.testLink &&step.technicalTest.testLink.link"
            >
              <a :href="step.technicalTest.testLink.link">
                Click here to go to your test
              </a>
            </div>
            <div class="test-instructions"  v-if="step.technicalTest.instructions" >
              <span class="inst-title">Instructions:</span>
              <div
                class="instructions"
                v-html="step.technicalTest.instructions"
                />
            </div>
          </div>

       </step-display>
     </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import StepDisplay from '@/components/UniversalComponents/Offer/Process/_stepDisplay.vue';
// import JobSideDisplay from '@/
// components/UniversalComponents/Offer/OfferDisplay/JobSideDisplay.vue';
// import TalentSkills from '@/components/UniversalComponents/TalentView/TalentSkills.vue';
// import ResumeEntry from '@/components/TalentComponents/resume/ResumeEntry.vue';
import FeedbackTalent from '@/components/TalentComponents/offer/feedbackTalent.vue';
import downloadMedia from '@/modules/mixins/downloadMedia';
import UploadTest from '@/components/TalentComponents/offer/UploadTestCompleted.vue';
import CompanyInfo from './companyInfo.vue';
// import PostView from '@/components/UniversalComponents/Feed/_post.vue';
// import ExpandSection from '../../utilityPartials/expandSection.vue';

export default {
  name: 'OfferProgressTalent',
  props: ['offer'],
  mixins: [downloadMedia],
  components: {
    StepDisplay,
    CompanyInfo,
    // JobSideDisplay,
    // ExpandSection,
    // TalentSkills,
    // ResumeEntry,
    UploadTest,
    // PostView,
  },
  data() {
    return {
      possibleTimes: false,
      choosingAppointment: false,
      applicationInformation: false,
      instructionOpen: false,
      editingPhone: false,
    };
  },

  methods: {
    leaveApplication() {
      this.$store.dispatch('talent/leaveApplication', this.offer.offer._id).then(() => {
        this.$modal.hide('offer-process');
      });
    },
    getPossibleTimes(step) {
      this.possibleTimes = step.meeting.possibleTimes;
    },
    setPhoneNumber(step, newNumber) {
      this.$store.dispatch('talent/setPhoneNumber', {
        offerId: this.offer.offer._id,
        applicationId: this.offer.application._id,
        stepId: step._id,
        phoneNumber: newNumber,
      }).then(() => {
        this.editingPhone = false;
      });
    },
    setDate(step, time) {
      // Test accept
      // Required offerId, applicationId, stepId, eventStart(Date)
      this.$store.dispatch('talent/acceptMeeting', {
        offerId: this.offer.offer._id,
        applicationId: this.offer.application._id,
        stepId: step._id,
        eventStart: time,
      });
    },
    uploadCompletedFile(step) {
      this.$store.dispatch('talent/uploadTest', {
        offerId: this.offer.offer._id,
        applicationId: this.offer.application._id,
        stepId: step._id,
        file: step.technicalTest.completedTestFile,
      });
    },
    seeFeedback(step) {
      this.$modal.show(FeedbackTalent,
        {
          feedback: step.publicFeedback,
        }, {
          name: 'feedback-talent',
          classes: ['overflow'],
        });
    },
    chooseAppointment(step, index) {
      this.possibleTimes = false;
      this.choosingAppointment = index;
      this.getPossibleTimes(step);
    },
    appointmentOpen(step) {
      return !step.meeting.eventAccepted && step.meeting.possibleTimes.length > 0;
    },
    joinVideoCall(eventId) {
      this.$modal.hide('offer-process');
      window.open(`${window.location.origin}/videochat/${eventId}`);
    },
    redirect() {
      this.$modal.hide('offer-process');
      this.$router.push(`/offer/${this.offer.offer._id}`);
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'offer-process');
    },
  },
  computed: {
    academicEntries() {
      const elemAca = this.applicationInformation.academicBG;
      if (elemAca) {
        elemAca.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
        return elemAca;
      }
      return [];
      // return this.applicationInformation.academicBG;
    },
    projectEntries() {
      const elemProject = this.applicationInformation.projects;
      if (elemProject) {
        elemProject.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
        return elemProject;
      }
      return [];
      // return this.applicationInformation.projects;
    },
    professionalEntries() {
      const elemPro = this.applicationInformation.professionalBG;
      if (elemPro) {
        elemPro.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
        return elemPro;
      }
      return [];
      // return this.applicationInformation.professionalBG;
    },
    posts() {
      if (this.$store.getters['feed/myPosts/loaded'] && this.applicationInformation.posts) {
        return this.applicationInformation.posts.map((post) => this.$store.getters['feed/myPosts/postFromId'](post));
      }
      return [];
    },
  },
  mounted() {
    if (this.offer.application.applicationType === 'Public') {
      this.applicationInformation = this.$store.getters['talent/talentResume'];
    } else {
      this.applicationInformation = {
        ...this.offer.application.customApplication,
        name: this.$store.state.talent.talentInfo.name,
        description: this.$store.state.talent.talentInfo.description,
      };
    }
    if (this.offer.application.motivationalText) {
      this.applicationInformation.motivationalText = this.offer.application.motivationalText;
    }
    if (!this.$store.getters['feed/myPosts/loaded']) {
      this.$store.dispatch('feed/myPosts/updateMyPosts');
    }

    setTimeout(() => {
      // eslint-disable-next-line no-unused-vars

      const ps = new PerfectScrollbar('#step-list', {
        wheelPropagation: false,
        wheelSpeed: 1,
      });
      ps.update();
      // psInfo.update();
    }, 1000);
  },

};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.close-button{
  position: absolute;
  top:0;
  right: 0;
  transform: translateY(-70%) translateX(50%);
}

.ap-entry{
  width: 98%;
}
// .inner-company-info{
//   padding-right: 0.5vh;
// }
.process-edit{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1vh;
  flex: 1;
}
.company-title{
  margin-bottom: 2vh;
  margin-top: 0.5vh;
  font-size: 2.2vh;
  color: #8c28e1;
}
.company-info{
  // width: calc(35% - 0.1vh);
  // border-right: 0.1vh black solid;
  // position: relative;
  // height: 68vh;
  padding: 2vh;
  flex: auto 0 1;
  width: calc(40% - 4vh);
  &.fullwidth{
    width: 100%;
  }
}
.steps-container{
  width: calc(59% - 4.1vh);
  margin-top: 6.5vh;
  flex: 1 1;
  height: 66vh;
  padding: 2vh;
  padding-top: 0;
  border-left: 0.1vh black solid;
  .status{
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-top: 4vh;
    margin-bottom: 1vh;
    margin-right: 1vh;
    width: 100%;
    min-height: 8vh;
    h4{
      &.Rejected{
        color: $logo_color_dark;
      }
      &.Approved{
        color: $green_color;
      }
      &.Current{
        color: $logo_color;
      }
    }
    .btn-lightpurple{
      margin-top: 1vh;
    }
  }
  .test-space{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1vh;
    .button-download{
      width: 30%;
      .btn-green{
        width: 6vw;
        margin-bottom: 1vh;
      }
    }
    .test-instructions{
      width: 60%;
      margin-left: 1vw;
      margin-bottom: 1vh;
    }
    .inst-title{
      font-size: 2vh;
      position: relative;
      top: -2vh;

    }
    .instructions{
      margin-top: 1vh;
      height: calc(100% - 2vh) ;
    }
  }
  .apointment{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1vh auto;
    width: 50%;
    .btn-choice{
      width: 20%;
      flex: 1 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .radius{
        width: 50%;
      }
    }
    .cont-multiple-date{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .inner-multiple-date{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .date-option{
          background-color: $logo_color;
          color: #ffffff;
          width: calc(50% - 2.5vh);
          margin-right: 1vh;
          margin-top: 1vh;
          padding: 1vh;
          cursor: pointer;
          transition: all .2s;
          &:nth-child(2n){
            margin-right: 0;
          }
          &:hover{
            background-color: $logo_color_dark;
          }
          .date{
            text-align: center;
            font-size: $classic_fs;
          }
          .hours{
            text-align: center;
            font-size: $classic_min_fs;
          }
        }
      }
    }
  }
}

.cont-phone{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2vh;
  margin-bottom: 2vh;
  .btn-choice{
    width: 50%;
  }
  .edit-phone{
    width: 50%;
  }
  .radius{
    margin: 0 auto;
    width: 50%;
  }
  .phone{
    margin-bottom: 1vh;
  }
  .v-select{
    margin-bottom: 1vh;
  }
}
</style>

<style lang="scss">
  .biographie{
    width: calc(100% - 1vh);
    .ResumeEntry{
      width: 100%;
    }
    .btn_open_other{
      display: none;
    }
  }
</style>
