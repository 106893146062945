import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import user from './modules/user';
import talent from './modules/talent';
import search from './modules/searchModules';
import company from './modules/company';
import offer from './modules/offer';
import feed from './modules/feed';
import calendar from './modules/calendar';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    initialized: false,
    homeNavIndex: 1,
    signupIndex: -1,
    lastModal: {},
  },
  actions: {
    navigateHomeTab({ commit }, index) {
      commit('navigateHomeTab', index);
    },
    signupIndex({ commit }, index) {
      commit('signupIndex', index);
    },
    // Store modal in state to re-open if needed
    storeModal({ commit }, modalData) {
      if (modalData.comp && modalData.props && modalData.options) {
        commit('storeModal', modalData);
      }
    },
    // open modal with previous values
    openLastModal({ state, commit }) {
      if (state.lastModal.comp) {
        Vue.prototype.$modal.showStatic(
          state.lastModal.comp,
          state.lastModal.props,
          state.lastModal.options,
        );
      }
      commit('storeModal', { comp: false });
    },
  },
  mutations: {
    initialize(state) {
      state.initialized = true;
    },
    navigateHomeTab(state, index) {
      state.homeNavIndex = index;
    },
    signupIndex(state, index) {
      state.signupIndex = index;
    },
    storeModal(state, modal) {
      state.lastModal = modal;
    },
  },
  modules: {
    auth,
    user,
    talent,
    search,
    offer,
    feed,
    company,
    calendar,
  },
});
