<template>
  <div class="ResumeEntry">
    <div class="infos">
      <professional-entry
        class="radius box-shadow"
        v-if="this.type === 'professionalBG'"
        :data="data"
        :editing="editing && this.editable"
        :display="this.editable"
      />
      <project-entry
        class="radius box-shadow"
        v-if="this.type === 'projects'"
        :data="data"
        :editing="editing && this.editable"
        :display="this.editable"
      />
      <academic-entry
        class="radius box-shadow"
        v-if="this.type === 'academicBG'"
        :data="data"
        :editing="editing && this.editable"
        :display="this.editable"
      />
      <div class="cont-btn" v-if="editing && this.editable">
        <div
          class="btn-green greener radius-small"
          @click="submitUpdate"
        >
          Submit
        </div>
      </div>
    </div>
    <div class="action" v-if="this.editable">
      <div class="private_field">
        <p><i>{{data.private ? 'Private': 'Public'}}</i></p>
      </div>
      <div class="separ"></div>
      <div class="global-param" v-if="this.editable">
        <div class="param ghost"></div>
        <div class="param" @click="handleOpenParam">
          <img src="@/assets/pictos/picto_param_purple.svg">
          <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg">
        </div>
        <div class="cont-param">
          <div class="elem-param" @click="toggleEdit">{{this.editing? 'Close':'Edit'}}</div>
          <div class="elem-param delete"
          v-if="this.editable && editing" @click="deleteThis">Remove</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AcademicEntry from './_academicEntry.vue';
import ProfessionalEntry from './_professionalEntry.vue';
import ProjectEntry from './_projectEntry.vue';

export default {
  name: 'ResumeEntry',
  props: ['type', 'objectData', 'editable', 'deleteEntry', 'index'],
  components: {
    AcademicEntry,
    ProfessionalEntry,
    ProjectEntry,
  },
  computed: {
    blank() {
      return this.objectData._id === undefined;
    },
    uploading() {
      return this.$store.state.talent.requestSending;
    },

  },
  data() {
    return {
      editing: true,
      data: { ...this.objectData },
      initialData: {},
    };
  },
  methods: {
    submitUpdate() {
      const updateBody = this.data;
      if (this.blank) {
        this.$store.dispatch('talent/postResumeEntry', { type: this.type, index: this.index, updateBody });
      } else {
        this.$store.dispatch('talent/updateResumeEntry', { type: this.type, id: this.objectData._id, updateBody });
      }
      this.editing = false;
      document.querySelectorAll('.open').forEach((elem) => {
        elem.classList.remove('open');
      });
    },
    toggleEdit() {
      if (this.editing) {
        if (this.blank) {
          this.$store.dispatch('talent/removeResumeEntry', { type: this.type, index: this.index });
        } else {
          this.editing = false;
          this.data = this.initialData;
        }
      } else {
        this.editing = true;
        this.initialData = { ...this.objectData };
      }
      document.querySelectorAll('.open').forEach((elem) => {
        elem.classList.remove('open');
      });
    },
    deleteThis() {
      this.$store.dispatch('talent/deleteResumeEntry', { type: this.type, id: this.objectData._id });
      document.querySelectorAll('.open').forEach((elem) => {
        elem.classList.remove('open');
      });
    },
    handleOpenParam(event) {
      if (event.target.parentNode.classList.contains('open')) {
        event.target.parentNode.classList.remove('open');
      } else {
        document.querySelectorAll('.open').forEach((elem) => {
          elem.classList.remove('open');
        });
        event.target.parentNode.classList.add('open');
      }
    },
  },
  mounted() {
    this.editing = this.blank;
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .ResumeEntry{
    position: relative;
  }
  .infos{
    position: relative;
  }
</style>
