export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    /**
     * @desc Display toast error
     * @param {string} errorMessage Message to display
     * @param {object|optional} toastOptions
     *  {position: position option string, duration: duration in ms}
     */
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$toastError = (errorMessage, toastOptions = { position: 'top-right', duration: 2000 }) => {
      Vue.toasted.show(errorMessage, {
        theme: 'toasted-primary',
        position: toastOptions.position,
        type: 'error',
        duration: toastOptions.duration,
        action: {
          text: 'Close',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    };
  },
};
