<template>
<div class="project-entry ap-entry">
  <div class="editing-project ap-editing" v-if="editing">
    <div class="cont-form">
      <div class="elem-form private">
        <!-- <label>Private:  </label> -->
        <!-- <true-false-switch v-model="data.private" /> -->
        <ToggleSwitch
          :labelOff="'Public'"
          :labelOn="'Private'"
          :func="updatePrivate"
        ></ToggleSwitch>
      </div>
      <div class="elem-form">
        <input class="v-select" v-model="data.title" placeholder="Title" />
      </div>
      <div class="elem-form">
        <!-- <span
          class="textarea"
          role="textbox"
          contenteditable
          @input="onInputDesc"
        >
        </span> -->
        <wysiwyg class="radius box-shadow" v-model="data.description" />
      </div>
      <div class="cont-half">
        <div class="elem-form">
          <div class="labeldate">Start date</div>
          <datepicker
            class="elem-date"
            :minimum-view="'month'"
            :format="'MMM yyyy'"
            v-model="data.dates.start"
          />
        </div>
        <div class="elem-form" :class="currentSelected ? 'disable' : ''">
          <div class="labeldate wother">
            End date
            <div class="other">
              <div class="select-current">
                <div>Current</div>
                <input type="checkbox" v-model="currentSelected" />
              </div>
            </div>
          </div>
          <datepicker
            class="elem-date candisable"
            :minimum-view="'month'"
            :format="'MMM yyyy'"
            v-model="data.dates.end"
          />
        </div>
        <!-- <div class="select-current">
          <label>Current</label>
          <input type="checkbox" v-model="currentSelected" />
        </div> -->
      </div>
      <div class="elem-form">
        <vue-tags-input
          v-model="skillTag"
          :tags="skillTags"
          :placeholder="'Add Skill'"
          class="radius"
          @tags-changed="newSkills => updateSkills(newSkills)"
        />
      </div>
      <!-- <div class="line">
        <label>Private:  </label>
        <true-false-switch v-model="data.private" />
      </div> -->

    </div>
  </div>
  <div class="view-project ap-view radius" v-else>
    <div class="visual_infos" v-if="isPicture">
    <!-- <div class="visual_infos"> -->
    </div>
    <div class="content_infos">
      <div class="content_date">
        {{data.dates.end? new Date(data.dates.end).getFullYear(): 'Today' }}
        <div class="separ"></div>
        {{data.dates.start? new Date(data.dates.start).getFullYear(): '2020' }}
      </div>
      <div class="inner_infos">
        <h4>{{data.title}}</h4>

        <div class="desc" v-if="data.description" v-html="data.description"></div>
        <div class="content_tags">
          <div
            class="elem_skill radius box-shadow"
            v-for="skill in data.skills"
            :key="skill"
          >
            {{skill}}
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

</template>

<script>
// import DateRange from '@/components/utilityPartials/dateRange.vue';
import VueTagsInput from '@johmun/vue-tags-input';
import Datepicker from 'vuejs-datepicker';
// import TrueFalseSwitch from '@/components/utilityPartials/trueFalseSwitch.vue';
import ToggleSwitch from '@/components/utilityPartials/ToggleSwitch.vue';
import currentSelected from '@/modules/mixins/currentSelect';

export default {
  name: 'ProjectEntry',
  props: ['data', 'editing', 'display'],
  mixins: [currentSelected],

  components: {
    // DateRange,
    VueTagsInput,
    Datepicker,
    // TrueFalseSwitch,
    ToggleSwitch,
  },
  data() {
    return {
      isPicture: false,
      skillTag: '',
      skillTags: this.data.skills ? this.data.skills.map((skill) => ({ text: skill })) : [],
    };
  },
  methods: {
    onInputDesc(e) {
      this.data.description = e.target.innerText;
    },
    updateSkills(newSkills) {
      this.data.skills = newSkills.map((skill) => skill.text);
      this.skillTags = newSkills;
    },
    updatePrivate(elem) {
      this.data.private = elem.target.checked;
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
</style>
