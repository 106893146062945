var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-social-picto"},[(_vm.site === 'Facebook')?_c('img',{attrs:{"src":require('@/assets/pictosocial/social-facebook'+
        "" + (this.purple ? '-violet.svg' : '.svg'))}}):(_vm.site === 'Instagram')?_c('img',{attrs:{"src":require('@/assets/pictosocial/social-instagram'+
        "" + (this.purple ? '-violet.svg' : '.svg'))}}):(_vm.site === 'Twitter')?_c('img',{attrs:{"src":require('@/assets/pictosocial/social-twitter'+
        "" + (this.purple ? '-violet.svg' : '.svg'))}}):(_vm.site === 'Linkedin')?_c('img',{attrs:{"src":require('@/assets/pictosocial/social-linkedin'+
        "" + (this.purple ? '-violet.svg' : '.svg'))}}):(_vm.site === 'Github')?_c('img',{attrs:{"src":require('@/assets/pictosocial/social-github'+
        "" + (this.purple ? '-violet.svg' : '.svg'))}}):(_vm.site === 'Gitlab')?_c('img',{attrs:{"src":require('@/assets/pictosocial/social-gitlab'+
        "" + (this.purple ? '-violet.svg' : '.svg'))}}):(_vm.site === 'Website')?_c('img',{attrs:{"src":require('@/assets/pictosocial/social-web'+
        "" + (this.purple ? '-violet.svg' : '.svg'))}}):_c('div',{},[_vm._v(" "+_vm._s(_vm.site)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }