<template>
  <label class="switch">
    <p v-html="this.labelOff"></p>
    <div class="inner_switch">
      <input type="checkbox" @input="func" v-model="val">
      <span class="slider round"></span>
    </div>
    <p v-html="this.labelOn"></p>
  </label>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    labelOff: String,
    labelOn: String,
    func: Function,
    val: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .switch {
    position:relative;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    p{
      margin: 0.3vh 0 0 0;
      padding: 0;
      display: block;
      line-height: 1;
      font-size: $fnt-14;
    }

    .inner_switch{
      position: relative;
      margin: 0 0.5vh;
      width: 3.6vh;
      height: 2vh;
      input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked + .slider {
          background-color: $logo_color;
        }
        &:focus + .slider {
          box-shadow: 0 0 0.1vh $logo_color;
        }
        &:checked + .slider:before {
          -webkit-transform: translateX(100%);
          -ms-transform: translateX(100%);
          transform: translateX(100%);
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background-color: #ccc;
        background-color: $logo_color;
        transition: .4s;
        &::before {
          position: absolute;
          content: "";
          height: 1.6vh;
          width: 1.6vh;
          left: 0.2vh;
          bottom: 0.2vh;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
        }
        &.round {
          border-radius: 3.4vh;
          &::before {
            border-radius: 50%;
          }
        }
      }
    }
  }
</style>
