var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lateral radius-large",class:(_vm.$router.currentRoute.path.indexOf('myTalent') === 1
    || _vm.$router.currentRoute.path.indexOf('myCompany') === 1) ? '' : 'collapse',attrs:{"id":"lateral-panel"}},[(this.$store.state.auth.loggedIn && !_vm.loading)?_c('div',{staticClass:"navigation"},_vm._l((_vm.tabs),function(nav){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
        content: _vm.$t(nav.trlabel),
        delay: {
          show: 0,
          hide: 0,
        }
      }),expression:"{\n        content: $t(nav.trlabel),\n        delay: {\n          show: 0,\n          hide: 0,\n        }\n      }",modifiers:{"top-center":true}}],key:nav.name,staticClass:"elem-nav radius-spe",class:[
        _vm.currentPage === nav.path ? 'current' : '',
        nav.name === 'Settings' ? 'lastone box-shadow-spe' : '' ],on:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return (function () { return _vm.switchTab(nav.path); }).apply(null, arguments)},function($event){if(!$event.ctrlKey){ return null; }return _vm.newTabBrowser(nav.path)}],"auxclick":function($event){return _vm.newTabBrowser(nav.path)}}},[_c('div',{staticClass:"picto-nav"},[_c('img',{staticClass:"picto-current",attrs:{"src":require(("@/assets/pictos/" + (nav.picto) + ".svg"))}}),_c('img',{staticClass:"picto-classic",attrs:{"src":require(("@/assets/pictos/" + (nav.pictoWhite) + ".svg"))}})]),_c('div',{staticClass:"label-nav"},[_vm._v(_vm._s(_vm.$t(nav.trlabel)))]),_c('div',{staticClass:"corner-top"}),_c('div',{staticClass:"corner-bot"})])}),0):_vm._e(),(!this.$store.state.auth.loggedIn && !_vm.loading)?_c('div',{staticClass:"navigation radius",class:!_vm.isDashboard ? 'collapse' : ''},[_c('router-link',{staticClass:"elem-nav radius",attrs:{"to":"/jobs"}},[_c('div',{staticClass:"picto-nav"}),_c('div',{staticClass:"label-nav"},[_vm._v("Jobs")])]),(!_vm.loading)?_c('div',{staticClass:"pre_head"},[_c('div',{staticClass:"btn-green radius",on:{"click":_vm.handleLogin}},[_vm._v(" "+_vm._s(this.$store.state.auth.loggedIn? 'Logout': 'Signin')+" ")]),(!this.$store.state.auth.loggedIn)?_c('div',{staticClass:"btn-green radius",on:{"click":_vm.handleSignup}},[_vm._v(" Signup ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"collapse-btn",attrs:{"id":"collapser"},on:{"click":function () { return _vm.handleCollapseNav(); }}},[_c('img',{staticClass:"picto-more",attrs:{"src":require('@/assets/img/arrow_black.png')}})])],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"navigation radius"},[_c('loading')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }