import MediaSelect from '@/components/UniversalComponents/Media/mediaSelect.vue';
import MediaUpload from '@/components/UniversalComponents/Media/mediaUpload.vue';

export default {
  methods: {
    // open media select modal and attach to callee (using assign function)
    // takes type (media type) and assign function (function to assign value)
    openAttach(type, assignFunction, locked = false) {
      const select = (media) => {
        if (locked && media.type !== type) {
          this.$modal.hide('media-select');
        } else {
          assignFunction(media);
          this.$modal.hide('media-select');
        }
      };
      this.$modal.show(
        MediaSelect,
        {
          select,
          type,
          lockFilter: locked,
        },
        {
          name: 'media-select',
          width: '60%',
          height: '40vh',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        },
      );
    },
    // short hand to just show images to attach
    attachImage(assignFunction) {
      this.openAttach('Image', assignFunction, true);
    },
    uploadMedia(type, locked = true) {
      this.$modal.show(
        MediaUpload,
        {
          callback: () => this.$modal.hide('MediaUpload'),
          lock: locked,
          initialType: type,
        },
        {
          name: 'MediaUpload',
          width: '60%',
          height: 'auto',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        },
      );
    },
  },
};
