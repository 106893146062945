<template>
  <div class="expandSection">
    <div class="block-settings contentbox box-shadow" :class="{open: show}" >
      <div class="head-settings" @click="toggleOpen">
        <div class="inner-head-settings">
          <div class="title-block">
            {{title}}
          </div>
          <div class="control-open">
            <img src="@/assets/img/arrow_black.png">
          </div>
        </div>
      </div>
      <div class="cont-settings">
        <slot>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExpandSection',
  props: {
    startExpanded: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Show',
    },
    titleClass: {
      type: String,
      default: 'defaultTitle',
    },
  },
  data() {
    return {
      show: this.startExpanded,
    };
  },
  methods: {
    toggleOpen() {
      this.show = !this.show;
    },
  },

};
</script>

<style lang="scss" scoped>
.head-settings{
  padding: 20px !important;
}
.default{
  font-size: 16px;
  align-content: center;
  border-bottom: 1px solid black;
}
.dropdown{
  float: right;
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.block-settings{
  width: calc(100% - 1vh) !important;
}
#applicant-info-offer{
  > .expandSection{
    width: 100%;
    &.limit-height{
      .block-settings{
        width: 100%;
        .cont-settings{
          padding: 0px 0.5vh;
          .biographie{
            padding: 0.5vh;
            margin-bottom: 0;
            .ap-view{
              .content_infos{
                h4{
                  font-size: $label_min_fs;
                }
                h5{
                  font-size: $classic_fs;
                }
              }
              .elem_tag{
                font-size: $micro_fs;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
</style>
