const TalentDashboard = () => import('@/views/Talent/TalentDashboard/TalentDashboard.vue');
const Resume = () => import('@/views/Talent/TalentDashboard/Resume.vue');
const OfferList = () => import('@/views/Talent/TalentDashboard/Offer/OfferList.vue');
const TalentHome = () => import('@/views/Talent/TalentDashboard/TalentHome.vue');
const TalentSettings = () => import('@/views/Talent/TalentDashboard/TalentSettings.vue');
const MediaLibrary = () => import('@/views/Universal/MediaLibrary.vue');

// import Test from '@/views/Talent/Test.vue';

const routes = [
  {
    path: '/myTalent',
    component: TalentDashboard,
    children: [
      {
        name: 'Home',
        path: '',
        component: TalentHome,
      },
      {
        path: 'resume',
        component: Resume,
      },
      {
        path: 'offers',
        component: OfferList,
      },
      {
        path: 'talent-settings',
        component: TalentSettings,
      },
      {
        name: 'MediaLibrary',
        path: 'media',
        component: MediaLibrary,
      },

    ],
  },

];

export default routes.map((route) => {
  const metaRoute = route;
  metaRoute.meta = { ...metaRoute.meta, type: 'Talent', loggedIn: true };
  return metaRoute;
});
