<template>
  <div class="process-edit">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>

    <div class="applicant-info" :class="stepHide?'fullwidth':''">
      <div class="applicant-title">
        Talent's Info
      </div>
      <applicant-process-info
        v-if="stepHide"
        :applicationId="this.applicant._id"
        :offerId="this.offerId"
        :name="nameString"
        :seeprofile="() => this.seeprofileCurrent()"
      />
      <applicant-process-info
        v-else
        :applicationId="this.applicant._id"
        :offerId="this.offerId"
        :name="nameString"
        :reject="() => this.rejectCurrent()"
        :seeprofile="() => this.seeprofileCurrent()"
      />
    </div>
    <div class="steps-container" id="step-list" v-if="!stepHide">
      <div v-for="(step, index) in steps" :key="step._id">
        <!-- {{step}} -->
        <step-display :step="step">

          <div :class="{'param-left': step.status === 'Current', 'global-param': true  }">
            <div class="param ghost"></div>
            <div class="param">
              <img src="@/assets/pictos/picto_param_purple.svg" />
              <img class="hover" src="@/assets/pictos/picto_param_purple_full.svg" />
            </div>
            <div class="cont-param">
              <div class="elem-param" @click="() => editStep(index)">Edit step</div>
              <div class="elem-param" @click="() => openFeedbackModal(index)">
                Edit/View feedback </div>
              <div class="elem-param"
              v-if="steps[index + 1] && steps[index+1].status !== 'Initial'"
              @click="() => resetStep(index)">Reset Step to Current</div>
            </div>
          </div>

          <div class="status" v-if="step.status === 'Current'">

            <div class="availabilityContainer"
              v-if="index === availabilityIndex && (step.action === 'Video Meeting' ||
                step.action === 'Live Code' ||
                step.action === 'Phone Call' ||
                step.action === 'Other')">
              <meeting-availability
                v-model="step.meeting.possibleTimes"
                :meetingTime="step.meeting.time || 1800000"
              />
              <div class="cont-btn-availability">
                <div
                  @click="() => submitAvailability(step)"
                  class="btn-green radius center"
                >
                  Send
                </div>
              </div>
            </div>

            <div class="btn-action">
              <div
                @click="() => toggleAvailabilty(index)"
                v-if="(step.action === 'Video Meeting' ||
                step.action === 'Live Code' ||
                step.action === 'Phone Call' ||
                step.action === 'Other')"
                class="btn-green radius center"
              >
                {{availabilityIndex  ===  -1 ?
                (step.meeting.possibleTimes.length? 'Edit Availability':'Set Availability')
                : 'Close'}}
              </div>
              <div
                @click="() => startVideoChat(step.meeting.event)"
                v-if="step.meeting && step.meeting.event && step.action === 'Video Meeting'"
                class="btn-green radius"
              >
                {{step.meeting.event.link? 'Join Video Call': 'Start Video Call' }}
              </div>

              <div
                v-if="(step.action === 'Coding Test')
                && step.technicalTest
                && step.technicalTest.completedTestFile"
                class="btn-green radius"
                @click="() => downloadMedia(step.technicalTest.completedTestFile)"
               >
                  Download Test
              </div>
            </div>
            <div class="bottom-line">
              <!-- {{step.meeting}} -->
              <div class="status-choice"
                v-if="step.meeting
                  && step.meeting.eventAccepted
                  && (step.action === 'Video Meeting' ||
                  step.action === 'Live Code' ||
                  step.action === 'Phone Call' ||
                  step.action === 'Other')" >
                Waiting applicant to select a date
              </div>

              <div class="status-choice"
                v-if="step.meeting
                  && step.meeting.eventAccepted
                  &&  step.action === 'Phone Call'" >
                {{step.meeting.phoneNumber ? `Phone Number: ${step.meeting.phoneNumber}` :
                  'Waiting for Applicant to submit Phone Number'}}
              </div>

              <div class="status-choice"
                v-if="(step.action === 'Coding Test')
                && step.technicalTest
                && !step.technicalTest.completedTestFile">
                {{
                  !step.technicalTest.testFile ? 'Waiting for Test Setup'
                  : 'Waiting for Applicant Submission'
                }}
              </div>

              <div class="status-choice"
                v-if="(step.action === 'Coding Test')
                && step.technicalTest
                && step.technicalTest.completedTestFile">
                Test Finished

              </div>

              <div class="btn-choice" v-show="buttonDisplay(step)">
                <div
                class="btn-lightpurple medium radius"
                  v-if="step.status !== 'Approved'"
                  @click="() => updateStatus(index, 'Approved')"
                >
                  Approve
                </div>
                <div
                class="btn-gray medium dark radius"
                  v-if="step.status !== 'Rejected'"
                  @click="() => updateStatus(index, 'Rejected')"
                >
                  Reject
                </div>
              </div>
            </div>

          </div>

        </step-display>
      </div>
      <div class="cont-btn">
        <div class="btn-green radius" @click="() => addStep">
          Add Step
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import PerfectScrollbar from 'perfect-scrollbar';
import StepDisplay from '@/components/UniversalComponents/Offer/Process/_stepDisplay.vue';
import MeetingAvailability from '@/components/EmployeeComponents/JobManagement/processPartials/_meetingAvailability.vue';
import FeedbackModal from '@/components/EmployeeComponents/JobManagement/processPartials/feedbackModal.vue';
import downloadMedia from '@/modules/mixins/downloadMedia';
import StepForm from './stepForm.vue';
import ApplicantProcessInfo from './applicantProcessInfo.vue';

export default {
  name: 'ApplicantProcess',
  props: ['applicant', 'processComp', 'offerIdProp', 'stepHide', 'modalName'],
  mixins: [downloadMedia],

  components: {
    ApplicantProcessInfo,
    StepDisplay,
    MeetingAvailability,
  },
  data() {
    return {
      steps: this.applicant.process,
      selectedStepIndex: this.applicant.process.findIndex((step) => step.status === 'Current'),
      availabilityIndex: -1,
    };
  },
  computed: {
    nameString() {
      return `${this.applicant.user.name.firstName} ${this.applicant.user.name.lastName}`;
    },
    offerId() {
      if (this.offerIdProp) {
        return this.offerIdProp;
      }
      return this.$store.state.company.currentOfferApplicationId;
    },

  },

  mounted() {
    const ps = new PerfectScrollbar('#step-list', {
      wheelPropagation: false,
      wheelSpeed: 1,
    });
    ps.update();
  },

  methods: {
    buttonDisplay(step) {
      if (step.action === 'Coding Test') {
        if (step.technicalTest && step.technicalTest.completedTestFile) {
          return true;
        }
      } else if (step.action === 'Video Meeting'
        || step.action === 'Live Code'
        || step.action === 'Phone Call') {
        if (step.meeting && step.meeting.eventAccepted) {
          return true;
        }
      } else if (step.action === 'Other') {
        return true;
      }
      return false;
    },
    toggleIndex(index) {
      if (this.selectedStepIndex === index) {
        this.selectedStepIndex = false;
      } else {
        this.selectedStepIndex = index;
      }
    },

    rejectCurrent() {
      for (let i = 0; i < this.steps.length; i += 1) {
        if (this.steps[i].status === 'Current') {
          return this.updateStatus(i, 'Rejected');
        }
      }
      return false;
    },

    seeprofileCurrent() {
      window.open(`${window.location.origin}/talent/${this.applicant.user._id}`, '_blank');
    },

    toggleAvailabilty(index) {
      if (this.availabilityIndex === -1) {
        this.availabilityIndex = index;
      } else {
        this.availabilityIndex = -1;
      }
    },
    resetStep(index) {
      const updateBody = {
        applicantId: this.applicant._id,
        offerId: this.$store.state.company.currentOfferApplicationId,
        stepId: this.steps[index]._id,
      };
      this.$store.dispatch('company/resetApplicationSteps', updateBody).then(() => {
        this.refreshModal();
      });
    },

    updateStatus(index, status) {
      this.steps[index].status = status;
      if (status === 'Approved' && this.steps.length > index + 1 && this.steps[index + 1].status === 'Initial') {
        this.steps[index + 1].status = 'Current';
      }
      this.updateSteps({ type: 'stepStatusUpdate', status, stepId: this.steps[index]._id }).then(() => {
        this.$modal.hide('applicant-process');
      });
    },
    refreshModal() {
      const newApplicant = this.$store.getters['company/applicant'](this.offerId, this.applicant._id);
      this.$modal.hide('applicant-process');
      this.showThisModal(newApplicant);
    },

    updateSingleStep(update, stepId, action = false) {
      const updateBody = {
        applicantId: this.applicant._id,
        offerId: this.offerId,
        applicationUpdate: update,
        stepId,
      };
      if (action) {
        updateBody.action = action;
        if (action.type === 'stepStatusUpdate' && action.status === 'Rejected') {
          updateBody.applicationUpdate.status = 'Rejected';
        }
      }
      return this.$store.dispatch('company/updateApplicationStep', updateBody);
    },

    updateSteps(action = false) {
      const updateBody = {
        applicantId: this.applicant._id,
        offerId: this.offerId,
        applicationUpdate: {
          process: this.steps,
        },
      };
      if (action) {
        updateBody.action = action;
        if (action.type === 'stepStatusUpdate' && action.status === 'Rejected') {
          updateBody.applicationUpdate.status = 'Rejected';
        }
      }
      return this.$store.dispatch('company/updateApplication', updateBody);
    },

    showThisModal(applicant = false) {
      this.$modal.show(
        this.processComp,
        {
          applicant: applicant || this.applicant,
          processComp: this.processComp,
        },
        {
          name: 'applicant-process',
          width: '80%',
          height: '70vh',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        },
      );
    },
    addStep() {
      this.$modal.hide('applicant-process');
      this.$modal.show(
        StepForm,
        {
          callbackSubmit: (update) => {
            if (this.steps[this.steps.length - 1].status === 'Approved') {
              // eslint-disable-next-line no-param-reassign
              update.status = 'Current';
            }
            this.steps.push(update);
            let updateAction = false;
            if (update.meeting && update.meeting.possibleTimes.length > 0) {
              updateAction = {
                type: 'calendarTimeSet',
                stepId: 'latest',
              };
            }
            this.updateSteps(updateAction).then(() => {
              this.$modal.hide('add-step-applicant');
              this.showThisModal();
            });
          },
        },
        {
          name: 'add-step-applicant',
          height: 'auto',
          width: '80%',
          scrollable: true,
          maxHeight: 500,
          minHeight: 100,
          classes: ['overflow'],
        },
      );
    },
    editStep(stepIndex) {
      this.$modal.hide('applicant-process');
      this.$modal.show(
        StepForm,
        {
          step: this.steps[stepIndex],
          callbackSubmit: (update) => {
            let updateAction = false;
            if (
              update.meeting
              && update.meeting.possibleTimes
              !== this.$store.getters['company/applicant'](this.offerId, this.applicant._id).process[stepIndex].meeting.possibleTimes
            ) {
              updateAction = {
                type: 'calendarTimeSet',
              };
            }
            this.updateSingleStep(update, update._id, updateAction).then((stepUpdate) => {
              Vue.set(this.steps, stepIndex, stepUpdate.process[stepIndex]);
              this.$modal.hide('edit-step-applicant');
              this.showThisModal();
            });
          },
          deleteStep: () => {
            Vue.delete(this.steps, stepIndex);
            this.updateSteps().then(() => {
              this.$modal.hide('edit-step-applicant');
              this.showThisModal();
            });
          },
        },
        {
          name: 'edit-step-applicant',
          height: '70vh',
          width: '50%',
          scrollable: true,
          maxHeight: 500,
          minHeight: 100,
          classes: ['overflow'],
        },
      );
    },

    openFeedbackModal(index) {
      this.$modal.show(FeedbackModal,
        {
          value: this.steps[index],
          updateStep: (update) => {
            const step = this.steps[index];
            if (update.publicFeedback || (!update.publicFeedback && step.publicFeedback)) {
              step.publicFeedback = update.publicFeedback;
            }
            if (update.privateFeedback || (!update.privateFeedback && step.privateFeedback)) {
              step.privateFeedback = update.privateFeedback;
            }
            this.updateSteps({ type: 'feedback', stepId: this.steps[index]._id }).then(() => {
              this.$modal.hide('feedback-company');
            });
          },
        }, {
          name: 'feedback-company',
          width: '80%',
          height: 'auto',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        });
    },

    startVideoChat(event) {
      const eventId = typeof event === 'object' ? event._id : event;
      this.$api.post('communications/startVideoCall', { eventId }).then(() => {
        window.open(`${window.location.origin}/videochat/${eventId}`);
        this.$modal.hide('applicant-process');
      });
    },

    submitAvailability(step) {
      const updateAction = {
        type: 'calendarTimeSet',
        stepId: step._id,
      };
      this.updateSteps(updateAction);
      this.availabilityIndex = -1;
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'applicant-process');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.current-step{
  border: 1px #8c28e1 solid
}
.close-button{
  position: absolute;
  top:0;
  right: 0;
  transform: translateY(-70%) translateX(50%);
}
.process-edit {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1vh;
  flex: 1;

  .applicant-title{
    margin-bottom: 2vh;
    margin-top: 0.5vh;
    font-size: 2.2vh;
    color: #8c28e1;
  }
  .applicant-info{
    flex: auto 0 1;
    width: calc(40% - 4vh);
    &.fullwidth{
      width: 100%;
    }
  }

  .steps-container {
    width: calc(59% - 4.1vh);
    margin-top: 6.5vh;
    flex: 1 1;
    height: 66vh;
    padding: 2vh;
    padding-top: 0;
    border-left: 0.1vh black solid;
    .status{
      display: flex;
      flex: 2;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin-top: 4vh;
      margin-bottom: 1vh;
      margin-right: 1vh;
      width: 100%;
      min-height: 8vh;
      .btn-action{
        width: 35%;
        flex: 1 1;
        margin-top:  1vh;
        .btn-green{
          margin: 0;
          margin-bottom: 1vh;
        }
      }
      .availabilityContainer{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 1vh;
        align-items: flex-end;
        .cont-btn-availability{
          width: 35%;
        }
      }
      .bottom-line{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: nowrap;
        .status-choice{
          // position: absolute;
          // right: 24%;
          // bottom: 1.5vh;
          width: 14vh;
          font-size: 12px;
          padding-right: 1vh;
          text-align: right;
          // line-height: 12px;
          // text-align: center;
          color: #BEB8C5;
        }
        .btn-choice{
          width: 45%;
          // flex: 1 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .radius{
            width: 50%;
            &:first-child{
              margin-left: 0;
              margin-right: 0.5vh;
            }
            &:last-child{
              margin-left: 0.5vh;
              margin-right: 0;
            }
          }
        }
      }
    }
    .cont-btn{
      justify-content: right;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.param-left{
  right: 14% !important;
}
  .applicant-info {
    padding: 2vh;
    .elem_tag{
      overflow: hidden;
      text-align: center;
      padding: 0.5vh 0;
      span{
        padding: 0 1vh 0 1vh;
        display: block;
        height: 1.4vh;
        line-height: 1.4vh;
        overflow: hidden;
      }
    }
  }
  .vdatetime-popup{
    border-radius: 1vh;
    overflow: hidden;
    .vdatetime-calendar{
      color: $logo_color_dark;
    }
    .vdatetime-popup__header{
      background-color: $logo_color;
    }
    .vdatetime-calendar__month__day{
      > span{
        > span{
          height:3.284vh;
        }
      }
    }
    .vdatetime-calendar__month__day--selected{
      > span{
        > span{
          background-color: $logo_color;
        }
      }
    }
    .vdatetime-time-picker__item{
      color: $logo_color_dark;
      &.vdatetime-time-picker__item--selected{
        color: $logo_color;
      }
    }
    .vdatetime-popup__actions__button{
      border-radius: 0.5vh;
      padding: 0.5vh 1.5vh;
      background-color: $green_color;
      color: $logo_color_dark;
      font-family: "MontserratRegular";
      cursor: pointer;
      transition: all 0.2s;
      &:hover{
        background-color: $logo_color_dark;
        color: $green_color;
      }
      &.vdatetime-popup__actions__button--cancel{
        background-color: $logo_color_dark;
        color: $green_color;
        &:hover {
          background-color: $green_color;
          color: $logo_color_dark;
        }
      }
    }
  }
</style>
