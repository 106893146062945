/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';

export default (typeString) => {
  const state = () => ({
    entries: [],
    searchQueryObject: {},
    limit: 25,
    retrieved: 0,
    initialSearchTime: false,
    endOfList: false,
  });

  const getters = {
    nextPageAvailable: (state) => state.initialSearchTime && !state.endOfList,
    params: (state) => state.searchQueryObject,
  };

  const actions = {
    setSearchString({ commit, state, rootState }) {
      commit('setSearchQuery', { ...state.searchQueryObject, text: rootState.search.searchText });
      const searchQuery = { ...state.searchQueryObject };
      searchQuery.limit = state.limit;
      searchQuery.skip = 0;
      Vue.prototype.$api.post(`search/${typeString}`, searchQuery).then((res) => {
        commit('updateEntriesList', res.data);
        if (res.data.length < state.limit) {
          commit('endReached');
        }
      });
    },
    clearFilters({ dispatch }) {
      dispatch(`search/${typeString}/getEntriesInitial`, {});
    },
    getEntriesInitial({ commit, state, rootState }, query) {
      commit('setSearchQuery', { text: rootState.search.searchText, ...query });
      const searchQuery = { ...state.searchQueryObject };
      searchQuery.limit = state.limit;
      searchQuery.skip = 0;
      Vue.prototype.$api.post(`search/${typeString}`, searchQuery).then((res) => {
        commit('updateEntriesList', res.data);
        if (res.data.length < state.limit) {
          commit('endReached');
        }
      });
    },
    getNextEntries({ commit, state }) {
      return new Promise((resolve) => {
        if (!state.endOfList) {
          const searchQuery = { ...state.searchQueryObject };
          searchQuery.limit = state.limit;
          searchQuery.skip = state.retrieved;
          Vue.prototype.$api.post(`search/${typeString}`, searchQuery).then((res) => {
            commit('addEntries', res.data);
            if (res.data.length < state.limit) {
              commit('endReached');
            }
            resolve(true);
          });
        } else {
          resolve(false);
        }
      });
    },

  };
  const mutations = {
    setSearchQuery(state, query) {
      state.searchQueryObject = query;
      state.initialSearchTime = Date.now();
      state.endOfList = false;
      state.retrieved = 0;
      state.entries = [];
    },
    updateEntriesList(state, newEntries) {
      state.entries = [...newEntries];
      state.retrieved = newEntries.length;
    },
    addEntries(state, newEntries) {
      state.entries.push(...newEntries);
      state.retrieved += newEntries.length;
    },
    endReached(state) {
      state.endOfList = true;
    },
    clearFilters(state) {
      state.searchQueryObject = {};
      state.initialSearchTime = false;
      state.endOfList = false;
      state.retrieved = 0;
      state.entries = [];
    },
  };
  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
};
