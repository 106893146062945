/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';

const state = () => ({
  following: [],
  followers: [],
  loaded: false,
});

const getters = {
  isFollowing: (state) => (id, type) => {
    const index = state
      .following.findIndex((acct) => acct.account._id === id && acct.accountType === type);
    if (index > -1) {
      return state.following[index];
    }
    return false;
  },
  isFollower: (state) => (id, type) => {
    const index = state
      .followers.findIndex((acct) => acct.account._id === id && acct.accountType === type);
    if (index > -1) {
      return state.followers[index];
    }
    return false;
  },
};

const actions = {
  getFollowing({ commit }) {
    return new Promise((resolve) => Vue.prototype.$api.get('feed/follow').then((res) => {
      commit('updateFollowing', res.data);
      resolve();
    }));
  },
  follow({ commit }, { accountId, accountType }) {
    return new Promise((resolve) => Vue.prototype.$api.post('feed/follow', { followId: accountId, followType: accountType }).then((res) => {
      commit('addFollowing', res.data);
      resolve();
    }));
  },
  unfollow({ commit }, followingId) {
    return new Promise((resolve) => Vue.prototype.$api.delete('feed/follow', { data: { followingId } }).then((res) => {
      commit('removeFollowing', followingId);
      resolve();
    }));
  },
  clearFollow({ commit }) {
    commit('clearFollow');
  },

};
const mutations = {
  updateFollowing(state, { following, followers }) {
    state.followers = followers;
    state.following = following;
    state.loaded = true;
  },
  addFollowing(state, follow) {
    state.following.push(follow);
  },
  removeFollowing(state, followId) {
    const followIndex = state.following.findIndex((follow) => follow._id === followId);
    Vue.delete(state.following, followIndex);
  },
  clearFollow(state) {
    state.following = [];
    state.followers = [];
    state.loaded = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
