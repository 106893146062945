<template>
  <div class="availability">
    <div class="title">
      Set Availability <span>(meeting length is set in step parameters)</span>
    </div>
    <div class="lineAvailable" v-for="(event, index) in events" :key="index">
      <entry :event="event" :meetingTime="meetingTime" />
    </div>
    <div class="cont-btn" v-if="countEvent < 5">
      <div class="btn-green radius small" @click="addEvent">Add Time</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import entry from './_availabilityEntry.vue';

export default {
  name: 'MeetingAvailability',
  props: [
    'value',
    'meetingTime',
  ],
  components: { entry },
  data() {
    return {
      countEvent: 0,
      events: [...this.value || []],
    };
  },
  methods: {
    addEvent() {
      if (this.countEvent <= 5) {
        this.events.push({});
        this.countEvent += 1;
      }
    },

  },

  watch: {
    events(newE) {
      this.$emit('input', newE);
    },
  },

};
</script>

<style scoped lang="scss">
  @import "@/assets/scss/_variables.scss";

.availability{
  position: relative;
  padding: 0 1vh 1vh 0;
  // height: 400px;
  .title{
    font-size: $label_fs;
    margin-bottom: 10px;
    span{
      font-size: $classic_fs;
      color: $font_gray_color;
      font-style: italic;
    }
  }
  .btn-green{
    &.mini{
      margin: 10px 0;
      padding: 5px 10px;
      font-size: $classic_min_fs;
      font-family: 'MontserratBold';
    }
  }
}

  .vdatetime-input{
      font-size: 1.2vh !important;
    }
.lineAvailable{
  font-size: 1.2vh;

}

</style>
