<template>
  <div class="feedback-modal">
    <div class="contentbox box-shadow radius marginB">
      <div class="title-page wother no-margin">
        Feedback
        <div class="other">
          <div class="btn-green radius small reverse" @click="closeThis"> Cancel</div>
          <div class="btn-green radius small" @click="submit"> Submit </div>
        </div>
      </div>
    </div>
    <div class="contentbox box-shadow radius marginB">
      <div class="subtitle-page">
        Public Feedback <i> This is visible to the applicant</i>
      </div>
      <wysiwyg class="radius" v-model="publicFeedback" />
    </div>
    <div class="contentbox box-shadow radius">
      <div class="subtitle-page">
        Private Feedback <i> This is only visible to other employees</i>
      </div>
      <wysiwyg class="radius" v-model="privateFeedback" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'FeedbackEmployee',
  props: ['value', 'updateStep'],

  data() {
    return {
      publicFeedback: undefined,
      privateFeedback: undefined,
    };
  },
  mounted() {
    if (this.value.publicFeedback) {
      this.publicFeedback = this.value.publicFeedback;
    }
    if (this.value.privateFeedback) {
      this.privateFeedback = this.value.privateFeedback;
    }
  },
  methods: {
    closeThis() {
      this.$modal.hide('feedback-company');
    },
    submit() {
      this.updateStep(
        { publicFeedback: this.publicFeedback, privateFeedback: this.privateFeedback },
      );
    },

  },

};
</script>

<style lang="scss">
  .feedback-modal{
    padding: 1vh;
  }
</style>
