/* eslint-disable global-require */
export default [
  {
    name: 'Video Meeting',
    iconRoute: require('@/assets/pictos/picto_videomeeting_gray.svg'),
    iconRouteGray: require('@/assets/pictos/picto_videomeeting_gray.svg'),
    iconRouteDarkPurple: require('@/assets/pictos/picto_videomeeting_darkpurple.svg'),
  },
  {
    name: 'Live Code',
    iconRoute: require('@/assets/pictos/picto_videocoding_gray.svg'),
    iconRouteGray: require('@/assets/pictos/picto_videocoding_gray.svg'),
    iconRouteDarkPurple: require('@/assets/pictos/picto_videocoding_darkpurple.svg'),
  },
  {
    name: 'Coding Test',
    iconRoute: require('@/assets/pictos/picto_coding_gray.svg'),
    iconRouteGray: require('@/assets/pictos/picto_coding_gray.svg'),
    iconRouteDarkPurple: require('@/assets/pictos/picto_coding_darkpurple.svg'),
  },
  {
    name: 'Phone Call',
    iconRoute: require('@/assets/pictos/picto_phone_gray.svg'),
    iconRouteGray: require('@/assets/pictos/picto_phone_gray.svg'),
    iconRouteDarkPurple: require('@/assets/pictos/picto_phone_darkpurple.svg'),
  },
  {
    name: 'Other',
    iconRoute: require('@/assets/pictos/picto_allmedia_gray.svg'),
    iconRouteGray: require('@/assets/pictos/picto_allmedia_gray.svg'),
    iconRouteDarkPurple: require('@/assets/pictos/picto_allmedia_darkpurple.svg'),
  },
];
