<template>
  <div class="mediaSelect">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
      <div class="sort box-shadow contentbox radius marginB" v-if="!lockFilter">
        <div class="subtitle-page">Filter</div>
        <v-select
          :clearable="false"
          :searchable="false"
          :options="types"
          placeholder="Filter type"
          v-model="filterType"
          label="name"
        />
      </div>
      <div
        class="cont-media box-shadow contentbox radius"
        v-if="mediaLibrary[0].media.length > 0"
      >
        <div
          v-for="media in mediaLibrary[0].media"
          :key="media._id"
          :class="{media: true, selected: mediaSelected && mediaSelected.id === media._id}"
          v-tooltip.top-center="{
            content: media.name,
            offset: -100,
            delay: {
              show: 1000,
              hide: 0,
            }
          }"
          class="radius"
          @click="() => selectMedia(media)"
        >
          <file-preview
            class="elem-media boxshadow-middle"
            :filePreview="media.data"
            :fileType="media.type"
          />
          <label class="label-media">{{media.name}}</label>
        </div>
      </div>
      <div
        class="cont-media box-shadow contentbox radius"
        v-else
      >
        No media
      </div>
      <div class="cont-btn" v-if="mediaSelected">
        <div class="radius btn-green" @click="confirm" > Attach </div>
      </div>

      <div class="cont-btn"  v-if="!mediaSelected">
        <div class="radius btn-green" @click="uploadNew"> Upload New </div>
      </div>

  </div>
</template>

<script>
import FilePreview from '@/components/utilityPartials/_filePreview.vue';
import MediaUpload from '@/components/UniversalComponents/Media/mediaUpload.vue';

export default {
  name: 'MediaSelect',
  props: ['select', 'type', 'lockFilter'],
  components: {
    FilePreview,
  },
  data() {
    return {
      searchTerm: '',
      filterType: this.type || 'All',
      types: ['All', 'Image', 'Video', 'Pdf', 'Audio', 'Other'],
      mediaSelected: false,
    };
  },
  computed: {
    mediaLibrary() {
      const params = this.filterType === 'All' || !this.filterType ? [] : [this.filterType];
      return this.$store.getters['user/media'](params, 'date');
    },

  },
  methods: {
    selectMedia(media) {
      this.mediaSelected = {
        type: media.type, data: media.data, id: media._id, name: media.name,
      };
    },
    confirm() {
      this.select(this.mediaSelected);
    },
    uploadNew() {
      this.$modal.hide('media-select');
      this.$modal.show(
        MediaUpload,
        {
          callback: this.select,
          lock: this.lockFilter,
        },
        {
          name: 'MediaUpload',
          width: '60%',
          height: 'auto',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        },
      );
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'media-select');
    },
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .cont-media{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    .media{
      width: calc(calc(100% - 10vh) / 6);
      margin-right: 2vh;
      cursor: pointer;
      // height: 20vh;
      &:nth-child(6n){
        margin-right: 0;
      }
      margin-bottom: 3vh;
      .elem-media{
        width: 100%;
        transition: all 0.2s;
        div{
          font-size: 0;
          // background-color: $logo_color_dark;
          // height: 15vh;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-radius: $radius;
          overflow: hidden;
          img{
            font-size: 0;
            width: 100%;
          }
          video{
            width: 100%;
          }
          .preview{
            width: 100%;
            height: auto;
            pointer-events: none;
          }
        }
      }
      .label-media{
        display: block;
        font-size: $micro_fs;
        margin: 0 auto;
        margin-top: 0.5vh;
        width: 90%;
        text-align: center;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &:hover{
        // .elem-media{
        //   transform: scale(1.02);
        // }
      }
    }
  }

  .selected{
    background-color: $logo_color;
    padding: 0.5vh;
  }
  .mediaSelect{
    padding: 1vh;
  }

  .cont-btn{
    width: 100%;
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

</style>
