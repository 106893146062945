import createAuth0Client from '@auth0/auth0-spa-js';
import axios from 'axios';
import { domain, clientId } from './auth_config.json';

const DEFAULT_REDIRECT_CALLBACK = () => window
  .history.replaceState({}, document.title, window.location.pathname);
export default async () => {
  const auth0 = (
    redirectUri = window.location.origin,

  ) => createAuth0Client({
    domain,
    client_id: clientId,
    redirect_uri: redirectUri,
    responseType: 'token id_token',
    scope: 'openid email',
  });

  const vm = {};
  vm.auth0Client = await auth0();

  vm.checkLogin = async () => {
    try {
      // If the user is returning to the app after authentication..
      if (
        window.location.search.includes('code=')
        && window.location.search.includes('state=')
      ) {
        // handle the redirect and retrieve tokens
        const { appState } = await vm.auth0Client.handleRedirectCallback();

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        DEFAULT_REDIRECT_CALLBACK(appState);
      }
    } catch (e) {
      this.error = e;
    }
    // Initialize our internal authentication state
    if (await vm.auth0Client.isAuthenticated()) {
      const { __raw } = await vm.auth0Client.getIdTokenClaims();
      return new Promise((resolve, reject) => axios.get(`${process.env.VUE_APP_API_URL}/auth/login`, { headers: { Authorization: `Bearer ${__raw}`, 'Access-Control-Allow-Origin': '*' } }).then((res) => {
        const user = { ...res.data, token: __raw, loggedIn: true };
        resolve({ new: false, ...user });
      }).catch((e) => {
        if (e.response.status === 404) {
          axios.post(`${process.env.VUE_APP_API_URL}/auth/signup`, {}, { headers: { Authorization: `Bearer ${__raw}`, 'Access-Control-Allow-Origin': '*' } }).then((res) => {
            const user = { ...res.data, token: __raw, loggedIn: true };
            resolve({ new: true, ...user });
          }).catch((err) => {
            vm.auth0Client.logout();
            if (err.response.status === 401) {
              reject(new Error('Already signed in with different login'));
            }
            throw err;
          });
        } else {
          throw e;
        }
      }));
    }
    return false;
  };

  return vm;
};
