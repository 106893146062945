export const needsToSignup = (user) => {
  if (user.loggedIn) {
    if (!user.type || !user.verified || !user.profileCreated) {
      return true;
    } if (user.type === 'Employee' && !user.company) {
      return true;
    }
  }
  return false;
};
const checkType = (user, type) => {
  if (user.loggedIn && user.type === type) {
    return true;
  }
  return false;
};
const checkRolePermissions = (user, permissions) => {
  for (let i = 0; i < permissions.length; i += 1) {
    if (!user.permissions.includes(permissions[i])) {
      return false;
    }
  }
  return true;
};

export default (store, initialized) => async (to, from, next) => {
  await initialized;
  const user = store.state.auth;
  if (to.meta.loggedIn && !user.loggedIn) {
    store.dispatch('auth/login');
  }
  if (needsToSignup(user) && to.path !== '/signup' && to.path !== '/auth') {
    return next('/signup');
  }
  if (!needsToSignup(user) && to.path === '/signup') {
    return next('/');
  }
  if (to.path === '/' && user.loggedIn) {
    if (user.type === 'Employee') {
      return next('/myCompany');
    }
    return next('/myTalent');
  }
  if (to.meta.type && !checkType(user, to.meta.type)) {
    return next('/');
  }
  if (to.meta.authorize && !checkRolePermissions(user, to.meta.authorize)) {
    return next('/');
  }

  return next();
};
