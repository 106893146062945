/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';

const state = () => ({
  companyInfo: {},
  companyEmployees: false,
  offers: [],
  loadingInfo: true,
  loadingOffers: true,
  currentOfferApplicationId: false,
  currentProcessId: false,
  managementPageIndex: 1,
  projects: [],
  roles: [],
  columns: [],

});
const getters = {
  offers: (state) => state.offers,
  currentOfferApplication: (state) => state.offers.find(
    (offer) => offer._id === state.currentOfferApplicationId,
  ) || false,
  selectedOffer: (state) => {
    if (!state.currentOfferApplicationId || state.currentOfferApplicationId === 'new') {
      return false;
    }
    return true;
  },
  getOfferById: (state) => (id) => state.offers.find((offer) => offer._id === id),
  info: (state) => state.companyInfo,
  currentProcess: (state) => (state.companyInfo.processes
    && state.currentProcessId
    ? state.companyInfo.processes.find(
      (process) => process._id === state.currentProcessId,
    ) : false),
  stepById: (getters) => (id) => {
    if (getters.currentProcess) {
      return getters.currentProcess.steps.find((step) => step._id === id) || false;
    }
    return false;
  },
  processes: (state) => (state.companyInfo.processes ? state.companyInfo.processes : []),
  processName: (state) => (id) => state.companyInfo.processes.find((proc) => proc._id === id).name,
  applicant: (state) => (offerId, applicantId) => state.offers
    .find((offer) => offer._id === offerId)
    .applicants
    .find((applicant) => applicant._id === applicantId),
  projects: (state) => state.projects,
  listRoles: (state) => state.roles.map((r) => ({ name: r.name, _id: r._id })),
  hackerRankToken: (state) => state.companyInfo.hackerRankToken || '',
  employees: (state) => {
    if (!state.company.companyEmployees) {
      return [];
    }
    return state.company.companyEmployees.map((employee) => {
      const updatedEmployee = { ...employee.user };
      updatedEmployee.nameString = `${employee.user.name.firstName || ''} ${employee.user.name.lastName || ''}`;
      return updatedEmployee;
    });
  },
  columns: (state) => state.columns,
  column: (state) => (id) => state.columns.find((col) => col._id === id),
};

const actions = {
  // INFO MGMT
  getInfo({ commit, state }) {
    return new Promise((resolve) => Vue.prototype.$api.get('company').then((res) => {
      commit('setCompanyInfo', res.data);
      if (res.data.processes && res.data.processes.length && !state.currentProcessId) {
        commit('changeProcessId', res.data.processes[0]._id);
      }
      resolve();
    }));
  },
  updateInfo({ commit }, companyUpdate) {
    return new Promise((resolve) => Vue.prototype.$api.post('company/edit', companyUpdate).then((res) => {
      commit('setCompanyInfo', res.data);
      resolve(res.data);
    }));
  },

  // Employees

  getEmployees({ commit }) {
    Vue.prototype.$api.get('company/employees').then((res) => {
      commit('setEmployees', res.data);
    });
  },

  assignEmployee({ commit, state }, { offerId, employeeId, role }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('company/assign', { offerId, employeeId, role }).then((res) => {
        const offerIndex = state.offers.findIndex((offer) => offer._id === offerId);
        if (offerIndex > -1) {
          commit('editOffer', { offerIndex, offerData: res.data });
        }
        resolve(offerIndex);
      });
    });
  },
  unAssignEmployee({ commit, state }, { offerId, employeeId }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('company/unassign', { offerId, employeeId }).then((res) => {
        const offerIndex = state.offers.findIndex((offer) => offer._id === offerId);
        if (offerIndex > -1) {
          commit('editOffer', { offerIndex, offerData: res.data });
        }
        resolve(offerIndex);
      });
    });
  },

  // OFFERS MANGAGEMENT
  getOffers({ commit, state }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.get('company/offers').then((res) => {
        commit('setCompanyOffers', res.data);
        if (!state.currentOfferApplicationId && res.data.length) {
          commit('setCurrentOfferApplicationId', res.data[0]._id);
        }
        resolve(res.data);
      });
    });
  },
  setCurrentOfferApplicationId({ commit, state }, id) {
    commit('setCurrentOfferApplicationId', id);
  },
  setNewOfferApplication({ commit }) {
    commit('setCurrentOfferApplicationId', 'new');
  },
  clearCurrentOfferNew({ commit, state }) {
    if (state.currentOfferApplicationId === 'new') {
      commit('setCurrentOfferApplicationId', false);
    }
  },
  addOffer({ commit }, offerData) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('company/offers', offerData).then((res) => {
        commit('addOffer', res.data);
        resolve(res.data._id);
      });
    });
  },
  deleteOffer({ commit, state }, id) {
    return new Promise((resolve) => Vue.prototype.$api.delete(`company/offers/${id}`).then(() => {
      const offerIndex = state.offers.findIndex((offer) => offer._id === id);
      if (offerIndex > -1) {
        commit('deleteOffer', offerIndex);
      }
      resolve();
    }));
  },
  editOffer({ commit, state }, { id, offerData }) {
    return new Promise((resolve) => Vue.prototype.$api.put(`company/offers/${id}`, offerData).then((res) => {
      const offerIndex = state.offers.findIndex((offer) => offer._id === id);
      if (offerIndex > -1) {
        commit('editOffer', { offerIndex, offerData });
      }
      resolve(id);
    }));
  },
  updateOffer({ commit, state }, id) {
    return new Promise((resolve) => Vue.prototype.$api.get(`company/offers/${id}`).then((res) => {
      const offerIndex = state.offers.findIndex((offer) => offer._id === id);
      if (offerIndex > -1) {
        commit('editOffer', { offerIndex, offerData: res.data });
      }
      resolve(offerIndex);
    }));
  },

  duplicateOffer({ dispatch, state }, offerId) {
    const offerData = { ...state.offers.find((offer) => offer._id === offerId) };
    delete offerData._id;
    offerData.name += '(copy)';
    dispatch('addOffer', offerData).then((newId) => {
      dispatch('setCurrentOfferApplicationId', newId);
    });
  },

  getUpdatedApplicants({ commit, state }, id) {
    return new Promise((resolve) => Vue.prototype.$api.get(`company/allApplicantInfo/${id}`).then((res) => {
      const offerIndex = state.offers.findIndex((offer) => offer._id === id);
      if (offerIndex > -1) {
        commit('editOfferApplicants', { offerIndex, applicantData: res.data });
      }
      resolve(offerIndex);
    }));
  },

  getApplication({ commit, state }, { offerId, applicationId }) {
    return new Promise((resolve) => Vue.prototype.$api.get(`company/application/${offerId}/${applicationId}`).then((res) => {
      const offerIndex = state.offers.findIndex((offer) => offer._id === offerId);
      const applicantIndex = state.offers[offerIndex]
        .applicants.findIndex((applicant) => applicant._id === applicationId);
      commit('updateApplicant', { offerIndex, applicantIndex, update: res.data });
      resolve(res.data);
    }));
  },

  getUpdatedApplicationTimes({ commit, state }, { offerId, applicationId }) {
    return new Promise((resolve) => Vue.prototype.$api.get(`company/stepTimes/${offerId}/${applicationId}`).then((res) => {
      const offerIndex = state.offers.findIndex((offer) => offer._id === offerId);
      const applicantIndex = state.offers[offerIndex]
        .applicants.findIndex((applicant) => applicant._id === applicationId);
      commit('updateApplicantTimes', { offerIndex, applicantIndex, update: res.data });
      resolve(res.data);
    }));
  },
  // Require applicantId and offerId and applicationUpdate in updateBody
  updateApplication({ commit, state }, updateBody) {
    return new Promise((resolve) => Vue.prototype.$api.post('company/updateApplication', updateBody).then((res) => {
      const offerIndex = state.offers.findIndex((offer) => offer._id === updateBody.offerId);
      const applicantIndex = state.offers[offerIndex]
        .applicants.findIndex((applicant) => applicant._id === updateBody.applicantId);
      commit('updateApplicant', { offerIndex, applicantIndex, update: res.data });
      resolve();
    }));
  },

  updateApplicationStep({ commit, state }, updateBody) {
    return new Promise((resolve) => Vue.prototype.$api.post('company/updateApplicationStep', updateBody).then((res) => {
      const offerIndex = state.offers.findIndex((offer) => offer._id === updateBody.offerId);
      const applicantIndex = state.offers[offerIndex]
        .applicants.findIndex((applicant) => applicant._id === updateBody.applicantId);
      commit('updateApplicant', { offerIndex, applicantIndex, update: res.data });
      resolve(res.data);
    }));
  },

  resetApplicationSteps({ commit, state }, updateBody) {
    return new Promise((resolve) => Vue.prototype.$api.post('company/resetSteps', updateBody).then((res) => {
      const offerIndex = state.offers.findIndex((offer) => offer._id === updateBody.offerId);
      const applicantIndex = state.offers[offerIndex]
        .applicants.findIndex((applicant) => applicant._id === updateBody.applicantId);
      commit('updateApplicant', { offerIndex, applicantIndex, update: res.data });
      resolve();
    }));
  },
  changeManagementTab({ commit }, index) {
    commit('changeManagementTab', index);
  },
  addProcess({ commit }, processBody) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('company/process', processBody).then((res) => {
        commit('addProcess', res.data);
        resolve(res.data._id);
      });
    });
  },
  editProcess({ commit, state }, processBody) {
    return new Promise((resolve) => {
      Vue.prototype.$api.put(`company/process/${processBody._id}`, processBody).then((res) => {
        const processIndex = state.companyInfo.processes.findIndex(
          (process) => process._id === res.data._id,
        );
        commit('editProcess', { process: res.data, processIndex });
        resolve(res.data);
      });
    });
  },
  deleteProcess({ commit, state }, processId) {
    return new Promise((resolve) => {
      Vue.prototype.$api.delete(`company/process/${processId}`).then((res) => {
        const processIndex = state.companyInfo.processes.findIndex(
          (process) => processId === process._id,
        );
        commit('deleteProcess', processIndex);

        resolve();
      }).catch((e) => {
        if (e.response && e.response.status === 304) {
          Vue.prototype.$toastError('Process is in use and cannot be deleted');
        }
        resolve();
      });
    });
  },
  duplicateProcess({ dispatch, state }, processId) {
    const processData = {
      ...state.companyInfo.processes.find(
        (process) => processId === process._id,
      ),
    };
    delete processData._id;
    processData.name += '(copy)';
    dispatch('addProcess', processData).then((newId) => {
      dispatch('setCurrentProcessId', newId);
    });
  },
  addStep({ commit, state, getters }, step) {
    const steps = [...getters.currentProcess.steps];
    steps.push(step);
    return new Promise((resolve) => {
      Vue.prototype.$api.put(`company/process/${getters.currentProcess._id}`, { steps }).then((res) => {
        const processIndex = state.companyInfo.processes.findIndex(
          (process) => process._id === res.data._id,
        );
        commit('editProcess', { process: res.data, processIndex });
        resolve(res.data);
      });
    });
  },
  editStep({ commit, state, getters }, stepUpdate) {
    const { steps } = getters.currentProcess;
    steps[steps.findIndex((step) => step._id === stepUpdate._id)] = stepUpdate;

    return new Promise((resolve) => {
      Vue.prototype.$api.put(`company/process/${getters.currentProcess._id}`, { steps }).then((res) => {
        const processIndex = state.companyInfo.processes.findIndex(
          (process) => process._id === res.data._id,
        );
        commit('editProcess', { process: res.data, processIndex });
        resolve(res.data);
      });
    });
  },
  deleteStep({ commit, state, getters }, id) {
    const { currentProcess } = getters;
    const steps = currentProcess.steps.filter((step) => step._id !== id);
    return new Promise((resolve) => {
      Vue.prototype.$api.put(`company/process/${currentProcess._id}`, { steps }).then((res) => {
        const processIndex = state.companyInfo.processes.findIndex(
          (process) => process._id === res.data._id,
        );
        commit('editProcess', { process: res.data, processIndex });
        resolve(res.data);
      });
    });
  },
  setCurrentProcessId({ commit }, id) {
    commit('changeProcessId', id);
  },

  // projects

  addProject({ commit }, project) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('company/project', { project }).then((res) => {
        commit('addProject', res.data);
        resolve(res.data);
      });
    });
  },

  editProject({ commit }, project) {
    return new Promise((resolve) => {
      Vue.prototype.$api.put('company/project', { project }).then((res) => {
        commit('updateProject', res.data);
        resolve(res.data);
      });
    });
  },

  deleteProject({ commit }, projectId) {
    return new Promise((resolve) => {
      Vue.prototype.$api.delete(`company/project/${projectId}`).then((res) => {
        if (res.status === 202) {
          commit('deleteProject', projectId);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  },

  // roles

  getRoles({ commit }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.get('company/roles').then((res) => {
        commit('setRoles', res.data);
        resolve(res.data);
      });
    });
  },
  addRole({ commit }, role) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('company/role', { role }).then((res) => {
        commit('addRole', res.data);
        resolve(res.data);
      });
    });
  },

  editRole({ commit }, role) {
    return new Promise((resolve) => {
      Vue.prototype.$api.put('company/role', { role }).then((res) => {
        commit('updateRole', res.data);
        resolve(res.data);
      });
    });
  },

  deleteRole({ commit }, roleId) {
    return new Promise((resolve) => {
      Vue.prototype.$api.delete(`company/role/${roleId}`).then((res) => {
        if (res.status === 202) {
          commit('deleteRole', roleId);
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch(() => {
        resolve(false);
      });
    });
  },

  assignRole({ commit, state }, { roleId, accountId }) {
    return new Promise((resolve) => {
      Vue.prototype.$api.post('company/assignRole', { roleId, accountId }).then((res) => {
        const employee = res.data;
        const employeeIndex = state.companyEmployees.findIndex(((emp) => emp._id === accountId));
        commit('editEmployee', { employee, employeeIndex });
      });
    });
  },

  // Columns

  getColumns({ commit }) {
    return new Promise((resolve) => Vue.prototype.$api.get('company/columns').then((res) => {
      commit('updateColumns', res.data);
    }));
  },
  postColumn({ commit }, columnData) {
    return new Promise((resolve) => Vue.prototype.$api.post('company/columns', columnData).then((res) => {
      commit('updateColumns', res.data);
      resolve();
    }));
  },
  putColumn({ commit, dispatch, state }, columnData) {
    return new Promise((resolve) => Vue.prototype.$api.put('company/columns', columnData).then((res) => {
      commit('updateColumns', res.data);
      if (state.currentOfferApplicationId) {
        dispatch('getUpdatedApplicants', state.currentOfferApplicationId);
      }
      resolve();
    }));
  },
  deleteColumn({ commit }, id) {
    return new Promise((resolve) => Vue.prototype.$api.delete(`company/columns/${id}`).then((res) => {
      commit('updateColumns', res.data);
      resolve();
    }));
  },

};
const mutations = {
  setCompanyInfo(state, info) {
    state.companyInfo = info;
    if (info.projects) {
      state.projects = info.projects;
      delete state.companyInfo.projects;
    }
    if (info.columns) {
      state.columns = info.columns;
      delete state.companyInfo.columns;
    }

    state.loadingInfo = false;
  },
  setCompanyOffers(state, offers) {
    state.offers = offers;
    state.loadingOffers = false;
  },
  setCurrentOfferApplicationId(state, id) {
    state.currentOfferApplicationId = id;
  },
  setEmployees(state, employees) {
    state.companyEmployees = employees;
  },
  editEmployee(state, { employee, employeeIndex }) {
    Vue.set(state.companyEmployees, employeeIndex, employee);
  },
  addOffer(state, offerData) {
    state.offers.unshift(offerData);
  },
  deleteOffer(state, index) {
    state.currentOfferApplicationId = false;
    state.offers.splice(index, 1);
  },
  editOffer(state, { offerIndex, offerData }) {
    const newOffer = { ...state.offers[offerIndex], ...offerData };
    Vue.set(state.offers, offerIndex, newOffer);
  },
  editOfferApplicants(state, { offerIndex, applicantData }) {
    Vue.set(state.offers[offerIndex], 'applicants', applicantData);
  },
  updateApplicant(state, { offerIndex, applicantIndex, update }) {
    Vue.set(state.offers[offerIndex].applicants, applicantIndex, update);
  },
  updateApplicantTimes(state, { offerIndex, applicantIndex, update }) {
    const timeUpdate = { ...state.offers[offerIndex].applicants[applicantIndex] };
    timeUpdate.process = update;
    Vue.set(state.offers[offerIndex].applicants, applicantIndex, timeUpdate);
  },
  changeManagementTab(state, index) {
    state.managementPageIndex = index;
  },
  addProcess(state, process) {
    state.companyInfo.processes.push(process);
  },
  editProcess(state, { process, processIndex }) {
    Vue.set(state.companyInfo.processes, processIndex, process);
  },
  deleteProcess(state, processIndex) {
    state.companyInfo.processes.splice(processIndex, 1);
  },
  changeProcessId(state, id) {
    state.currentProcessId = id;
  },
  addProject(state, project) {
    state.projects.push(project);
  },
  updateProject(state, project) {
    const certIndex = state.projects.findIndex(((cert) => cert._id === project._id));
    Vue.set(state.projects, certIndex, project);
  },
  deleteProject(state, certId) {
    const certIndex = state.projects.findIndex((cert) => cert._id === certId);
    Vue.delete(state.projects, certIndex);
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  addRole(state, role) {
    state.roles.push(role);
  },
  updateRole(state, role) {
    const roleentryIndex = state.roles.findIndex(((roleentry) => roleentry._id === role._id));
    Vue.set(state.roles, roleentryIndex, role);
  },
  deleteRole(state, roleId) {
    const roleentryIndex = state.roles.findIndex((roleentry) => roleentry._id === roleId);
    Vue.delete(state.roles, roleentryIndex);
  },
  updateColumns(state, columns) {
    Vue.set(state, 'columns', columns);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
