<template>
  <div class="application-process-info radius" id="applicantProcessInfoScroll">
    <div class="cont-infos" v-if="!loading">
      <div class="contentbox box-shadow radius marginB purple"
      >
        <toggle-dropdown
          :title="''"
          v-model="applicantInfoView"
        >
          <div class="row">
            <div class="pro-pic">
              <img :src="applicantData.profilePicture" />
            </div>
            <div class="user-info">
              <div class="name">
                {{applicantData.name.firstName}} {{applicantData.name.lastName}}
              </div>
              <div class="status">
                {{applicantData.jobWanted}}
              </div>

            </div>
          </div>

        </toggle-dropdown>

        <div class="divider"  v-if="applicantInfoView" ></div>

        <div v-if="applicantInfoView" class="inner-slot user-more purple">
          <div class="col left">
            <div class="work" v-if="applicantData.work">
              Work at <b>{{}}</b>
            </div>
            <div class="location" v-if="applicantData.location">
              Lives in <b>{{applicantData.location}}</b>
            </div>

          </div>
          <div class="col right social">
            <div
              v-for="social in
              applicantData.socials.filter(social => !social.private)"
              :key="social._id"
              class="social-link" @click="() => openSocial(social.link)"
            >
              <social-picto :site="social.site" :purple="true" />
            </div>
          </div>
        </div>
      </div>

      <div class="cont-btn-process-info">
        <button v-if="this.seeprofile" @click="this.seeprofile"
          class="btn-green radius bottom-right">
          See Talent's Profile
        </button>
        <button v-if="this.reject" @click="this.reject"
          class="btn-green radius bottom-right reverse">
          Reject Talent
        </button>
      </div>

      <div class="contentbox box-shadow radius marginB">
        <toggle-dropdown
          :title="'About'"
          v-model="applicantAbout"
        />
        <div class="inner-slot" v-show="applicantAbout">
        <div class="about-entry box-shadow">
          <div class="about-title">
            Bio
          </div>
          <div class="about-line" v-html="applicantData.description">
          </div>
        </div>
         <div class="about-entry box-shadow">
          <div class="about-title">
            Cover Letter
          </div>
          <div class="about-line" v-html="applicantData.motivationalText">
          </div>
        </div>
        <div class="about-entry box-shadow">
          <div class="about-title">
            Skills
          </div>
          <div class="about-line" v-html="allSkills()">
          </div>
        </div>
        <div class="about-entry box-shadow">
          <div class="about-title">
            Technologies
          </div>
          <div class="about-line" >
            <p>{{allTech()}}</p>
          </div>
        </div>

        </div>
      </div>

      <!-- <div class="contentbox box-shadow radius marginB">
        <div class="subtitle-page">
          Resume
        </div>
      </div> -->
      <div class="contentbox box-shadow radius marginB"
        v-if="professionalEntries.length !== 0"
      >
        <toggle-dropdown
          :title="'Experiences'"
          v-model="applicantData.professionalBG.open"
        />
        <div class="inner-slot"  v-show="applicantData.professionalBG.open">
          <resume-entry v-for="entry in professionalEntries" :key="entry._id"
            :editable="false" :type="'professionalBG'" :objectData="entry" />
        </div>
      </div>

      <div class="contentbox box-shadow radius marginB"
        v-if="projectEntries.length !== 0"
      >
        <toggle-dropdown
          :title="'Projects'"
          v-model="applicantData.projects.open"
        />
        <div class="inner-slot"  v-show="applicantData.projects.open">
          <resume-entry v-for="entry in projectEntries" :key="entry._id"
            :editable="false" :type="'projects'" :objectData="entry" />
        </div>
      </div>

      <div class="contentbox box-shadow radius marginB"

        v-if="academicEntries.length !== 0"
      >
        <toggle-dropdown
          :title="'Academic'"
          v-model="applicantData.academicBG.open"
        />
        <div class="inner-slot" v-show="applicantData.academicBG.open">
          <resume-entry v-for="entry in academicEntries" :key="entry._id"
            :editable="false" :type="'academicBG'" :objectData="entry" />
        </div>
      </div>
      <div class="history">
        <h3>History</h3>
        <div class="separ-history"></div>
        <div class="hist-entry" v-for="ev in applicantData.history" :key="ev._id">
          <div class="label-hist" v-html="ev.event"></div>
          <div class="time-hist">{{ev.time | moment('hh:mm DD/MM YYYY')}}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>{{this.name}}</h3>
      <loading></loading>
    </div>
  </div>
</template>

<script>
// import TalentBio from '@/components/UniversalComponents/TalentView/TalentBio.vue';
import PerfectScrollbar from 'perfect-scrollbar';
import Loading from '@/components/utilityPartials/_loading.vue';
import ResumeEntry from '@/components/TalentComponents/resume/ResumeEntry.vue';
import ToggleDropdown from '@/components/utilityPartials/showDropdown.vue';
import SocialPicto from '@/components/utilityPartials/socialPicto.vue';

export default {
  name: 'ApplicantProcessInfo',
  props: ['applicationId', 'offerId', 'reject', 'seeprofile'],
  components: {
    // TalentBio,
    ResumeEntry,
    ToggleDropdown,
    Loading,
    SocialPicto,
  },
  data() {
    return {
      loading: true,
      applicantData: false,
      openEntry: '',
      applicantInfoView: false,
      applicantAbout: false,
    };
  },
  mounted() {
    this.$api.get(`company/applicantInfo/${this.offerId}/${this.applicationId}`).then((res) => {
      this.loading = false;
      this.applicantData = res.data;
    });
    const ps = new PerfectScrollbar('#applicantProcessInfoScroll', {
      wheelPropagation: false,
      wheelSpeed: 1,
    });
    ps.update();
  },
  computed: {
    academicEntries() {
      const elemAca = this.applicantData.academicBG;
      elemAca.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
      return elemAca;
      // return this.applicantData.academicBG;
    },
    projectEntries() {
      const elemProject = this.applicantData.projects;
      elemProject.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
      return elemProject;
      // return this.applicantData.projects;
    },
    professionalEntries() {
      const elemPro = this.applicantData.professionalBG;
      elemPro.sort((a, b) => new Date(b.dates.start) - new Date(a.dates.start));
      return elemPro;
      // return this.applicantData.professionalBG;
    },
  },
  methods: {
    openSocial(site) {
      window.open(site);
    },
    allSkills() {
      return this.applicantData.skills.map((sk) => `#${sk}`).join(', ');
    },
    allTech() {
      return this.applicantData.technologies.map((sk) => `#${sk}`).join(', ');
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.application-process-info{
  position: relative;
  height: 66vh;
  overflow: auto;
  padding: 1vh;
  background-color: #160033;
  // border-radius: 5%;
  flex: 1;

  .divider{
    // margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: 1vh;
    background-color: white;
    height: 0.1vh;
    width: calc(100% - 2vh);
  }
  .pro-pic{
    position: relative;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    overflow: hidden;
    // margin-left: 1vh;
    img{
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      position: absolute;
    }
  }
  .user-more{
    display: flex;
    position: relative;
    flex-direction: row;
    padding-left: 2%;

    .col{
      position: relative;
      display: flex;
      flex-direction: column;
    }
    .left{

      flex: 3;
    }
    .right{
      flex: 1

    }
  }
  .user-info{
    flex: 9;
    padding: 1%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .name{
      font-size: 18px;
      font-weight: bold;
    }
    .status{
      font-size: 14px
    }
  }
  .all-info{
    flex:9;
  }
  .row{
    display: flex;
    flex-direction: row;
  }
  .experience{
  }
  .purple{
    background-color: #8c28e1;
    color: #FFF
  }
  .bottom-right{
    margin-bottom: 2vh;
    position: relative;
    margin-left: auto;
  }
  .social-link{
    width: 30%;
    height: auto;
    margin: 3%;
    background-color: white;
    color: purple;
    border-radius: 10%;
  }
  .social{
    flex-direction: row-reverse !important;
    flex-wrap: wrap;

    padding-right: 2%;

  }
  .about-entry{
    margin: 10px;
    padding: 1vh;
    background-color: #f8f8f8;
    border-radius: 1vh;
    line-height: 18px;
    .about-title{
      font-size: 18px;
      color: #8c28e1;
      margin-bottom: .5vh;
    }
    .about-line{
      font-size: 12px;
      color: #939598;
      line-height: 2vh;
      overflow: hidden;
      text-overflow: ellipsis;
      #text{
        white-space: nowrap;
      }
    }

  }
  // padding-bottom: 4vh;
  .cont-infos{
    // max-height: 100%;
    // overflow: auto;
    .ap-entry{
      width: 98%;
      .ap-view{
        // background-color: red;
        .content_date{
          font-size: $classic_fs;
        }
        .content_infos{
          font-size: $mini_fs;
          width: 76%;
          h4{
            display:block;
            width: 100%;
            font-size: $label_min_fs;
          }
          h5{
            display:block;
            width: 100%;
            font-size: $classic_fs;
          }
          .content_tags{
            width: 200%;
          }
        }
        &.view-project{
          flex-direction: row !important;
          .visual_infos{
            // display: none;
            width:10vh;
            .content_tags{
              display:none;
            }
          }
        }
      }
    }
  }
}
h4{
  float: left;
}
.history{
  // background-color: white;
  color: #ffffff;
  h3{
    font-family: 'MontserratBold';
    font-size: 2vh;
  }
  .separ-history{
    height: 0.1vh;
    width: 100%;
    background-color: #ffffff;
    margin-top: .5vh;
    margin-bottom: 1vh;
  }
  .hist-entry{
    position: relative;
    margin-bottom: 1vh;
    padding: .5vh 1vh;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: .4vh;
      height: 100%;
      background-color: $logo-color;
    }
    &:last-child{
      margin-bottom: 0;
    }
    .label-hist{}
    .time-hist{
      font-size: 1.2vh;
      margin-top: 0.5vh;
      color: $border_darkgray;
    }
  }
}

.cont-btn-process-info{
  width: 100%;
  display: flex;
  justify-content: space-between;
  button{
    margin-left: 0 !important;
    &:hover{
      color: #ffffff;
    }
    &.reverse{
      color: #ffffff;
    }
  }
}

.inner-slot{
  padding: 1vh;
  .about-entry{
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .ResumeEntry{

    margin-bottom: 1vh;
    &:last-child{
      margin-bottom: 0;
    }
    .ap-entry{
      width: 100% !important;
    }
  }
  .cont-btn{
    display: none;
  }
}

</style>
