export default {
  watch: {
    currentSelected(newVal) {
      if (newVal) {
        this.data.dates.end = null;
      }
    },
  },
  data() {
    return {
      currentSelected: false,
    };
  },
  mounted() {
    this.currentSelected = !!this.data.endDate;
  },
};
