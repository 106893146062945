import Vue from 'vue';
import myPosts from './myPosts';
import myFeed from './myFeed';
import followList from './followList';

const state = {
  companyView: false,
};

const actions = {
  switchView({ commit, dispatch }) {
    commit('switchView');
    dispatch('myPosts/getInitialPosts');
    dispatch('myFeed/getInitialPosts');
    dispatch('followList/clearFollow');
  },
  likePost({ dispatch }, postId) {
    return new Promise((resolve) => Vue.prototype.$api.post(`feed/like/${postId}`, {}).then((res) => {
      dispatch('myPosts/updateLike', res.data);
      dispatch('myFeed/updateLike', res.data);
      resolve(res.data);
    }).catch(() => {
    }));
  },
  deletePost({ dispatch }, postId) {
    dispatch('myPosts/deletePost', postId);
    dispatch('myFeed/deletePost', postId);
  },
  createPost({ dispatch }, post) {
    Vue.prototype.$api.post('feed/post', post).then((res) => {
      dispatch('myPosts/addPost', res.data);
      dispatch('myFeed/addPost', res.data);
    });
  },
};

const mutations = {
  switchView(state) {
    state.companyView = !state.companyView;
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    myPosts,
    myFeed,
    followList,
  },
};
