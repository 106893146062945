/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';
import auth0 from '../../modules/auth/authModule';

const getInitialState = () => ({
  token: '',
  roleName: '',
  permissions: [],
  verified: false,
  profileCreated: false,
  type: false,
  loggedIn: false,
});
const state = getInitialState();

const getters = {
  loggedIn: (state, getters, rootState) => state.loggedIn,
  token: (state, getters, rootState) => (state.loggedIn ? state.token : false),
  name: (state, getters) => state.name,
  userData: (state) => ({ name: state.name, email: state.email }),
  type: (state) => state.type,
};

const actions = {
  saveToken: ({ commit, state }, token) => {
    commit('saveToken', token);
  },

  initialize: ({ commit, dispatch, state }) => new Promise((resolve) => {
    auth0().then((auth) => {
      commit('authInit', auth);
      auth.checkLogin().then((user) => {
        commit('initialize', null, { root: true });
        resolve();
        if (user && !user.error) {
          commit('storeUser', user);
          dispatch('user/getUserInfo', null, { root: true });
          Vue.prototype.$socket.client.open();
          Vue.prototype.$socket.client.on('reconnect', () => {
            Vue.prototype.$socket.client.emit('authorize', { token: state.token });
          });
          Vue.prototype.$socket.client.emit('authorize', { token: state.token });
          dispatch('user/getMedia', null, { root: true });
        } else if (user.error) {
          Vue.prototype.$toastError(user.error);
        }
      });
    });
  }),
  logout: ({ commit, state }) => {
    state.authObject.auth0Client.logout({
      returnTo: window.location.origin,
    });
    commit('logout');
  },
  login: ({ commit, state }) => {
    if (!state.loggedIn) {
      state.authObject.auth0Client.loginWithRedirect();
    }
  },
  signup: ({ commit, state }) => {
    if (!state.loggedIn) {
      state.authObject.auth0Client.loginWithRedirect({ screen_hint: 'signup' });
    }
  },
  getTokenInfo: ({ state }) => state.authObject.auth0Client.getIdTokenClaims(),
  setUserAttribute: ({ commit }, { field, val }) => {
    commit('updateUser', { field, val });
  },
  setUserType: ({ commit }, type) => {
    commit('updateUser', { field: 'type', val: type });
  },
  setProfileCreated: ({ commit }) => {
    commit('updateUser', { field: 'profileCreated', val: true });
  },
  setUserCompany: ({ commit }) => new Promise((resolve) => {
    commit('updateUser', { field: 'type', val: 'Employee' });
    resolve();
  }),

};
const mutations = {

  authInit(state, auth) {
    state.initializing = false;
    state.authObject = auth;
  },
  initializingAuth(state) {
    state.initializing = true;
  },
  storeUser(state, userData) {
    state.token = userData.token;
    state.company = userData.company;
    state.roleName = userData.roleName;
    state.verified = userData.verified;
    state.profileCreated = userData.profileCreated;
    state.type = userData.type;
    state.loggedIn = true;
    state.permissions = userData.permissions;
  },
  logout(state) {
    // eslint-disable-next-line no-param-reassign
    state = getInitialState();
  },
  updateUser(state, { field, val }) {
    state[field] = val;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
