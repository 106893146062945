<template>
  <div >
      <div class="file-upload">
        <div v-if="(value.testFile && value.testFile.name) || tempName">
          File: {{value.testFile.name || tempName}}
        </div>
        <div v-else>
          No File Uploaded
        </div>
        <button @click="openMediaSelect">Upload</button>
      </div>
      <div class="cont-input">
        <label>Test Instructions</label>
        <wysiwyg class="wysiwyg radius" v-model="value.instructions" />
      </div>
  </div>

</template>

<script>
import selectMedia from '@/modules/mixins/selectMedia';

export default {
  name: 'TechTestForm',
  props: ['value'],
  mixins: [selectMedia],
  data() {
    return {
      editFile: false,
      tempName: '',
    };
  },

  methods: {
    toggleEdit() {
      this.editFile = !this.editFile;
    },
    fileUpdate(file) {
      this.tempName = file.name;
    },

    clearUpload() {
      this.editFile = false;
      this.tempName = '';
    },

    openMediaSelect() {
      this.openAttach(
        'Pdf',
        (newFile) => {
          this.value.testFile = { name: newFile.name, file: newFile.data, type: newFile.type };
          this.tempName = newFile.name;
        },
      );
    },
  },
  mounted() {
    if (!this.value.testFile) {
      this.value.testFile = {};
      this.editFile = true;
    }
  },

};
</script>

<style lang="scss" scoped>
.techtestform{
  width: 100%;
}
.file-cont{
  display: flex;
  flex-direction: column;
  position: relative;
  .cont-input{
    flex: 2;
    width: 100%;
  }
  .upload-cont{
    flex: 1;
    width: 100%;
  }
}
.wysiwyg{
  height: 30vh;
  width:100%
}

</style>
