<template>
  <div class="availability-entry">
    <span>
      From
    </span>
    <span>
      <datetime
        class="elem-date v-select"
        type="datetime"
        v-model="event.start"
        :placeholder="'Click to set start date'"
        :minute-step="10"
        :use12-hour="true"
        :format="'MMMM d, yyyy, HH:mm a'"
      />
    </span>
    <span>To</span>
    <span v-if="typeof event.end === 'string'">
      <div class="v-fakeselect">{{event.end | moment('MMMM D, YYYY, HH:mm a')}}</div>
    </span>
    <span v-else>Start date need to be set</span>
  </div>
</template>

<script>
import 'vue-datetime/dist/vue-datetime.css';
import { Datetime } from 'vue-datetime';

export default {
  name: 'AvailabilityEntry',
  props: ['event', 'meetingTime'],
  components: {
    datetime: Datetime,
  },
  watch: {
    'event.start': function () {
      const dt = new Date(this.event.start);
      this.event.end = new Date(dt.setMinutes(dt.getMinutes() + ((this.meetingTime / 60000))))
        .toISOString();
    },
  },
  mounted() {
  },

};
</script>

<style lang="scss">
.availability-entry{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  span{
    margin-right: 10px;
    margin-bottom: 5px;
    .v-select {
      .vdatetime-input{
        border: 1px solid rgba(60, 60, 60, 0.26);
        padding: 4px 8px;
        line-height: 1.4;
        border-radius: 4px;
        font-size: 1.2vh;
        font-family: 'MontserratRegular';
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
    .v-fakeselect{
        border: 1px solid rgba(60, 60, 60, 0.26);
        padding: 4px 8px;
        line-height: 1.4;
        border-radius: 4px;
        font-size: 1em;
        font-family: 'MontserratRegular';
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
  }
}
</style>
