<template>
  <div class="media-upload-modal">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="modal-title">
      Add a new media
    </div>
    <div class="inner-modal" v-if="!uploading" >
      <div class="cont-upload-select" v-if="!this.lock">
        <div
          :class="media === 'audio' ? 'reverse' : ''"
          @click="() => toggleType('audio')"
          class="radius btn-green"
        >
          Record Audio
        </div>
        <div
          :class="media === 'file' ? 'reverse' : ''"
          @click="() => toggleType('file')"
          class="radius btn-green"
        >
          Upload File
        </div>
        <div
          :class="media === 'video' ? 'reverse' : ''"
          @click="() => toggleType('video')"
          class="radius btn-green"
        >
          Record Video
        </div>
      </div>
      <file-upload
        class="upload"
        v-model="data"
        :preventButton="true"
        ref="file"
        @fileUpdate="fileUpdateHandler"
        v-if="media === 'file'"
      />
      <audio-upload
        v-if="media === 'audio'"
        ref="audio"
        @ready="readyUpload"
        />
      <video-upload
        v-if="media === 'video'"
        ref="video"
        @ready="readyUpload"
        />
      <div class="cont-info-file">
        <div class="inner-info-file">
          <div>
            <input
              v-model="name"
              class="v-select"
              placeholder="Media name"
            />
          </div>
          <div v-if="type" class="upload-type">
            Type: {{type}}
          </div>
        </div>
        <div class="container-upload-btn">
          <div class="btn-green radius" v-if="readyToUpload" @click="uploadMedia">Upload</div>
          <div class="else-record-ready" v-else>Record or select a record to upload</div>
        </div>
      </div>
    </div>
    <div v-else >
      Uploading ...
    </div>

  </div>
</template>

<script>
import FileUpload from '@/components/utilityPartials/fileUpload.vue';
import AudioUpload from '@/components/UniversalComponents/Media/_audioUpload.vue';
import VideoUpload from '@/components/UniversalComponents/Media/_videoUpload.vue';

export default {
  name: 'MediaUpload',
  components: {
    FileUpload,
    AudioUpload,
    VideoUpload,
  },
  data() {
    return {
      data: '',
      name: 'my_record',
      type: '',
      uploading: false,
      media: 'file',
      readyToUpload: false,
    };
  },
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
    lock: {
      type: Boolean,
      default: false,
    },
    initialType: {
      type: String,
      default: 'file',
    },
  },
  mounted() {
    this.media = this.initialType;
    this.type = this.initialType[0].toUpperCase() + this.initialType.substring(1);
  },

  methods: {
    fileUpdateHandler(file) {
      this.name = file.name.split('.').slice(0, -1).join('.');
      if (file.type.split('/')[0] === 'image') {
        this.type = 'Image';
      } else if (file.type.split('/')[0] === 'video') {
        this.type = 'Video';
      } else if (file.type === 'application/pdf') {
        this.type = 'Pdf';
      }
      this.readyToUpload = true;
    },
    uploadMedia() {
      if (this.readyToUpload) {
        this.uploading = true;
        this.$refs[this.media].upload().then((endpoint) => {
          this.$store.dispatch('user/uploadMedia', {
            data: endpoint,
            name: this.name,
            type: this.type,
          }).then((newMedia) => {
            this.$modal.hide('MediaUpload');
            this.callback(newMedia);
          });
        });
      }
      return false;
    },
    toggleType(type) {
      this.name = '';
      this.type = '';
      this.readyToUpload = false;
      this.media = type;
      if (type === 'audio') {
        this.type = 'Audio';
      } else if (type === 'video') {
        this.type = 'Video';
      } else if (type === 'file') {
        this.type = 'File';
      }
    },
    readyUpload(val) {
      this.readyToUpload = val;
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'MediaUpload');
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.media-upload-modal{
  position: relative;
  padding: 20px;
  height: 100%;
  .modal-title{
    font-family: 'MontserratBold';
    font-size: $stitre_fs;
    color: $text_color;
    margin-bottom: 20px;
  }
  .inner-modal{
    display: flex;
    flex-direction: column;
    height: auto;
    .cont-upload-select{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 75%;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .cont-info-file{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 2vh;
      .inner-info-file{
        display: flex;
        flex-direction: row;
        align-items: center;
        .upload-type{
          margin-left: 2vh;
        }
      }
      .cont-upload-btn{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        .btn-green{
          margin: 0;
        }
      }
    }
    // .upload{
    //   width: 100%;
    //   height: 200px;
    //   position: relative;
    // }
  }
}
// .container-upload-btn{
//   position: absolute;
//   bottom: 40px;
//   z-index: 10;
// }

</style>
