/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Vue from 'vue';

const allowedSorts = ['NEW', 'POPULAR'];
const state = () => ({
  feedPosts: [],
  feedSort: 'NEW',
  limit: 25,
  lastLoaded: false,
  requestSending: false,
  endOfList: false,
  feedSource: 'all',
});

const getters = {
  loaded: (state) => !!state.lastLoaded && !state.requestSending,
};

const actions = {
  addPost({ commit }, post) {
    commit('addPost', post);
  },
  setSort({ commit, state }, sort) {
    if (allowedSorts.indexOf(sort) > -1) {
      commit('setSort', sort);
    }
  },
  getPosts({ commit, state }) {
    return new Promise((resolve) => {
      commit('sendRequest');
      Vue.prototype.$api.post(`feed/${state.feedSource}`, {
        orderBy: state.feedSort,
        dateSearch: state.lastLoaded,
        limit: state.limit,
        skip: state.feedPosts.length,
      }).then((res) => {
        commit('loadPosts', res.data);
        resolve(res.data.length > 0);
        if (res.data.length < state.limit) {
          commit('endReached');
        }
      });
    });
  },
  getInitialPosts({ commit, dispatch }) {
    commit('setInitial', Date.now());
    dispatch('getPosts');
  },
  updateLike({ commit, state }, post) {
    const postIndex = state.feedPosts.findIndex((feedPost) => feedPost._id === post._id);
    if (postIndex > -1) {
      commit('updateLike', { postIndex, post });
    }
  },
  deletePost({ commit, state }, id) {
    const postIndex = state.feedPosts.findIndex((feedPost) => feedPost._id === id);
    if (postIndex > -1) {
      commit('deletePost', postIndex);
    }
  },
  changeFeedSource({ dispatch, commit, state }) {
    if (state.feedSource === 'following') {
      commit('changeFeedSource', 'all');
    } else {
      commit('changeFeedSource', 'following');
    }
    dispatch('getInitialPosts');
  },

};
const mutations = {
  sendRequest(state) {
    state.requestSending = true;
  },
  addPost(state, post) {
    state.feedPosts.unshift(post);
  },
  setInitial(state, date) {
    state.requestSending = false;
    state.feedPosts = [];
    state.lastLoaded = date;
  },
  loadPosts(state, posts) {
    state.requestSending = false;
    state.feedPosts = state.feedPosts.concat(posts);
  },
  endReached(state) {
    state.endOfList = true;
  },
  setSort(state, sort) {
    state.sort = sort;
  },
  updateLike(state, { postIndex, post }) {
    state.feedPosts[postIndex].likes = post.likes;
    state.feedPosts[postIndex].youLiked = post.youLiked;
  },
  deletePost(state, postIndex) {
    state.feedPosts.splice(postIndex, 1);
  },
  changeFeedSource(state, newSource) {
    state.feedSource = newSource;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
