<template>
  <div class="cont-input file-cont">

    <div class="btn-green radius" v-if="!submitted" @click="upload">Upload Response</div>
    <div class="btn-green small radius" v-if="submitted" @click="upload">Change</div>

    <div class="submitted"
      v-if="submitted">
      Submitted File: {{this.value.completedTestFile.name}}
    </div>

  </div>

</template>

<script>
import selectMedia from '@/modules/mixins/selectMedia';

export default {
  name: 'TechTestForm',
  props: ['value'],
  mixins: [selectMedia],
  data() {
    return {
      editFile: false,
      tempName: '',
    };
  },

  methods: {
    toggleEdit() {
      this.editFile = !this.editFile;
    },
    fileUpdate(file) {
      this.tempName = file.name;
    },
    upload() {
      this.openAttach('Other', (newFile) => {
        this.value.completedTestFile = {
          name: newFile.name,
          file: newFile.data,
          type: newFile.type,
        };
        this.$emit('submit');
      });
    },

    clearUpload() {
      this.editFile = false;
    },
  },
  mounted() {
    if (!this.value.completedTestFile) {
      this.value.completedTestFile = {};
      this.editFile = true;
    }
  },
  computed: {
    submitted() {
      return this.value.completedTestFile && this.value.completedTestFile.name;
    },
  },

};
</script>

<style lang="scss" scoped>
.file-cont{
  display: flex;
  .submitted{
    width: 10vw;
    word-wrap: break-word;
  }
  .upload-cont{
    width: 100%;
    height: auto;
  }
}

</style>
